import services from "../../../http";
import * as types from "./mutations-types";

const baseUrl = "account";

export const ActionDoListAccounts = ({ commit }) => {
    return services.api.request({
        url: baseUrl,
        method: "get"
    }).then(response_data => {
        let response = response_data.data;

        commit(types.SET_LIST_ACCOUNTS, response);

        return response;
    });
};

export const ActionDoCreateAccount = (context, payload) => {
    return services.api.request({
        url: baseUrl,
        method: "post",
        data: payload
    }).then(response_data => {
        let response = response_data.data;

        return response;
    });
};

export const ActionDoReadAccount = (context, id_account) => {
    return services.api.request({
        url: baseUrl + "/" + id_account,
        method: "get"
    }).then(response_data => {
        let response = response_data.data;

        return response;
    });
};

export const ActionDoEditAccount = (context, payload) => {
    return services.api.request({
        url: baseUrl + "/" + payload.id,
        method: "put",
        data: payload
    }).then(response_data => {
        let response = response_data.data;

        return response;
    });
};

export const ActionDoDeleteAccount = (context, id_account) => {
    return services.api.request({
        url: baseUrl + "/" + id_account,
        method: "delete"
    }).then(response_data => {
        let response = response_data.data;

        return response;
    });
};