<template>
    <v-dialog v-model="value" max-width="500px" persistent>
        <v-card>
            <v-card-title>
                <span class="text-h5">
                    Editar despesa de cartão recorrente
                </span>
            </v-card-title>
            <v-card-text>
                <v-container>
                    <v-alert v-if="error_message" type="error">
                        {{ error_message }}
                    </v-alert>
                    <v-form ref="formEdit">
                        <v-row>
                            <v-col cols="6">
                                <base-text-money
                                    v-model="recurrent_record.value"
                                    label="Valor"
                                    :rules="value_rules"
                                ></base-text-money>
                            </v-col>
                            <v-col cols="6">
                                <v-select
                                    v-model="recurrent_record.category"
                                    label="Categoria"
                                    :rules="category_rules"
                                    :items="macro_category"
                                ></v-select>
                            </v-col>
                            <v-col cols="6">
                                <v-select
                                    v-model="recurrent_record.credit_card"
                                    label="Cartão de crédito"
                                    :rules="credit_card_rules"
                                    :items="credit_cards"
                                    disabled
                                ></v-select>
                            </v-col>
                            <v-col cols="6">
                                <v-text-field
                                    v-model="recurrent_record.description"
                                    label="Título"
                                    :rules="description_rules"
                                ></v-text-field>
                            </v-col>
                            <v-col cols="12">
                                <v-textarea
                                    v-model="recurrent_record.long_description"
                                    label="Descrição"
                                />
                            </v-col>
                        </v-row>
                    </v-form>
                </v-container>
            </v-card-text>
            <v-card-actions>
                <v-spacer></v-spacer>
                <v-btn
                    color="primary"
                    text
                    :disabled="loading"
                    @click="close"
                >
                    Cancelar
                </v-btn>
                <v-btn
                    color="success"
                    text
                    :disabled="loading"
                    @click="create"
                >
                    Salvar
                </v-btn>
            </v-card-actions>
            <v-progress-linear
                v-if="loading"
                indeterminate
                height="6"
            ></v-progress-linear>
        </v-card>
        <base-notify
            v-model="show_notify"
            :type="notify_type"
            :message="notify_message"
        >
        </base-notify>
    </v-dialog>
</template>

<script>
import BaseNotify from "../../../components/BaseNotify.vue";
import BaseTextMoney from "../../../components/BaseTextMoney";
import { mapActions, mapState } from "vuex";

export default {
    components: {
        BaseNotify,
        BaseTextMoney
    },
    props: {
        value: {
            type: Boolean,
            required: true
        },
        recurrentRecordId: {
            type: Number,
            required: false,
            default: null
        }
    },
    data() {
        return {
            value_rules: [
                v => !!v || "Informe um valor",
            ],
            category_rules: [
                v => !!v || "Selecione uma categoria",
            ],
            credit_card_rules: [
                v => !!v || "Selecione um cartão de crédito",
            ],
            description_rules: [
                v => !!v || "Informe uma descrição"
            ],
            loading: false,
            error_message: null,
            show_notify: false,
            notify_type: null,
            notify_message: "",
            macro_category: [],
            credit_cards: [],
            recurrent_record: {
                value: null,
                category: null,
                credit_card: null,
                description: null,
                long_description: null
            }
        };
    },
    computed: {
        ...mapState("credit_card", {
            list_credit_cards: state => state.credit_cards
        }),
        ...mapState("settings", {
            list_macro_category: state => state.macro_category
        })
    },
    watch: {
        value(new_value) {
            if (new_value) {
                this.read();
            }
        }
    },
    mounted() {
        if (!this.list_macro_category || this.list_macro_category.length === 0) {
            this.getListMacroCategory();
        } else {
            this.makeListMacroCategory();
        }

        if (!this.list_credit_cards || this.list_credit_cards.length === 0) {
            this.getListCreditCards();
        } else {
            this.makeListCreditCard();
        }
    },
    methods: {
        ...mapActions("settings", ["ActionDoListMacroCategory"]),
        ...mapActions("credit_card", ["ActionDoListCreditCards"]),
        ...mapActions("recurrent_record", ["ActionDoReadRecurrentRecord", "ActionDoEditRecurrentRecord"]),
        close() {
            this.cleanForm();
            this.$emit("close");
            this.$emit("input", false);
        },
        cleanForm() {
            this.recurrent_record = {
                value: null,
                category: null,
                credit_card: null,
                description: null,
                long_description: null
            };

            this.$refs.formEdit.resetValidation();
        },
        async read() {
            this.loading = true;

            if (this.recurrentRecordId) {
                await this.ActionDoReadRecurrentRecord(this.recurrentRecordId).then(response => {
                    this.recurrent_record = {
                        value: response.value,
                        category: response.category_id,
                        credit_card: response.credit_card_id,
                        description: response.description,
                        long_description: response.long_description
                    };

                    this.loading = false;
                });
            } else {
                this.close();
            }
        },
        create() {
            this.loading = true;

            if (this.$refs.formEdit.validate()) {
                try {
                    this.ActionDoEditRecurrentRecord({
                        id: this.recurrentRecordId,
                        value: this.recurrent_record.value,
                        category_id: this.recurrent_record.category,
                        description: this.recurrent_record.description,
                        long_description: this.recurrent_record.long_description,
                        credit_card_id: this.recurrent_record.credit_card
                    }).then(response => {
                        this.error_mesasge = null;

                        if (response.recurrent_record) {
                            this.show_notify = true;
                            this.notify_type = "success";
                            this.notify_message = "Registro salvo com sucesso!";
                            this.loading = false;
                            this.close();
                        } else {
                            this.show_notify = true;
                            this.notify_type = "error";
                            this.notify_message = "Ocorreu um erro!";
                            this.loading = false;
                            this.error_mesasge = response.error;
                        }
                    });
                } catch (error) {
                    this.show_notify = true;
                    this.notify_type = "error";
                    this.notify_message = "Ocorreu um erro!";
                    this.loading = false;
                    this.error_mesasge = error.response.data.error;
                }
            } else {
                this.loading = false;
            }
        },
        async getListMacroCategory() {
            await this.ActionDoListMacroCategory().then(() => {
                this.makeListMacroCategory();
            });
        },
        makeListMacroCategory() {
            for (let macro of this.list_macro_category) {
                this.macro_category.push({
                    text: macro.name,
                    value: macro.id + "macro",
                    header: macro.name
                });

                for (let category of macro.categories) {
                    this.macro_category.push({
                        text: category.name,
                        value: category.id,
                        divider: false
                    });
                }
            }
        },
        async getListCreditCards() {
            await this.ActionDoListCreditCards().then(() => {
                this.makeListCreditCard();
            });
        },
        makeListCreditCard() {
            for (let credit_card of this.list_credit_cards) {
                this.credit_cards.push({
                    text: credit_card.name,
                    value: credit_card.id
                });
            }
        }
    }
};
</script>
