<template>
    <v-container>
        <v-row>
            <v-col>
                <v-card class="overflow-auto" :height="650">
                    <v-card-title>
                        <v-row no-gutters>
                            <v-col cols="12">
                                Resumo geral por Categoria
                            </v-col>
                        </v-row>
                    </v-card-title>
                    <v-card-text>
                        <v-row>
                            <v-col>
                                <apexchart
                                    type="area"
                                    :height="500"
                                    :options="dataChart.chartOptions"
                                    :series="dataChart.series"
                                ></apexchart>
                            </v-col>
                        </v-row>
                    </v-card-text>
                </v-card>
            </v-col>
        </v-row>
        <v-row>
            <v-col
                v-for="(item, i) in list_macro_category"
                :key="i"
                md="6"
            >
                <v-card class="overflow-auto">
                    <v-card-title>
                        <v-row no-gutters>
                            <v-col cols="12">
                                {{ item.macro_category_name }}
                            </v-col>
                        </v-row>
                    </v-card-title>
                    <v-card-text>
                        <v-row>
                            <v-col>
                                <apexchart
                                    type="area"
                                    :height="500"
                                    :options="getDataChart(item).chartOptions"
                                    :series="getDataChart(item).series"
                                ></apexchart>
                            </v-col>
                        </v-row>
                    </v-card-text>
                </v-card>
            </v-col>
        </v-row>

        <dialog-list-records-category v-model="dialog_list_records" :category-id="category_selected" :custom-month="month_selected"></dialog-list-records-category>
    </v-container>
</template>

<script>
import DialogListRecordsCategory from "../credit-card-record/components/DialogListRecordsCategory";
import Vue from "vue";
import { mapActions, mapState } from "vuex";

export default {
    components: {
        DialogListRecordsCategory
    },
    data() {
        return {
            list_macro_category: {},
            label_sparkline: [
                "Janeiro",
                "Fevereiro",
                "Março",
                "Abril",
                "Maio",
                "Junho",
                "Julho",
                "Agosto",
                "Setembro",
                "Outubro",
                "Novembro",
                "Dezembro"
            ],
            dataChart: {
                series: [],
                chartOptions: {}
            },
            dialog_list_records: false,
            category_selected: null,
            month_selected: null
        };
    },
    computed: {
        ...mapState("utils", {
            year: state => state.year
        })
    },
    watch: {
        year() {
            this.getListSummary();
        }
    },
    mounted() {
        this.getListSummary();
    },
    methods: {
        ...mapActions("settings", ["ActionDoGetSummaryYear"]),
        async getListSummary() {
            this.list_macro_category = {};
            await this.ActionDoGetSummaryYear({ year: this.year }).then((response) => {
                for (let item of response) {
                    if (this.list_macro_category[item.macro_category_id] === undefined) {
                        this.list_macro_category[item.macro_category_id] = {
                            sum_total: 0,
                            macro_category_name: item.macro_category.name,
                            total_by_month: {
                                0: 0,
                                1: 0,
                                2: 0,
                                3: 0,
                                4: 0,
                                5: 0,
                                6: 0,
                                7: 0,
                                8: 0,
                                9: 0,
                                10: 0,
                                11: 0,
                            },
                            categories: {}
                        };
                    }

                    this.list_macro_category[item.macro_category_id].sum_total += parseInt(item.records_sum_value ?? 0) + parseInt(item.extracts_sum_value ?? 0) + parseInt(item.extracts_incomings_sum_value ?? 0);

                    for (let extract of item.extracts) {
                        let month_extract = new Date(extract.date).getMonth();

                        if (this.year < new Date().getFullYear() || month_extract <= new Date().getMonth()) {
                            this.list_macro_category[item.macro_category_id].total_by_month[month_extract] += extract.value;

                            if (this.list_macro_category[item.macro_category_id].total_by_month[month_extract] < 0) {
                                this.list_macro_category[item.macro_category_id].total_by_month[month_extract] = 0;
                            }

                            if (this.list_macro_category[item.macro_category_id].categories[extract.category_id] === undefined) {
                                this.list_macro_category[item.macro_category_id].categories[extract.category_id] = {
                                    category_name: item.name,
                                    category_id: extract.category_id,
                                    total_by_month: {
                                        0: 0,
                                        1: 0,
                                        2: 0,
                                        3: 0,
                                        4: 0,
                                        5: 0,
                                        6: 0,
                                        7: 0,
                                        8: 0,
                                        9: 0,
                                        10: 0,
                                        11: 0,
                                    }
                                };
                            }

                            this.list_macro_category[item.macro_category_id].categories[extract.category_id].total_by_month[month_extract] += extract.value;

                            if (this.list_macro_category[item.macro_category_id].categories[extract.category_id].total_by_month[month_extract] < 0) {
                                this.list_macro_category[item.macro_category_id].categories[extract.category_id].total_by_month[month_extract] = 0;
                            }
                        }
                    }

                    for (let record of item.records) {
                        let month_record = record.invoice.month - 1;

                        if (this.year < new Date().getFullYear() || month_record <= new Date().getMonth()) {
                            this.list_macro_category[item.macro_category_id].total_by_month[month_record] += record.value;

                            if (this.list_macro_category[item.macro_category_id].total_by_month[month_record] < 0) {
                                this.list_macro_category[item.macro_category_id].total_by_month[month_record] = 0;
                            }

                            if (this.list_macro_category[item.macro_category_id].categories[record.category_id] === undefined) {
                                this.list_macro_category[item.macro_category_id].categories[record.category_id] = {
                                    category_name: item.name,
                                    category_id: record.category_id,
                                    total_by_month: {
                                        0: 0,
                                        1: 0,
                                        2: 0,
                                        3: 0,
                                        4: 0,
                                        5: 0,
                                        6: 0,
                                        7: 0,
                                        8: 0,
                                        9: 0,
                                        10: 0,
                                        11: 0,
                                    }
                                };
                            }

                            this.list_macro_category[item.macro_category_id].categories[record.category_id].total_by_month[month_record] += record.value;

                            if (this.list_macro_category[item.macro_category_id].categories[record.category_id].total_by_month[month_record] < 0) {
                                this.list_macro_category[item.macro_category_id].categories[record.category_id].total_by_month[month_record] = 0;
                            }
                        }
                    }

                    for (let extract_incoming of item.extracts_incomings) {
                        let month_extract_incoming = new Date(extract_incoming.date).getMonth();

                        let value = extract_incoming.value * (-1);

                        if (item.macro_category_id === 12) {
                            value = extract_incoming.value;
                        }

                        if (this.year < new Date().getFullYear() || month_extract_incoming <= new Date().getMonth()) {
                            this.list_macro_category[item.macro_category_id].total_by_month[month_extract_incoming] += value;

                            if (this.list_macro_category[item.macro_category_id].total_by_month[month_extract_incoming] < 0) {
                                this.list_macro_category[item.macro_category_id].total_by_month[month_extract_incoming] = 0;
                            }

                            if (this.list_macro_category[item.macro_category_id].categories[extract_incoming.category_id] === undefined) {
                                this.list_macro_category[item.macro_category_id].categories[extract_incoming.category_id] = {
                                    category_name: item.name,
                                    category_id: extract_incoming.category_id,
                                    total_by_month: {
                                        0: 0,
                                        1: 0,
                                        2: 0,
                                        3: 0,
                                        4: 0,
                                        5: 0,
                                        6: 0,
                                        7: 0,
                                        8: 0,
                                        9: 0,
                                        10: 0,
                                        11: 0,
                                    }
                                };
                            }

                            this.list_macro_category[item.macro_category_id].categories[extract_incoming.category_id].total_by_month[month_extract_incoming] += value;

                            if (this.list_macro_category[item.macro_category_id].categories[extract_incoming.category_id].total_by_month[month_extract_incoming] < 0) {
                                this.list_macro_category[item.macro_category_id].categories[extract_incoming.category_id].total_by_month[month_extract_incoming] = 0;
                            }
                        }
                    }
                }

                this.list_macro_category = Object.values(this.list_macro_category).sort((a, b) => b.sum_total - a.sum_total);

                this.mountDataChart();
            });
        },
        mountDataChart() {
            let series = [];

            for (let index in this.list_macro_category) {
                series.push({
                    name: this.list_macro_category[index].macro_category_name,
                    data: Object.values(this.list_macro_category[index].total_by_month)
                });
            }

            let data = {
                series: series,
                chartOptions: {
                    chart: {
                        type: "area",
                        zoom: {
                            enabled: false
                        },
                        toolbar: {
                            show: false
                        },
                    },
                    forecastDataPoints: {
                        count: this.year < new Date().getFullYear() ? 0 : 11 - new Date().getMonth()
                    },
                    dataLabels: {
                        enabled: false
                    },
                    colors: ["#8BC34A", "#03A9F4", "#CDDC39", "#F44336", "#009688", "#E91E63", "#2196F3", "#4CAF50", "#FFC107", "#FFEB3B", "#3F51B5", "#673AB7", "#00BCD4", "#9C27B0"],
                    stroke: {
                        curve: "smooth"
                    },
                    grid: {
                        row: {
                            colors: ["#f3f3f3", "transparent"], // takes an array which will be repeated on columns
                            opacity: 0.5
                        },
                        padding: {
                            right: 30,
                            left: 20
                        }
                    },
                    xaxis: {
                        categories: this.label_sparkline,
                    },
                    yaxis: {
                        labels: {
                            formatter: function (value) {
                                return Vue.toCurrency(value);
                            }
                        }
                    }
                }
            };

            this.dataChart = data;
        },
        getDataChart(macro_category) {
            let series = [];

            for (let index in macro_category.categories) {
                series.push({
                    name: macro_category.categories[index].category_name,
                    data: Object.values(macro_category.categories[index].total_by_month)
                });
            }

            let data = {
                series: series,
                chartOptions: {
                    chart: {
                        type: "area",
                        zoom: {
                            enabled: false
                        },
                        toolbar: {
                            show: false
                        },
                        events: {
                            click: (event, chartContext, config) => {
                                let category = Object.values(macro_category.categories).find((item) => {
                                    return item.category_name === series[config.seriesIndex].name;
                                });

                                this.month_selected = (config.dataPointIndex + 1).toString().padStart(2, "0");

                                this.openListRecords(category.category_id);
                            }
                        }
                    },
                    forecastDataPoints: {
                        count: this.year < new Date().getFullYear() ? 0 : 11 - new Date().getMonth()
                    },
                    dataLabels: {
                        enabled: false
                    },
                    colors: ["#8BC34A", "#03A9F4", "#CDDC39", "#F44336", "#009688", "#E91E63", "#2196F3", "#4CAF50", "#FFC107", "#FFEB3B", "#3F51B5", "#673AB7", "#00BCD4", "#9C27B0"],
                    stroke: {
                        curve: "smooth"
                    },
                    grid: {
                        row: {
                            colors: ["#f3f3f3", "transparent"], // takes an array which will be repeated on columns
                            opacity: 0.5
                        },
                        padding: {
                            right: 30,
                            left: 20
                        }
                    },
                    xaxis: {
                        categories: this.label_sparkline,
                    },
                    yaxis: {
                        labels: {
                            formatter: function (value) {
                                return Vue.toCurrency(value);
                            }
                        }
                    }
                }
            };

            return data;
        },
        openListRecords(id) {
            this.category_selected = id;
            this.dialog_list_records = true;
        }
    }
};
</script>
