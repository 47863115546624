import * as types from "./mutations-types";

export default {
    [types.SET_LIST_MACRO_CATEGORY](state, macro_category) {
        state.macro_category = macro_category;
    },
    [types.SET_LIST_CATEGORY](state, category) {
        state.category = category;
    },
    [types.SET_LIST_MACRO_CATEGORY_TOTAL](state, macro_category_total) {
        state.macro_category_total = macro_category_total;
    }
};