import Vue from "vue";

const Utils = {};

const ACCOUNT_BANK = "B";
const ACCOUNT_VOUCHER = "V";

Utils.install = function (Vue) {
    Vue.prototype.$accountBank = ACCOUNT_BANK;
    Vue.prototype.$accountVoucher = ACCOUNT_VOUCHER;

    Vue.filter("toCurrency", function (value) {
        var formatter = new Intl.NumberFormat("pt-BR", {
            style: "currency",
            currency: "BRL"
        });

        return formatter.format(value / 100);
    });

    Vue.toCurrency = function (value) {
        var formatter = new Intl.NumberFormat("pt-BR", {
            style: "currency",
            currency: "BRL"
        });

        return formatter.format(value / 100);
    };

    Vue.sumColumnArrayOfObjects = function (array, column, filter_column, filter_value, filter_column_two, filter_value_two) {
        return array.reduce(function (a, b) {
            if (filter_column !== undefined && filter_value !== undefined) {
                if (filter_column_two !== undefined && filter_value_two !== undefined) {
                    if (b[filter_column_two] == filter_value_two && b[filter_column] == filter_value) {
                        return a + (parseInt(b[column]) ? parseInt(b[column]) : 0);
                    } else {
                        return a + 0;
                    }
                } else {
                    if (b[filter_column] == filter_value) {
                        return a + (parseInt(b[column]) ? parseInt(b[column]) : 0);
                    } else {
                        return a + 0;
                    }
                }
            } else {
                return a + parseInt(b[column]);
            }
        }, 0);
    };

    Vue.formatDateUsaToBrasil = function (date) {
        if (!date) {
            return null;
        }

        date = date.split(" ")[0];
        const [year, month, day] = date.split("-");
        return `${day}/${month}/${year}`;
    };

    Vue.formatDateBrasilToUsa = function (date) {
        if (!date) {
            return null;
        }

        date = date.split(" ")[0];
        const [day, month, year] = date.split("/");
        return `${year}-${month}-${day}`;
    };

    Vue.getAccountType = function (account_type) {
        switch (account_type) {
        case ACCOUNT_BANK:
            return "Conta bancária";
        case ACCOUNT_VOUCHER:
            return "Voucher";
        }
    };

    Vue.getAccountTypeColor = function (account_type) {
        switch (account_type) {
        case ACCOUNT_BANK:
            return "deep-purple";
        case ACCOUNT_VOUCHER:
            return "pink";
        }
    };

    Vue.directive("account-type", {
        bind(el, binding) {
            el.innerHTML = Vue.getAccountType(binding.value);
        },
        update(el, binding) {
            el.innerHTML = Vue.getAccountType(binding.value);
        }
    });

    Vue.directive("date-usa-br", {
        bind(el, binding) {
            el.innerHTML = Vue.formatDateUsaToBrasil(binding.value);
        },
        update(el, binding) {
            el.innerHTML = Vue.formatDateUsaToBrasil(binding.value);
        }
    });
};

Vue.use(Utils);