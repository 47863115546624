import services from "../../../http";

const baseUrl = "invoice";

export const ActionDoListInvoicesClosedPendingPayment = () => {
    return services.api.request({
        url: baseUrl + "/pending-payment",
        method: "post",
        data: {}
    }).then(response_data => {
        let response = response_data.data;

        return response;
    });
};

export const ActionDoReadInvoice = (context, invoice_id) => {
    return services.api.request({
        url: baseUrl + "/" + invoice_id,
        method: "get",
    }).then(response_data => {
        let response = response_data.data;

        return response;
    });
};

export const ActionDoPayInvoice = (context, payload) => {
    return services.api.request({
        url: baseUrl + "/pay-invoice",
        method: "post",
        data: payload
    }).then(response_data => {
        let response = response_data.data;

        return response;
    });
};

export const ActionDoGetListByCreditCard = (context, payload) => {
    return services.api.request({
        url: baseUrl + "/list-by-credit-card",
        method: "post",
        data: payload
    }).then(response_data => {
        return response_data.data;
    });
};
