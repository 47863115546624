<template>
    <v-container>
        <v-row justify="end">
            <v-col cols="auto">
                <v-btn color="primary" @click="dialog_add = true">
                    <v-icon left>
                        mdi-bank-plus
                    </v-icon>
                    Nova conta
                </v-btn>
            </v-col>
        </v-row>
        <v-row>
            <v-col>
                <v-data-table
                    :headers="headers"
                    :items="accounts"
                    :items-per-page="10"
                    :loading="loading"
                    :mobile-breakpoint="0"
                >
                    <template v-slot:[`item.balance`]="{ item }">
                        {{ item.balance | toCurrency }}
                    </template>
                    <template v-slot:[`item.include_summary`]="{ item }">
                        <v-checkbox
                            v-model="item.include_summary"
                            disabled
                        ></v-checkbox>
                    </template>
                    <template v-slot:[`item.account_type`]="{ item }">
                        <v-chip
                            v-account-type="item.account_type"
                            small
                            outlined
                            :color="getColorAccountType(item.account_type)"
                        ></v-chip>
                    </template>
                    <template v-slot:[`item.actions`]="{ item }">
                        <v-row class="flex-nowrap">
                            <v-col cols="auto">
                                <v-btn icon color="lime" @click="transferAccount(item.id)">
                                    <v-icon>mdi-bank-transfer</v-icon>
                                </v-btn>
                            </v-col>
                            <v-col cols="auto">
                                <v-btn icon color="success" @click="listExtract(item)">
                                    <v-icon>mdi-receipt-text</v-icon>
                                </v-btn>
                            </v-col>
                            <v-col cols="auto">
                                <v-btn icon color="primary" @click="editAccount(item.id)">
                                    <v-icon>mdi-bank-check</v-icon>
                                </v-btn>
                            </v-col>
                            <v-col cols="auto">
                                <v-btn icon color="error" @click="deleteAccount(item.id)">
                                    <v-icon>mdi-bank-remove</v-icon>
                                </v-btn>
                            </v-col>
                        </v-row>
                    </template>
                </v-data-table>
            </v-col>
        </v-row>
        <dialog-add v-model="dialog_add" @close="getListAccount"></dialog-add>
        <dialog-edit v-model="dialog_edit" :account-id="account_selected" @close="getListAccount"></dialog-edit>
        <dialog-delete v-model="dialog_remove" :account-id="account_selected" @close="getListAccount"></dialog-delete>
        <dialog-list-extract v-model="dialog_list_extract" :account-id="account_selected" @close="getListAccount"></dialog-list-extract>
        <dialog-transfer v-model="dialog_transfer" :account-source="account_selected" @close="getListAccount"></dialog-transfer>
    </v-container>
</template>

<script>
import Vue from "vue";

import DialogAdd from "./components/DialogAdd";
import DialogEdit from "./components/DialogEdit";
import DialogDelete from "./components/DialogDelete";
import DialogListExtract from "../extract/components/DialogListExtract";
import DialogTransfer from "./components/DialogTransfer";

import { mapActions, mapState } from "vuex";

export default {
    components: {
        DialogAdd,
        DialogEdit,
        DialogDelete,
        DialogListExtract,
        DialogTransfer
    },
    data() {
        return {
            headers: [
                { text: "Conta", value: "name", cellClass: "text-no-wrap" },
                { text: "Saldo", value: "balance" },
                { text: "Tipo", value: "account_type" },
                { text: "Soma no resumo", value: "include_summary", class: "text-no-wrap" },
                { text: "Ações", value: "actions", sortable: false }
            ],
            loading: false,
            dialog_add: false,
            dialog_edit: false,
            dialog_remove: false,
            dialog_list_extract: false,
            dialog_transfer: false,
            account_selected: null
        };
    },
    computed: {
        ...mapState("utils", {
            month: state => state.month,
            year: state => state.year
        }),
        ...mapState("account", {
            accounts: state => state.accounts
        })
    },
    watch: {
        month() {
            this.getListAccount();
        },
        year() {
            this.getListAccount();
        }
    },
    mounted() {
        if (!this.accounts || this.accounts.length === 0) {
            this.getListAccount();
        }
    },
    methods: {
        ...mapActions("account", ["ActionDoListAccounts"]),
        async getListAccount() {
            this.loading = true;
            await this.ActionDoListAccounts().then(() => {
                this.loading = false;
            });
        },
        editAccount(id) {
            this.account_selected = id;
            this.dialog_edit = true;
        },
        deleteAccount(id) {
            this.account_selected = id;
            this.dialog_remove = true;
        },
        transferAccount(id) {
            this.account_selected = id;
            this.dialog_transfer = true;
        },
        getColorAccountType(account_type) {
            return Vue.getAccountTypeColor(account_type);
        },
        listExtract(item) {
            this.account_selected = item.id;
            this.dialog_list_extract = true;
        }
    }
};
</script>
