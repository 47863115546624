<template>
    <v-dialog v-model="value" max-width="500px" persistent>
        <v-card>
            <v-card-title>
                <span class="text-h5">
                    Transferência entre contas
                    <v-icon right>
                        mdi-bank-transfer
                    </v-icon>
                </span>
            </v-card-title>
            <v-card-text>
                <v-container>
                    <v-alert v-if="error_message" type="error">
                        {{ error_message }}
                    </v-alert>
                    <v-form ref="formTransfer">
                        <v-row>
                            <v-col cols="6">
                                <v-select
                                    v-model="source_account"
                                    label="Origem"
                                    :rules="account_rules"
                                    :items="accounts"
                                ></v-select>
                            </v-col>
                            <v-col cols="6">
                                <v-select
                                    v-model="destination_account"
                                    label="Destino"
                                    :rules="account_rules"
                                    :items="accounts"
                                ></v-select>
                            </v-col>
                            <v-col cols="12">
                                <base-text-money
                                    v-model="balance"
                                    label="Valor"
                                    :rules="balance_rules"
                                ></base-text-money>
                            </v-col>
                        </v-row>
                    </v-form>
                </v-container>
            </v-card-text>
            <v-card-actions>
                <v-spacer></v-spacer>
                <v-btn
                    color="primary"
                    text
                    :disabled="loading"
                    @click="close"
                >
                    Cancelar
                </v-btn>
                <v-btn
                    color="success"
                    text
                    :disabled="loading"
                    @click="transfer"
                >
                    Transferir
                </v-btn>
            </v-card-actions>
            <v-progress-linear
                v-if="loading"
                indeterminate
                height="6"
            ></v-progress-linear>
        </v-card>
        <base-notify
            v-model="show_notify"
            :type="notify_type"
            :message="notify_message"
        >
        </base-notify>
    </v-dialog>
</template>

<script>
import BaseNotify from "../../../components/BaseNotify";
import BaseTextMoney from "../../../components/BaseTextMoney";
import { mapActions, mapState } from "vuex";

export default {
    components: {
        BaseNotify,
        BaseTextMoney
    },
    props: {
        value: {
            type: Boolean,
            required: true
        },
        accountSource: {
            type: Number,
            required: false,
            default: null
        }
    },
    data() {
        return {
            source_account: this.accountSource,
            destination_account: null,
            balance: null,
            balance_rules: [
                v => !!v || "Informe um valor de transferencia",
            ],
            account_rules: [
                v => !!v || "Selecione uma conta",
            ],
            loading: false,
            error_message: null,
            show_notify: false,
            notify_type: null,
            notify_message: "",
            accounts: []
        };
    },
    computed: {
        ...mapState("account", {
            list_accounts: state => state.accounts
        })
    },
    watch: {
        accountSource() {
            this.source_account = this.accountSource;
        }
    },
    mounted() {
        if (!this.list_accounts || this.list_accounts.length === 0) {
            this.getListAccount();
        } else {
            this.makeListAccounts();
        }
    },
    methods: {
        ...mapActions("account", ["ActionDoListAccounts", "ActionDoEditAccount"]),
        async getListAccount() {
            await this.ActionDoListAccounts().then(() => {
                this.makeListAccounts();
            });
        },
        makeListAccounts() {
            let bank = [];
            let voucher = [];

            for (let account of this.list_accounts) {
                if (account.account_type === "B") {
                    bank.push({
                        text: account.name,
                        value: account.id,
                        divider: false
                    });
                } else {
                    voucher.push({
                        text: account.name,
                        value: account.id,
                        divider: false
                    });
                }
            }

            this.accounts.push({
                text: "Conta bancária",
                value: "B",
                header: "Conta bancária"
            });

            for (let bank_account of bank) {
                this.accounts.push(bank_account);
            }

            this.accounts.push({
                text: "Voucher",
                value: "V",
                header: "Voucher"
            });

            for (let voucher_account of voucher) {
                this.accounts.push(voucher_account);
            }
        },
        close() {
            this.$refs.formTransfer.reset();
            this.$emit("close");
            this.$emit("input", false);
        },
        transfer() {
            this.loading = true;

            if (this.$refs.formTransfer.validate()) {
                let source = null;
                let destination = null;

                for (let account of this.list_accounts) {
                    if (account.id === this.source_account) {
                        source = account;
                    }
                    if (account.id === this.destination_account) {
                        destination = account;
                    }
                    if (source && destination) {
                        break;
                    }
                }

                if (destination && source) {
                    let source_balance = Number.isInteger(source.balance) ? source.balance : parseInt(source.balance.replace(/[^0-9]+/g, ""));
                    let destination_balance = Number.isInteger(destination.balance) ? destination.balance : parseInt(destination.balance.replace(/[^0-9]+/g, ""));
                    let transfer_value = Number.isInteger(this.balance) ? this.balance : parseInt(this.balance.replace(/[^0-9]+/g, ""));

                    try {
                        this.ActionDoEditAccount({
                            id: source.id,
                            balance: source_balance - transfer_value,
                            name: source.name,
                            account_type: source.account_type,
                            include_summary: source.include_summary
                        }).then(() => {
                            this.error_message = null;

                            this.ActionDoEditAccount({
                                id: destination.id,
                                balance: destination_balance + transfer_value,
                                name: destination.name,
                                account_type: destination.account_type,
                                include_summary: destination.include_summary
                            }).then(response => {
                                this.error_message = null;

                                if (response.account) {
                                    this.show_notify = true;
                                    this.notify_type = "success";
                                    this.notify_message = "Transferencia realizada!";
                                    this.loading = false;
                                    this.$refs.formTransfer.reset();
                                    this.close();
                                } else {
                                    this.show_notify = true;
                                    this.notify_type = "error";
                                    this.notify_message = "Ocorreu um erro!";
                                    this.loading = false;
                                    this.error_message = response.error;
                                }
                            });
                        });
                    } catch (error) {
                        this.show_notify = true;
                        this.notify_type = "error";
                        this.notify_message = "Ocorreu um erro!";
                        this.loading = false;
                        this.error_message = error.response.data.error;
                    }
                } else {
                    this.show_notify = true;
                    this.notify_type = "error";
                    this.notify_message = "Ocorreu um erro ao buscar dados das contas selecionadas!";
                    this.loading = false;
                }
            } else {
                this.loading = false;
            }
        }
    }
};
</script>
