import services from "../../../http";
import * as types from "./mutations-types";

const baseUrl = "future-release";

export const ActionDoCreateFutureRelease = (context, payload) => {
    return services.api.request({
        url: baseUrl,
        method: "post",
        data: payload
    }).then(response_data => {
        let response = response_data.data;

        return response;
    });
};

export const ActionDoListFutureReleases = () => {
    return services.api.request({
        url: baseUrl,
        method: "get"
    }).then(response_data => {
        let response = response_data.data;

        return response;
    });
};

export const ActionDoEditFutureRelease = (context, payload) => {
    return services.api.request({
        url: baseUrl + "/" + payload.id,
        method: "put",
        data: payload
    }).then(response_data => {
        let response = response_data.data;

        return response;
    });
};

export const ActionDoReadFutureRelease = (context, future_release_id) => {
    return services.api.request({
        url: baseUrl + "/" + future_release_id,
        method: "get"
    }).then(response_data => {
        let response = response_data.data;

        return response;
    });
};

export const ActionDoDeleteFutureRelease = (context, future_release_id) => {
    return services.api.request({
        url: baseUrl + "/" + future_release_id,
        method: "delete"
    }).then(response_data => {
        let response = response_data.data;

        return response;
    });
};

export const ActionDoListFutureReleasesByPeriod = ({ commit }, payload) => {
    return services.api.request({
        url: baseUrl + "/list-period",
        method: "post",
        data: payload
    }).then(response_data => {
        let response = response_data.data;

        commit(types.SET_FUTURE_RELESES_PERIOD, response);

        return response;
    });
};

export const ActionDoApproveRelease = (context, payload) => {
    return services.api.request({
        url: baseUrl + "/approve-release",
        method: "post",
        data: payload
    }).then(response_data => {
        let response = response_data.data;

        return response;
    });
};