<template>
    <v-dialog v-model="value" max-width="500px" persistent>
        <v-card>
            <v-card-title>
                <span class="text-h5">
                    Nova conta
                    <v-icon right>
                        mdi-bank-plus
                    </v-icon>
                </span>
            </v-card-title>
            <v-card-text>
                <v-container>
                    <v-alert v-if="error_message" type="error">
                        {{ error_message }}
                    </v-alert>
                    <v-form ref="formAdd">
                        <v-row>
                            <v-col cols="6">
                                <v-text-field
                                    v-model="name"
                                    label="Nome da conta"
                                    :rules="name_rules"
                                ></v-text-field>
                            </v-col>
                            <v-col cols="6">
                                <base-text-money
                                    v-model="balance"
                                    label="Saldo da conta"
                                    :rules="balance_rules"
                                ></base-text-money>
                            </v-col>
                            <v-col cols="12">
                                <v-select
                                    v-model="account_type"
                                    label="Tipo de conta"
                                    :rules="account_type_rules"
                                    :items="list_account_type"
                                ></v-select>
                            </v-col>
                            <v-col cols="12">
                                <v-switch
                                    v-model="include_summary"
                                    inset
                                    color="primary"
                                    label="Incluir na soma do resumo"
                                ></v-switch>
                            </v-col>
                        </v-row>
                    </v-form>
                </v-container>
            </v-card-text>
            <v-card-actions>
                <v-spacer></v-spacer>
                <v-btn
                    color="primary"
                    text
                    :disabled="loading"
                    @click="close"
                >
                    Cancelar
                </v-btn>
                <v-btn
                    color="success"
                    text
                    :disabled="loading"
                    @click="create"
                >
                    Salvar
                </v-btn>
            </v-card-actions>
            <v-progress-linear
                v-if="loading"
                indeterminate
                height="6"
            ></v-progress-linear>
        </v-card>
        <base-notify
            v-model="show_notify"
            :type="notify_type"
            :message="notify_message"
        >
        </base-notify>
    </v-dialog>
</template>

<script>
import BaseNotify from "../../../components/BaseNotify";
import BaseTextMoney from "../../../components/BaseTextMoney";
import { mapActions } from "vuex";

export default {
    components: {
        BaseNotify,
        BaseTextMoney
    },
    props: {
        value: {
            type: Boolean,
            required: true
        }
    },
    data() {
        return {
            name: null,
            name_rules: [
                v => !!v || "Digite um nome",
            ],
            balance: null,
            balance_rules: [
                v => !!v || "Informe um saldo inicial",
            ],
            account_type: null,
            account_type_rules: [
                v => !!v || "Selecione um tipo de conta",
            ],
            list_account_type: [
                { text: "Conta bancária", value: this.$accountBank },
                { text: "Voucher", value: this.$accountVoucher }
            ],
            include_summary: false,
            loading: false,
            error_message: null,
            show_notify: false,
            notify_type: null,
            notify_message: ""
        };
    },
    methods: {
        ...mapActions("account", ["ActionDoCreateAccount"]),
        close() {
            this.$refs.formAdd.reset();
            this.$emit("close");
            this.$emit("input", false);
        },
        create() {
            this.loading = true;

            if (this.$refs.formAdd.validate()) {
                try {
                    this.ActionDoCreateAccount({
                        name: this.name,
                        balance: parseInt(this.balance.replace(/[^0-9]+/g, "")),
                        account_type: this.account_type,
                        include_summary: this.include_summary
                    }).then(response => {
                        this.error_mesasge = null;

                        if (response.account) {
                            this.show_notify = true;
                            this.notify_type = "success";
                            this.notify_message = "Conta salva com sucesso!";
                            this.loading = false;
                            this.$refs.formAdd.reset();
                            this.close();
                        } else {
                            this.show_notify = true;
                            this.notify_type = "error";
                            this.notify_message = "Ocorreu um erro!";
                            this.loading = false;
                            this.error_mesasge = response.error;
                        }
                    });
                } catch (error) {
                    this.show_notify = true;
                    this.notify_type = "error";
                    this.notify_message = "Ocorreu um erro!";
                    this.loading = false;
                    this.error_mesasge = error.response.data.error;
                }
            } else {
                this.loading = false;
            }
        }
    }
};
</script>