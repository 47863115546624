<template>
    <v-speed-dial
        v-model="fab"
        direction="bottom"
        transition="slide-y-transition"
    >
        <template #activator>
            <v-btn
                v-model="fab"
                color="blue darken-2"
                dark
                fab
            >
                <v-icon v-if="fab">
                    mdi-close
                </v-icon>
                <v-icon v-else>
                    mdi-plus
                </v-icon>
            </v-btn>
        </template>
        <v-tooltip left color="error">
            <template v-slot:activator="{ on, attrs }">
                <v-btn
                    fab
                    dark
                    small
                    color="error"
                    v-bind="attrs"
                    v-on="on"
                    @click="$emit('creditCardClick')"
                >
                    <v-icon>mdi-credit-card-plus-outline</v-icon>
                </v-btn>
            </template>
            <span>Despesa de cartão</span>
        </v-tooltip>
        <v-tooltip left color="purple">
            <template v-slot:activator="{ on, attrs }">
                <v-btn
                    fab
                    dark
                    small
                    color="purple"
                    v-bind="attrs"
                    v-on="on"
                    @click="$emit('accountClick')"
                >
                    <v-icon>mdi-piggy-bank-outline</v-icon>
                </v-btn>
            </template>
            <span>Lançamento de conta</span>
        </v-tooltip>
        <v-tooltip left color="orange">
            <template v-slot:activator="{ on, attrs }">
                <v-btn
                    fab
                    dark
                    small
                    color="orange"
                    v-bind="attrs"
                    v-on="on"
                    @click="$emit('futureReleaseClick')"
                >
                    <v-icon>mdi-calendar-clock</v-icon>
                </v-btn>
            </template>
            <span>Lançamento Futuro</span>
        </v-tooltip>
        <v-tooltip left color="lime">
            <template v-slot:activator="{ on, attrs }">
                <v-btn
                    fab
                    dark
                    small
                    color="lime"
                    v-bind="attrs"
                    v-on="on"
                    @click="$emit('transferClick')"
                >
                    <v-icon>mdi-bank-transfer</v-icon>
                </v-btn>
            </template>
            <span>Transferência entre contas</span>
        </v-tooltip>
    </v-speed-dial>
</template>

<script>
export default {
    data() {
        return {
            fab: null
        };
    }
};
</script>
