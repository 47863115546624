<template>
    <v-dialog v-model="value" max-width="500px" persistent>
        <v-card>
            <v-card-title>
                <span class="text-h5">
                    Editar conta
                    <v-icon right>
                        mdi-bank-check
                    </v-icon>
                </span>
            </v-card-title>
            <v-card-text>
                <v-container>
                    <v-alert v-if="error_message" type="error">
                        {{ error_message }}
                    </v-alert>
                    <v-form ref="formEdit">
                        <v-row>
                            <v-col cols="6">
                                <v-text-field
                                    v-model="account.name"
                                    label="Nome da conta"
                                    :rules="name_rules"
                                ></v-text-field>
                            </v-col>
                            <v-col cols="6">
                                <base-text-money
                                    v-model="account.balance"
                                    label="Saldo da conta"
                                    :rules="balance_rules"
                                ></base-text-money>
                            </v-col>
                            <v-col cols="12">
                                <v-select
                                    v-model="account.account_type"
                                    label="Tipo de conta"
                                    :rules="account_type_rules"
                                    :items="list_account_type"
                                ></v-select>
                            </v-col>
                            <v-col cols="12">
                                <v-switch
                                    v-model="account.include_summary"
                                    inset
                                    color="primary"
                                    label="Incluir na soma do resumo"
                                ></v-switch>
                            </v-col>
                        </v-row>
                    </v-form>
                </v-container>
            </v-card-text>
            <v-card-actions>
                <v-spacer></v-spacer>
                <v-btn
                    color="primary"
                    text
                    :disabled="saving"
                    @click="close"
                >
                    Cancelar
                </v-btn>
                <v-btn
                    color="success"
                    text
                    :disabled="saving"
                    @click="save"
                >
                    Salvar
                </v-btn>
            </v-card-actions>
            <v-progress-linear
                v-if="saving"
                indeterminate
                height="6"
            ></v-progress-linear>
        </v-card>
        <base-notify
            v-model="show_notify"
            :type="notify_type"
            :message="notify_message"
        >
        </base-notify>
    </v-dialog>
</template>

<script>
import BaseNotify from "../../../components/BaseNotify";
import BaseTextMoney from "../../../components/BaseTextMoney";
import { mapActions } from "vuex";

export default {
    components: {
        BaseNotify,
        BaseTextMoney
    },
    props: {
        value: {
            type: Boolean,
            required: true
        },
        accountId: {
            type: Number,
            required: false,
            default: null
        }
    },
    data() {
        return {
            name_rules: [
                v => !!v || "Digite um nome",
            ],
            balance_rules: [
                v => !!v || "Informe um saldo",
            ],
            account_type_rules: [
                v => !!v || "Selecione um tipo de conta",
            ],
            list_account_type: [
                { text: "Conta bancária", value: this.$accountBank },
                { text: "Voucher", value: this.$accountVoucher }
            ],
            loading: false,
            saving: false,
            error_message: null,
            show_notify: false,
            notify_type: null,
            notify_message: "",
            account: {
                name: null,
                balance: null,
                account_type: null,
                include_summary: false
            }
        };
    },
    watch: {
        value(new_value) {
            if (new_value) {
                this.read();
            }
        }
    },
    methods: {
        ...mapActions("account", ["ActionDoReadAccount", "ActionDoEditAccount"]),
        close() {
            this.$refs.formEdit.reset();
            this.$emit("close");
            this.$emit("input", false);
        },
        async read() {
            this.loading = true;

            if (this.accountId) {
                await this.ActionDoReadAccount(this.accountId).then(response => {
                    this.account = response;
                    this.loading = false;
                });
            } else {
                this.close();
            }
        },
        save() {
            this.saving = true;

            if (this.$refs.formEdit.validate()) {
                try {
                    this.ActionDoEditAccount({
                        id: this.account.id,
                        name: this.account.name,
                        balance: Number.isInteger(this.account.balance) ? this.account.balance : parseInt(this.account.balance.replace(/[^0-9]+/g, "")),
                        account_type: this.account.account_type,
                        include_summary: this.account.include_summary
                    }).then(response => {
                        this.error_mesasge = null;

                        if (response.account) {
                            this.show_notify = true;
                            this.notify_type = "success";
                            this.notify_message = "Conta salva com sucesso!";
                            this.saving = false;
                            this.$refs.formEdit.reset();
                            this.close();
                        } else {
                            this.show_notify = true;
                            this.notify_type = "error";
                            this.notify_message = "Ocorreu um erro!";
                            this.saving = false;
                            this.error_mesasge = response.error;
                        }
                    });
                } catch (error) {
                    this.show_notify = true;
                    this.notify_type = "error";
                    this.notify_message = "Ocorreu um erro!";
                    this.saving = false;
                    this.error_mesasge = error.response.data.error;
                }
            } else {
                this.saving = false;
            }
        }
    }
};
</script>