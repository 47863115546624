import { store as auth } from "../views/auth";
import { store as utils } from "../utils";
import { store as dashboard } from "../views/dashboard";
import { store as credit_card } from "../views/credit-card";
import { store as account } from "../views/account";
import { store as settings } from "../views/settings";
import { store as credit_card_record } from "../views/credit-card-record";
import { store as recurrent_record } from "../views/recurrent-record";
import { store as extract } from "../views/extract";
import { store as future_release } from "../views/future-release";
import { store as invoice } from "../views/invoice";
import { store as budget } from "../views/budget";

export default {
    auth,
    utils,
    dashboard,
    credit_card,
    account,
    settings,
    credit_card_record,
    recurrent_record,
    extract,
    future_release,
    invoice,
    budget
};