import Vue from "vue";
import VueRouter from "vue-router";
import routes from "./routes";
import store from "../store";

Vue.use(VueRouter);

const router = new VueRouter({
    mode: "history",
    routes
});

router.beforeEach((to, from, next) => {
    if (to.name !== "auth" && (!store.state.auth.user || !store.state.auth.token)) {
        next({ name: "auth" });
    } else {
        next();
    }
});

export default router;