import services from "../../../http";
import * as types from "./mutations-types";

const baseUrl = "credit-card-record";

export const ActionDoCreateCreditCardRecord = (context, payload) => {
    return services.api.request({
        url: baseUrl,
        method: "post",
        data: payload
    }).then(response_data => {
        let response = response_data.data;

        return response;
    });
};

export const ActionDoReadCreditCardRecords = (context, payload) => {
    return services.api.request({
        url: baseUrl + "/credit-card",
        method: "post",
        data: payload
    }).then(response_data => {
        let response = response_data.data;

        return response;
    });
};

export const ActionDoEditCreditCardRecord = (context, payload) => {
    return services.api.request({
        url: baseUrl + "/" + payload.id,
        method: "put",
        data: payload
    }).then(response_data => {
        let response = response_data.data;

        return response;
    });
};

export const ActionDoReadCreditCardRecord = (context, record_id) => {
    return services.api.request({
        url: baseUrl + "/" + record_id,
        method: "get"
    }).then(response_data => {
        let response = response_data.data;

        return response;
    });
};

export const ActionDoDeleteCreditCardRecord = (context, record_id) => {
    return services.api.request({
        url: baseUrl + "/" + record_id,
        method: "delete"
    }).then(response_data => {
        let response = response_data.data;

        return response;
    });
};

export const ActionDoGetTotalYear = ({ commit }, payload) => {
    return services.api.request({
        url: baseUrl + "/total-year",
        method: "post",
        data: payload
    }).then(response_data => {
        let response = response_data.data;

        commit(types.SET_LIST_TOTAL_YEAR, response);

        return response;
    });
};
