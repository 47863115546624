<template>
    <v-dialog v-model="value" max-width="500px" persistent>
        <v-card>
            <v-card-title>
                <span class="text-h5">
                    Novo registro
                    <v-icon right>
                        mdi-cash-plus
                    </v-icon>
                </span>
            </v-card-title>
            <v-card-text>
                <v-container>
                    <v-alert v-if="error_message" type="error">
                        {{ error_message }}
                    </v-alert>
                    <v-form ref="formAdd">
                        <v-row>
                            <v-col cols="6">
                                <v-switch
                                    v-model="expense"
                                    inset
                                    color="error"
                                    label="Despesa"
                                ></v-switch>
                            </v-col>
                            <v-col cols="6">
                                <v-switch
                                    v-model="income"
                                    inset
                                    color="success"
                                    label="Receita"
                                ></v-switch>
                            </v-col>
                            <v-col cols="6">
                                <base-date-picker
                                    v-model="date"
                                    label="Data do registro"
                                    :rules="date_rules"
                                ></base-date-picker>
                            </v-col>
                            <v-col cols="6">
                                <base-text-money
                                    v-model="price"
                                    label="Valor"
                                    :rules="price_rules"
                                ></base-text-money>
                            </v-col>
                            <v-col cols="6">
                                <v-autocomplete
                                    v-model="category"
                                    label="Categoria"
                                    :rules="category_rules"
                                    :items="macro_category"
                                    return-object
                                ></v-autocomplete>
                            </v-col>
                            <v-col cols="6">
                                <v-select
                                    v-model="account"
                                    label="Conta / Voucher"
                                    :rules="account_rules"
                                    :items="accounts"
                                ></v-select>
                            </v-col>
                            <v-col cols="12">
                                <v-text-field
                                    v-model="description"
                                    label="Título"
                                    :rules="description_rules"
                                ></v-text-field>
                            </v-col>
                            <v-col cols="12">
                                <v-textarea
                                    v-model="long_description"
                                    label="Descrição"
                                />
                            </v-col>
                        </v-row>
                    </v-form>
                </v-container>
            </v-card-text>
            <v-card-actions>
                <v-spacer></v-spacer>
                <v-btn
                    color="primary"
                    text
                    :disabled="loading"
                    @click="close"
                >
                    Cancelar
                </v-btn>
                <v-btn
                    color="success"
                    text
                    :disabled="loading"
                    @click="create"
                >
                    Salvar
                </v-btn>
            </v-card-actions>
            <v-progress-linear
                v-if="loading"
                indeterminate
                height="6"
            ></v-progress-linear>
        </v-card>
        <base-notify
            v-model="show_notify"
            :type="notify_type"
            :message="notify_message"
        >
        </base-notify>
    </v-dialog>
</template>

<script>
import BaseNotify from "../../../components/BaseNotify.vue";
import BaseTextMoney from "../../../components/BaseTextMoney";
import BaseDatePicker from "../../../components/BaseDatePicker";
import { mapActions, mapState } from "vuex";

export default {
    components: {
        BaseNotify,
        BaseTextMoney,
        BaseDatePicker
    },
    props: {
        value: {
            type: Boolean,
            required: true
        }
    },
    data() {
        return {
            date: (new Date(Date.now() - (new Date()).getTimezoneOffset() * 60000)).toISOString().substr(0, 10),
            date_rules: [
                v => !!v || "Informe uma data",
            ],
            price: null,
            price_rules: [
                v => !!v || "Informe um valor",
            ],
            category: null,
            category_rules: [
                v => !!v || "Selecione uma categoria",
            ],
            account: null,
            account_rules: [
                v => !!v || "Selecione uma conta",
            ],
            description: null,
            description_rules: [
                v => !!v || "Informe um título"
            ],
            long_description: null,
            income: false,
            expense: true,
            loading: false,
            error_message: null,
            show_notify: false,
            notify_type: null,
            notify_message: "",
            macro_category: [],
            accounts: []
        };
    },
    computed: {
        ...mapState("account", {
            list_accounts: state => state.accounts
        }),
        ...mapState("settings", {
            list_macro_category: state => state.macro_category
        })
    },
    watch: {
        income(new_value) {
            this.expense = !new_value;
        },
        expense(new_value) {
            this.income = !new_value;
        }
    },
    mounted() {
        if (!this.list_macro_category || this.list_macro_category.length === 0) {
            this.getListMacroCategory();
        } else {
            this.makeListMacroCategory();
        }

        if (!this.list_accounts || this.list_accounts.length === 0) {
            this.getListAccount();
        } else {
            this.makeListAccounts();
        }
    },
    methods: {
        ...mapActions("settings", ["ActionDoListMacroCategory"]),
        ...mapActions("account", ["ActionDoListAccounts"]),
        ...mapActions("extract", ["ActionDoCreateExtract"]),
        close(budget_percent, category) {
            this.cleanForm();
            this.$emit("close", {budget_percent, category});
            this.$emit("input", false);
        },
        cleanForm() {
            this.date = (new Date(Date.now() - (new Date()).getTimezoneOffset() * 60000)).toISOString().substr(0, 10);
            this.price = null;
            this.category = null;
            this.account = null;
            this.description = null;
            this.long_description = null;
            this.income = false;
            this.expense = true;

            this.$refs.formAdd.resetValidation();
        },
        create() {
            this.loading = true;

            if (this.$refs.formAdd.validate()) {
                try {
                    this.ActionDoCreateExtract({
                        date: this.date,
                        description: this.description,
                        long_description: this.long_description,
                        value: parseInt(this.price),
                        type: this.income ? "I" : "E",
                        account_id: this.account,
                        category_id: this.category.value
                    }).then(response => {
                        this.error_mesasge = null;

                        if (response.extract) {
                            this.show_notify = true;
                            this.notify_type = "success";
                            this.notify_message = "Registro salvo com sucesso!";
                            this.loading = false;
                            this.close(response.budget_percent, this.category.text);
                        } else {
                            this.show_notify = true;
                            this.notify_type = "error";
                            this.notify_message = "Ocorreu um erro!";
                            this.loading = false;
                            this.error_mesasge = response.error;
                        }
                    });
                } catch (error) {
                    this.show_notify = true;
                    this.notify_type = "error";
                    this.notify_message = "Ocorreu um erro!";
                    this.loading = false;
                    this.error_mesasge = error.response.data.error;
                }
            } else {
                this.loading = false;
            }
        },
        async getListMacroCategory() {
            await this.ActionDoListMacroCategory().then(() => {
                this.makeListMacroCategory();
            });
        },
        makeListMacroCategory() {
            for (let macro of this.list_macro_category) {
                this.macro_category.push({
                    text: macro.name,
                    value: macro.id + "macro",
                    header: macro.name
                });

                for (let category of macro.categories) {
                    this.macro_category.push({
                        text: category.name,
                        value: category.id,
                        divider: false
                    });
                }
            }
        },
        async getListAccount() {
            await this.ActionDoListAccounts().then(() => {
                this.makeListAccounts();
            });
        },
        makeListAccounts() {
            let bank = [];
            let voucher = [];

            for (let account of this.list_accounts) {
                if (account.account_type === "B") {
                    bank.push({
                        text: account.name,
                        value: account.id,
                        divider: false
                    });
                } else {
                    voucher.push({
                        text: account.name,
                        value: account.id,
                        divider: false
                    });
                }
            }

            this.accounts.push({
                text: "Conta bancária",
                value: "B",
                header: "Conta bancária"
            });

            for (let bank_account of bank) {
                this.accounts.push(bank_account);
            }

            this.accounts.push({
                text: "Voucher",
                value: "V",
                header: "Voucher"
            });

            for (let voucher_account of voucher) {
                this.accounts.push(voucher_account);
            }
        }
    }
};
</script>
