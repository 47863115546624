<template>
    <v-dialog v-model="value" max-width="500px" persistent>
        <v-card>
            <v-card-title>
                <span class="text-h5">
                    Nova despesa de cartão
                    <v-icon right>
                        mdi-credit-card-fast-outline
                    </v-icon>
                </span>
            </v-card-title>
            <v-card-text>
                <v-container>
                    <v-alert v-if="error_message" type="error">
                        {{ error_message }}
                    </v-alert>
                    <v-form ref="formAdd">
                        <v-row>
                            <v-col cols="6">
                                <base-date-picker
                                    v-model="date"
                                    label="Data da despesa"
                                    :rules="date_rules"
                                ></base-date-picker>
                            </v-col>
                            <v-col cols="6">
                                <base-text-money
                                    v-model="price"
                                    label="Valor"
                                    :rules="price_rules"
                                ></base-text-money>
                            </v-col>
                            <v-col cols="6">
                                <v-autocomplete
                                    v-model="category"
                                    label="Categoria"
                                    :rules="category_rules"
                                    :items="macro_category"
                                    return-object
                                ></v-autocomplete>
                            </v-col>
                            <v-col cols="6">
                                <v-select
                                    v-model="credit_card"
                                    label="Cartão de crédito"
                                    :rules="credit_card_rules"
                                    :items="credit_cards"
                                ></v-select>
                            </v-col>
                            <v-col cols="12">
                                <v-text-field
                                    v-model="description"
                                    label="Título"
                                    :rules="description_rules"
                                ></v-text-field>
                            </v-col>
                            <v-col cols="12">
                                <v-textarea
                                    v-model="long_description"
                                    label="Descrição"
                                />
                            </v-col>
                            <v-col cols="6">
                                <v-switch
                                    v-model="is_recurrent"
                                    label="Compra recorrente"
                                    :disabled="is_a_parcel"
                                ></v-switch>
                            </v-col>
                            <v-col cols="6">
                                <v-switch
                                    v-model="is_a_parcel"
                                    label="Compra parcelada"
                                    :disabled="is_recurrent"
                                ></v-switch>
                            </v-col>
                            <v-col v-if="is_a_parcel" cols="12">
                                <v-text-field
                                    v-model="number_of_parcels"
                                    label="Número de parcelas"
                                    type="number"
                                    :rules="number_of_parcels_rules"
                                ></v-text-field>
                            </v-col>
                            <v-col cols="12">
                                <v-switch
                                    v-model="closed_invoice"
                                    label="Inserir em fatura fechada"
                                    :disabled="is_recurrent || !credit_card"
                                ></v-switch>
                            </v-col>
                            <v-col v-if="closed_invoice" cols="12">
                                <v-select
                                    v-model="invoice_id"
                                    label="Fatura"
                                    :rules="invoice_rules"
                                    :items="invoices"
                                ></v-select>
                            </v-col>
                        </v-row>
                    </v-form>
                </v-container>
            </v-card-text>
            <v-card-actions>
                <v-spacer></v-spacer>
                <v-btn
                    color="primary"
                    text
                    :disabled="loading"
                    @click="close"
                >
                    Cancelar
                </v-btn>
                <v-btn
                    color="success"
                    text
                    :disabled="loading"
                    @click="create"
                >
                    Salvar
                </v-btn>
            </v-card-actions>
            <v-progress-linear
                v-if="loading"
                indeterminate
                height="6"
            ></v-progress-linear>
        </v-card>
        <base-notify
            v-model="show_notify"
            :type="notify_type"
            :message="notify_message"
        >
        </base-notify>
    </v-dialog>
</template>

<script>
import BaseNotify from "../../../components/BaseNotify.vue";
import BaseTextMoney from "../../../components/BaseTextMoney";
import BaseDatePicker from "../../../components/BaseDatePicker";
import { mapActions, mapState } from "vuex";

export default {
    components: {
        BaseNotify,
        BaseTextMoney,
        BaseDatePicker
    },
    props: {
        value: {
            type: Boolean,
            required: true
        }
    },
    data() {
        return {
            date: (new Date(Date.now() - (new Date()).getTimezoneOffset() * 60000)).toISOString().substr(0, 10),
            date_rules: [
                v => !!v || "Informe uma data",
            ],
            price: null,
            price_rules: [
                v => !!v || "Informe um valor",
            ],
            category: null,
            category_rules: [
                v => !!v || "Selecione uma categoria",
            ],
            credit_card: null,
            credit_card_rules: [
                v => !!v || "Selecione um cartão de crédito",
            ],
            invoice_rules: [
                v => !!v || "Selecione uma fatura",
            ],
            description: null,
            description_rules: [
                v => !!v || "Informe uma descrição"
            ],
            long_description: null,
            is_a_parcel: false,
            is_recurrent: false,
            closed_invoice: false,
            number_of_parcels: null,
            number_of_parcels_rules: [
                v => (this.is_a_parcel && !!v) || "Informe a quantidade de parcelas"
            ],
            loading: false,
            error_message: null,
            show_notify: false,
            notify_type: null,
            notify_message: "",
            macro_category: [],
            credit_cards: [],
            invoices: [],
            invoice_id: null
        };
    },
    computed: {
        ...mapState("credit_card", {
            list_credit_cards: state => state.credit_cards
        }),
        ...mapState("settings", {
            list_macro_category: state => state.macro_category
        })
    },
    watch: {
        closed_invoice(new_value) {
            if (new_value) {
                this.getListInvoices();
            }
        }
    },
    mounted() {
        if (!this.list_credit_cards || this.list_credit_cards.length === 0) {
            this.getListCreditCards();
        } else {
            this.makeListCreditCard();
        }

        if (!this.list_macro_category || this.list_macro_category.length === 0) {
            this.getListMacroCategory();
        } else {
            this.makeListMacroCategory();
        }
    },
    methods: {
        ...mapActions("settings", ["ActionDoListMacroCategory"]),
        ...mapActions("credit_card", ["ActionDoListCreditCards"]),
        ...mapActions("credit_card_record", ["ActionDoCreateCreditCardRecord"]),
        ...mapActions("invoice", ["ActionDoGetListByCreditCard"]),
        close(budget_percent, category) {
            this.cleanForm();
            this.$emit("close", {budget_percent, category});
            this.$emit("input", false);
        },
        cleanForm() {
            this.date = (new Date(Date.now() - (new Date()).getTimezoneOffset() * 60000)).toISOString().substr(0, 10);
            this.price = null;
            this.category = null;
            this.credit_card = null;
            this.description = null;
            this.long_description = null;
            this.is_a_parcel = false;
            this.is_recurrent = false;
            this.number_of_parcels = null;
            this.invoice_id = null;

            this.$refs.formAdd.resetValidation();
        },
        create() {
            this.loading = true;

            if (this.$refs.formAdd.validate()) {
                try {
                    this.ActionDoCreateCreditCardRecord({
                        date: this.date,
                        value: parseInt(this.price),
                        category_id: this.category.value,
                        description: this.description,
                        long_description: this.long_description,
                        credit_card_id: this.credit_card,
                        is_a_parcel: this.is_a_parcel,
                        is_recurrent: this.is_recurrent,
                        number_of_parcels: this.number_of_parcels,
                        invoice_id: this.invoice_id
                    }).then(response => {
                        this.error_mesasge = null;

                        if (response.credit_card_record) {
                            this.show_notify = true;
                            this.notify_type = "success";
                            this.notify_message = "Registro salvo com sucesso!";
                            this.loading = false;
                            this.close(response.budget_percent, this.category.text);
                        } else {
                            this.show_notify = true;
                            this.notify_type = "error";
                            this.notify_message = "Ocorreu um erro!";
                            this.loading = false;
                            this.error_mesasge = response.error;
                        }
                    });
                } catch (error) {
                    this.show_notify = true;
                    this.notify_type = "error";
                    this.notify_message = "Ocorreu um erro!";
                    this.loading = false;
                    this.error_mesasge = error.response.data.error;
                }
            } else {
                this.loading = false;
            }
        },
        async getListMacroCategory() {
            await this.ActionDoListMacroCategory().then(() => {
                this.makeListMacroCategory();
            });
        },
        makeListMacroCategory() {
            for (let macro of this.list_macro_category) {
                this.macro_category.push({
                    text: macro.name,
                    value: macro.id + "macro",
                    header: macro.name
                });

                for (let category of macro.categories) {
                    this.macro_category.push({
                        text: category.name,
                        value: category.id,
                        divider: false
                    });
                }
            }
        },
        async getListCreditCards() {
            await this.ActionDoListCreditCards().then(() => {
                this.makeListCreditCard();
            });
        },
        makeListCreditCard() {
            for (let credit_card of this.list_credit_cards) {
                this.credit_cards.push({
                    text: credit_card.name,
                    value: credit_card.id
                });
            }
        },
        async getListInvoices() {
            await this.ActionDoGetListByCreditCard({ credit_card_id: this.credit_card }).then((response) => {
                for (let invoice of response) {
                    this.invoices.push({
                        text: invoice.month + "/" + invoice.year,
                        value: invoice.id
                    });
                }
            });
        }
    }
};
</script>
