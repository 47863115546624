<template>
    <v-container>
        <v-row justify="end">
            <v-col cols="auto">
                <v-btn color="primary" @click="dialog_add = true">
                    <v-icon left>
                        mdi-credit-card-plus-outline
                    </v-icon>
                    Novo cartão
                </v-btn>
            </v-col>
        </v-row>
        <v-row>
            <v-col>
                <v-data-table
                    :headers="headers"
                    :items="credit_cards"
                    :items-per-page="10"
                    :loading="loading"
                    :mobile-breakpoint="0"
                >
                    <template v-slot:[`item.closing_date`]="{ item }">
                        <span v-date-usa-br="item.closing_date"></span>
                    </template>
                    <template v-slot:[`item.limit`]="{ item }">
                        {{ item.limit | toCurrency }}
                    </template>
                    <template v-slot:[`item.available`]="{ item }">
                        {{ (item.limit - (parseInt(item.total) + parseInt(item.total_future))) | toCurrency }}
                    </template>
                    <template v-slot:[`item.total`]="{ item }">
                        {{ item.total | toCurrency }}
                    </template>
                    <template v-slot:[`item.actions`]="{ item }">
                        <v-row class="flex-nowrap">
                            <v-col cols="auto">
                                <v-btn icon color="success" @click="listRecords(item.id)">
                                    <v-icon>mdi-receipt</v-icon>
                                </v-btn>
                            </v-col>
                            <v-col cols="auto">
                                <v-btn icon color="primary" @click="editCreditCard(item.id)">
                                    <v-icon>mdi-credit-card-edit-outline</v-icon>
                                </v-btn>
                            </v-col>
                            <v-col cols="auto">
                                <v-btn icon color="error" @click="deleteCreditCard(item.id)">
                                    <v-icon>mdi-credit-card-remove-outline</v-icon>
                                </v-btn>
                            </v-col>
                        </v-row>
                    </template>
                </v-data-table>
            </v-col>
        </v-row>
        <dialog-add v-model="dialog_add" @close="getListCreditCard"></dialog-add>
        <dialog-edit v-model="dialog_edit" :credit-card-id="credit_card_selected" @close="getListCreditCard"></dialog-edit>
        <dialog-delete v-model="dialog_remove" :credit-card-id="credit_card_selected" @close="getListCreditCard"></dialog-delete>
        <dialog-list-records v-model="dialog_list_records" :credit-card-id="credit_card_selected" @close="getListCreditCard"></dialog-list-records>
    </v-container>
</template>

<script>
import DialogAdd from "./components/DialogAdd";
import DialogEdit from "./components/DialogEdit";
import DialogDelete from "./components/DialogDelete";
import DialogListRecords from "../credit-card-record/components/DialogListRecords";
import { mapActions, mapState } from "vuex";

export default {
    components: {
        DialogAdd,
        DialogEdit,
        DialogDelete,
        DialogListRecords
    },
    data() {
        return {
            headers: [
                { text: "Cartão", value: "name", class: "text-no-wrap", cellClass: "text-no-wrap" },
                { text: "Vencimento", value: "due_date", class: "text-no-wrap", cellClass: "text-no-wrap" },
                { text: "Fechamento", value: "closing_date", class: "text-no-wrap", cellClass: "text-no-wrap" },
                { text: "Limite", value: "limit", class: "text-no-wrap", cellClass: "text-no-wrap" },
                { text: "Disponível", value: "available", class: "text-no-wrap", cellClass: "text-no-wrap" },
                { text: "Total a pagar", value: "total", class: "text-no-wrap", cellClass: "text-no-wrap" },
                { text: "Ações", value: "actions", sortable: false }
            ],
            loading: false,
            dialog_add: false,
            dialog_edit: false,
            dialog_remove: false,
            dialog_list_records: false,
            credit_card_selected: null
        };
    },
    computed: {
        ...mapState("utils", {
            month: state => state.month,
            year: state => state.year
        }),
        ...mapState("credit_card", {
            credit_cards: state => state.credit_cards_total_invoice
        })
    },
    watch: {
        month() {
            this.getListCreditCard();
        },
        year() {
            this.getListCreditCard();
        }
    },
    mounted() {
        if (!this.credit_cards || this.credit_cards.length === 0) {
            this.getListCreditCard();
        }
    },
    methods: {
        ...mapActions("credit_card", ["ActionDoListWithTotalInvoice"]),
        async getListCreditCard() {
            this.loading = true;
            await this.ActionDoListWithTotalInvoice({
                month: this.month,
                year: this.year
            }).then(() => {
                this.loading = false;
            });
        },
        editCreditCard(id) {
            this.credit_card_selected = id;
            this.dialog_edit = true;
        },
        deleteCreditCard(id) {
            this.credit_card_selected = id;
            this.dialog_remove = true;
        },
        listRecords(credit_Card_id) {
            this.credit_card_selected = credit_Card_id;
            this.dialog_list_records = true;
        }
    }
};
</script>