<template>
    <v-dialog v-model="value" max-width="500px" persistent>
        <v-card>
            <v-card-title>
                <span class="text-h5">
                    Excluir despesa de cartão
                </span>
            </v-card-title>
            <v-card-text>
                <v-container>
                    <v-alert v-if="error_message" type="error">
                        {{ error_message }}
                    </v-alert>
                    <v-form ref="formDel">
                        <v-row>
                            <v-col cols="6">
                                <base-date-picker
                                    v-model="record.date"
                                    label="Data da despesa"
                                    disabled
                                ></base-date-picker>
                            </v-col>
                            <v-col cols="6">
                                <base-text-money
                                    v-model="record.price"
                                    label="Valor"
                                    disabled
                                ></base-text-money>
                            </v-col>
                            <v-col cols="6">
                                <v-select
                                    v-model="record.category"
                                    label="Categoria"
                                    :items="macro_category"
                                    disabled
                                ></v-select>
                            </v-col>
                            <v-col cols="6">
                                <v-select
                                    v-model="record.credit_card"
                                    label="Cartão de crédito"
                                    :items="credit_cards"
                                    disabled
                                ></v-select>
                            </v-col>
                            <v-col cols="12">
                                <v-text-field
                                    v-model="record.description"
                                    label="Título"
                                    disabled
                                ></v-text-field>
                            </v-col>
                            <v-col cols="12">
                                <v-textarea
                                    v-model="record.long_description"
                                    label="Descrição"
                                    disabled
                                />
                            </v-col>
                            <v-col cols="6">
                                <v-switch
                                    v-model="record.is_recurrent"
                                    label="Compra recorrente"
                                    disabled
                                ></v-switch>
                            </v-col>
                            <v-col cols="6">
                                <v-switch
                                    v-model="record.is_a_parcel"
                                    label="Compra parcelada"
                                    disabled
                                ></v-switch>
                            </v-col>
                            <v-col v-if="record.is_a_parcel" cols="12">
                                <v-text-field
                                    v-model="record.parcel"
                                    label="Parcela"
                                    disabled
                                ></v-text-field>
                            </v-col>
                        </v-row>
                    </v-form>
                </v-container>
            </v-card-text>
            <v-card-actions>
                <v-spacer></v-spacer>
                <v-btn
                    color="primary"
                    text
                    :disabled="loading"
                    @click="close"
                >
                    Cancelar
                </v-btn>
                <v-btn
                    color="error"
                    text
                    :disabled="loading"
                    @click="confirmDelete"
                >
                    Excluir
                </v-btn>
            </v-card-actions>
            <v-progress-linear
                v-if="loading"
                indeterminate
                height="6"
            ></v-progress-linear>
        </v-card>
        <base-notify
            v-model="show_notify"
            :type="notify_type"
            :message="notify_message"
        >
        </base-notify>
        <v-dialog v-model="confirm_delete" max-width="400">
            <v-card>
                <v-card-title class="text-h5">
                    Exclusão de compra parcelada
                </v-card-title>
                <v-card-text>
                    Esta é uma compra parcelada, ao excluí-la, todas as parcelas a partir da parcela {{ record.parcel_number }} serão excluídas!
                </v-card-text>
                <v-card-actions>
                    <v-spacer></v-spacer>
                    <v-btn
                        color="primary"
                        text
                        @click="close"
                    >
                        Cancelar
                    </v-btn>
                    <v-btn
                        color="error"
                        text
                        @click="deleteRecord"
                    >
                        Confirmar e excluir
                    </v-btn>
                </v-card-actions>
            </v-card>
        </v-dialog>
    </v-dialog>
</template>

<script>
import BaseNotify from "../../../components/BaseNotify.vue";
import BaseTextMoney from "../../../components/BaseTextMoney";
import BaseDatePicker from "../../../components/BaseDatePicker";
import { mapActions, mapState } from "vuex";

export default {
    components: {
        BaseNotify,
        BaseTextMoney,
        BaseDatePicker
    },
    props: {
        value: {
            type: Boolean,
            required: true
        },
        recordId: {
            type: Number,
            required: false,
            default: null
        }
    },
    data() {
        return {
            loading: false,
            error_message: null,
            show_notify: false,
            notify_type: null,
            notify_message: "",
            macro_category: [],
            credit_cards: [],
            confirm_delete: false,
            record: {
                date: (new Date(Date.now() - (new Date()).getTimezoneOffset() * 60000)).toISOString().substr(0, 10),
                price: null,
                category: null,
                credit_card: null,
                description: null,
                long_description: null,
                is_a_parcel: null,
                parcel: null,
                parcel_number: null,
                number_of_parcels: null
            }
        };
    },
    computed: {
        ...mapState("credit_card", {
            list_credit_cards: state => state.credit_cards
        }),
        ...mapState("settings", {
            list_macro_category: state => state.macro_category
        })
    },
    watch: {
        value(new_value) {
            if (new_value) {
                this.read();
            }
        }
    },
    mounted() {
        if (!this.list_macro_category || this.list_macro_category.length === 0) {
            this.getListMacroCategory();
        } else {
            this.makeListMacroCategory();
        }

        if (!this.list_credit_cards || this.list_credit_cards.length === 0) {
            this.getListCreditCards();
        } else {
            this.makeListCreditCard();
        }
    },
    methods: {
        ...mapActions("settings", ["ActionDoListMacroCategory"]),
        ...mapActions("credit_card", ["ActionDoListCreditCards"]),
        ...mapActions("credit_card_record", ["ActionDoReadCreditCardRecord", "ActionDoDeleteCreditCardRecord"]),
        close() {
            this.confirm_delete = false;
            this.cleanForm();
            this.$emit("close");
            this.$emit("input", false);
        },
        cleanForm() {
            this.date = (new Date(Date.now() - (new Date()).getTimezoneOffset() * 60000)).toISOString().substr(0, 10);
            this.price = null;
            this.category = null;
            this.credit_card = null;
            this.description = null;
            this.long_description = null;
            this.is_a_parcel = false;
            this.number_of_parcels = null;

            this.$refs.formDel.resetValidation();
        },
        async read() {
            this.loading = true;

            if (this.recordId) {
                await this.ActionDoReadCreditCardRecord(this.recordId).then(response => {
                    this.record = {
                        date: (new Date(response.date)).toISOString().substr(0, 10),
                        price: response.value,
                        category: response.category_id,
                        credit_card: response.credit_card_id,
                        description: response.description,
                        long_description: response.long_description,
                        is_a_parcel: response.is_a_parcel,
                        parcel: response.parcel_number + " de " + response.number_of_parcels,
                        parcel_number: response.parcel_number,
                        number_of_parcels: response.number_of_parcels
                    };

                    this.loading = false;
                });
            } else {
                this.close();
            }
        },
        confirmDelete() {
            if (this.record.is_a_parcel) {
                this.confirm_delete = true;
            } else {
                this.deleteRecord();
            }
        },
        deleteRecord() {
            this.loading = true;

            if (this.$refs.formDel.validate()) {
                try {
                    this.ActionDoDeleteCreditCardRecord(this.recordId).then(response => {
                        this.error_mesasge = null;

                        if (response.credit_card_record) {
                            this.show_notify = true;
                            this.notify_type = "success";
                            this.notify_message = "Registro excluido com sucesso!";
                            this.loading = false;
                            this.close();
                        } else {
                            this.show_notify = true;
                            this.notify_type = "error";
                            this.notify_message = "Ocorreu um erro!";
                            this.loading = false;
                            this.error_mesasge = response.error;
                        }
                    });
                } catch (error) {
                    this.show_notify = true;
                    this.notify_type = "error";
                    this.notify_message = "Ocorreu um erro!";
                    this.loading = false;
                    this.error_mesasge = error.response.data.error;
                }
            } else {
                this.loading = false;
            }
        },
        async getListMacroCategory() {
            await this.ActionDoListMacroCategory().then(() => {
                this.makeListMacroCategory();
            });
        },
        makeListMacroCategory() {
            for (let macro of this.list_macro_category) {
                this.macro_category.push({
                    text: macro.name,
                    value: macro.id + "macro",
                    header: macro.name
                });

                for (let category of macro.categories) {
                    this.macro_category.push({
                        text: category.name,
                        value: category.id,
                        divider: false
                    });
                }
            }
        },
        async getListCreditCards() {
            await this.ActionDoListCreditCards().then(() => {
                this.makeListCreditCard();
            });
        },
        makeListCreditCard() {
            for (let credit_card of this.list_credit_cards) {
                this.credit_cards.push({
                    text: credit_card.name,
                    value: credit_card.id
                });
            }
        }
    }
};
</script>
