<template>
    <v-dialog v-model="value" max-width="500px" persistent>
        <v-card>
            <v-card-title>
                <span class="text-h5">
                    Editar lançamento futuro
                    <v-icon right>
                        mdi-pencil
                    </v-icon>
                </span>
            </v-card-title>
            <v-card-text>
                <v-container>
                    <v-alert v-if="error_message" type="error">
                        {{ error_message }}
                    </v-alert>
                    <v-form ref="formEdit">
                        <v-row>
                            <v-col cols="6">
                                <v-switch
                                    v-model="expense"
                                    inset
                                    color="error"
                                    label="Despesa"
                                ></v-switch>
                            </v-col>
                            <v-col cols="6">
                                <v-switch
                                    v-model="income"
                                    inset
                                    color="success"
                                    label="Receita"
                                ></v-switch>
                            </v-col>
                            <v-col cols="6">
                                <base-date-picker
                                    v-model="future_release.date"
                                    label="Data do registro"
                                    :rules="date_rules"
                                    :min="min_date"
                                ></base-date-picker>
                            </v-col>
                            <v-col cols="6">
                                <base-text-money
                                    v-model="future_release.price"
                                    label="Valor"
                                    :rules="price_rules"
                                ></base-text-money>
                            </v-col>
                            <v-col cols="6">
                                <v-autocomplete
                                    v-model="future_release.category"
                                    label="Categoria"
                                    :rules="category_rules"
                                    :items="macro_category"
                                ></v-autocomplete>
                            </v-col>
                            <v-col cols="6">
                                <v-select
                                    v-model="future_release.account"
                                    label="Conta / Voucher"
                                    :items="accounts"
                                ></v-select>
                            </v-col>
                            <v-col cols="12">
                                <v-text-field
                                    v-model="future_release.description"
                                    label="Título"
                                    :rules="description_rules"
                                ></v-text-field>
                            </v-col>
                            <v-col cols="12">
                                <v-textarea
                                    v-model="future_release.long_description"
                                    label="Descrição"
                                />
                            </v-col>
                            <v-col cols="12">
                                <v-switch
                                    v-model="future_release.recurrent"
                                    inset
                                    color="primary"
                                    label="Lançamento recorrente"
                                ></v-switch>
                            </v-col>
                        </v-row>
                    </v-form>
                </v-container>
            </v-card-text>
            <v-card-actions>
                <v-spacer></v-spacer>
                <v-btn
                    color="primary"
                    text
                    :disabled="loading"
                    @click="close"
                >
                    Cancelar
                </v-btn>
                <v-btn
                    color="success"
                    text
                    :disabled="loading"
                    @click="update"
                >
                    Salvar
                </v-btn>
            </v-card-actions>
            <v-progress-linear
                v-if="loading"
                indeterminate
                height="6"
            ></v-progress-linear>
        </v-card>
        <base-notify
            v-model="show_notify"
            :type="notify_type"
            :message="notify_message"
        >
        </base-notify>
    </v-dialog>
</template>

<script>
import BaseNotify from "../../../components/BaseNotify.vue";
import BaseTextMoney from "../../../components/BaseTextMoney";
import BaseDatePicker from "../../../components/BaseDatePicker";
import { mapActions, mapState } from "vuex";

export default {
    components: {
        BaseNotify,
        BaseTextMoney,
        BaseDatePicker
    },
    props: {
        value: {
            type: Boolean,
            required: true
        },
        futureReleaseId: {
            type: Number,
            required: false,
            default: null
        }
    },
    data() {
        return {
            min_date: new Date(new Date().setDate(new Date().getDate() + 1)).toISOString().substr(0, 10),
            future_release: {
                date: new Date(new Date().setDate(new Date().getDate() + 1)).toISOString().substr(0, 10),
                price: null,
                category: null,
                account: null,
                description: null,
                long_description: null,
                recurrent: false,
            },
            date_rules: [
                v => !!v || "Informe uma data",
            ],
            price_rules: [
                v => !!v || "Informe um valor",
            ],
            category_rules: [
                v => !!v || "Selecione uma categoria",
            ],
            account_rules: [
                v => !!v || "Selecione uma conta",
            ],
            description_rules: [
                v => !!v || "Informe uma descrição"
            ],
            income: false,
            expense: true,
            loading: false,
            error_message: null,
            show_notify: false,
            notify_type: null,
            notify_message: "",
            macro_category: [],
            accounts: []
        };
    },
    computed: {
        ...mapState("account", {
            list_accounts: state => state.accounts
        }),
        ...mapState("settings", {
            list_macro_category: state => state.macro_category
        })
    },
    watch: {
        income(new_value) {
            this.expense = !new_value;
        },
        expense(new_value) {
            this.income = !new_value;
        },
        value(new_value) {
            if (new_value) {
                this.read();
            }
        }
    },
    mounted() {
        if (!this.list_macro_category || this.list_macro_category.length === 0) {
            this.getListMacroCategory();
        } else {
            this.makeListMacroCategory();
        }

        if (!this.list_accounts || this.list_accounts.length === 0) {
            this.getListAccount();
        } else {
            this.makeListAccounts();
        }
    },
    methods: {
        ...mapActions("settings", ["ActionDoListMacroCategory"]),
        ...mapActions("account", ["ActionDoListAccounts"]),
        ...mapActions("future_release", ["ActionDoEditFutureRelease", "ActionDoReadFutureRelease"]),
        close() {
            this.cleanForm();
            this.$emit("close");
            this.$emit("input", false);
        },
        cleanForm() {
            this.future_release = {
                date: new Date(new Date().setDate(new Date().getDate() + 1)).toISOString().substr(0, 10),
                price: null,
                category: null,
                account: null,
                description: null,
                long_description: null,
                recurrent: false,
            };

            this.income = false;

            this.$refs.formEdit.resetValidation();
        },
        async read() {
            await this.ActionDoReadFutureRelease(this.futureReleaseId).then(response => {
                this.future_release = {
                    date: (new Date(response.date)).toISOString().substr(0, 10),
                    price: response.value,
                    category: response.category_id,
                    account: response.account_id,
                    description: response.description,
                    long_description: response.long_description,
                    recurrent: response.recurrent
                };

                this.income = response.type === "I";
            });
        },
        update() {
            this.loading = true;

            if (this.$refs.formEdit.validate()) {
                try {
                    this.ActionDoEditFutureRelease({
                        id: this.futureReleaseId,
                        date: this.future_release.date,
                        value: this.future_release.price,
                        category_id: this.future_release.category,
                        account_id: this.future_release.account,
                        description: this.future_release.description,
                        long_description: this.future_release.long_description,
                        type: this.income ? "I" : "E",
                        recurrent: this.future_release.recurrent
                    }).then(response => {
                        this.error_mesasge = null;

                        if (response.future_release) {
                            this.show_notify = true;
                            this.notify_type = "success";
                            this.notify_message = "Registro salvo com sucesso!";
                            this.loading = false;
                            this.close();
                        } else {
                            this.show_notify = true;
                            this.notify_type = "error";
                            this.notify_message = "Ocorreu um erro!";
                            this.loading = false;
                            this.error_mesasge = response.error;
                        }
                    });
                } catch (error) {
                    this.show_notify = true;
                    this.notify_type = "error";
                    this.notify_message = "Ocorreu um erro!";
                    this.loading = false;
                    this.error_mesasge = error.response.data.error;
                }
            } else {
                this.loading = false;
            }
        },
        async getListMacroCategory() {
            await this.ActionDoListMacroCategory().then(() => {
                this.makeListMacroCategory();
            });
        },
        makeListMacroCategory() {
            for (let macro of this.list_macro_category) {
                this.macro_category.push({
                    text: macro.name,
                    value: macro.id + "macro",
                    header: macro.name
                });

                for (let category of macro.categories) {
                    this.macro_category.push({
                        text: category.name,
                        value: category.id,
                        divider: false
                    });
                }
            }
        },
        async getListAccount() {
            await this.ActionDoListAccounts().then(() => {
                this.makeListAccounts();
            });
        },
        makeListAccounts() {
            let bank = [];
            let voucher = [];

            for (let account of this.list_accounts) {
                if (account.account_type === "B") {
                    bank.push({
                        text: account.name,
                        value: account.id,
                        divider: false
                    });
                } else {
                    voucher.push({
                        text: account.name,
                        value: account.id,
                        divider: false
                    });
                }
            }

            this.accounts.push({
                text: "Conta bancária",
                value: "B",
                header: "Conta bancária"
            });

            for (let bank_account of bank) {
                this.accounts.push(bank_account);
            }

            this.accounts.push({
                text: "Voucher",
                value: "V",
                header: "Voucher"
            });

            for (let voucher_account of voucher) {
                this.accounts.push(voucher_account);
            }
        }
    }
};
</script>
