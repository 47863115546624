import services from "../../../http";
import * as types from "./mutations-types";

const baseUrl = "credit-card";

export const ActionDoListCreditCards = ({ commit }) => {
    return services.api.request({
        url: baseUrl,
        method: "get"
    }).then(response_data => {
        let response = response_data.data;

        commit(types.SET_LIST_CREDIT_CARD, response);

        return response;
    });
};

export const ActionDoCreateCreditCard = (context, payload) => {
    return services.api.request({
        url: baseUrl,
        method: "post",
        data: payload
    }).then(response_data => {
        let response = response_data.data;

        return response;
    });
};

export const ActionDoListWithTotalInvoice = ({ commit }, payload) => {
    return services.api.request({
        url: baseUrl + "/total-invoice",
        method: "post",
        data: payload
    }).then(response_data => {
        let response = response_data.data;

        commit(types.SET_LIST_CREDIT_CARD_TOTAL_INVOICE, response);

        return response;
    });
};

export const ActionDoReadCreditCard = (context, id_credit_card) => {
    return services.api.request({
        url: baseUrl + "/" + id_credit_card,
        method: "get"
    }).then(response_data => {
        let response = response_data.data;

        return response;
    });
};

export const ActionDoEditCreditCard = (context, payload) => {
    return services.api.request({
        url: baseUrl + "/" + payload.id,
        method: "put",
        data: payload
    }).then(response_data => {
        let response = response_data.data;

        return response;
    });
};

export const ActionDoDeleteCreditCard = (context, id_credit_card) => {
    return services.api.request({
        url: baseUrl + "/" + id_credit_card,
        method: "delete"
    }).then(response_data => {
        let response = response_data.data;

        return response;
    });
};