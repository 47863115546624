<template>
    <v-dialog v-model="value" persistent>
        <v-card>
            <v-card-title>
                <span class="text-h5">
                    Gastos no cartão <b>{{ credit_card.name }}</b>
                    <v-icon right>
                        mdi-credit-card-outline
                    </v-icon>
                </span>
            </v-card-title>
            <v-card-text>
                <v-container>
                    <v-data-table
                        :headers="headers"
                        :items="credit_card_records"
                        :items-per-page="10"
                        :loading="loading"
                        :sort-by="['date']"
                        :sort-desc="[true]"
                        :mobile-breakpoint="0"
                        item-key="id"
                        show-expand
                        single-expand
                        show-select
                    >
                        <template v-slot:[`item.category`]="{ item }">
                            {{ item.macro_category }} - {{ item.category }}
                        </template>
                        <template v-slot:[`item.parcel`]="{ item }">
                            <v-menu offset-y>
                                <template v-slot:activator="{ on, attrs }">
                                    <span
                                        v-if="item.parcel_number"
                                        v-bind="attrs"
                                        v-on="on"
                                    >
                                        {{ item.parcel_number }}/{{ item.number_of_parcels }}
                                    </span>
                                </template>
                                <v-card>
                                    <v-row>
                                        <v-col>
                                            <v-list>
                                                <v-list-item
                                                    v-for="(parcel, index) in item.remaining_parcels"
                                                    :key="index"
                                                >
                                                    <v-list-item-title>Parcela {{ parcel.parcel_number }} em {{ parcel.month }}/{{ parcel.year }} valor {{ parcel.value | toCurrency }}</v-list-item-title>
                                                </v-list-item>
                                            </v-list>
                                        </v-col>
                                    </v-row>
                                    <v-row justify="center">
                                        <v-col cols="auto">
                                            Total restante <b>{{ sumColumnArrayOfObjects(item.remaining_parcels, "value") | toCurrency }}</b>
                                        </v-col>
                                    </v-row>
                                </v-card>
                            </v-menu>
                        </template>
                        <template v-slot:[`item.value`]="{ item }">
                            {{ item.value | toCurrency }}
                        </template>
                        <template v-slot:[`item.date`]="{ item }">
                            <span v-date-usa-br="item.date"></span>
                        </template>
                        <template v-slot:[`item.actions`]="{ item }">
                            <v-row v-if="item.id > 0" class="flex-nowrap">
                                <v-col cols="auto">
                                    <v-btn icon color="primary" @click="editCreditCardRecord(item.id)">
                                        <v-icon>mdi-note-edit</v-icon>
                                    </v-btn>
                                </v-col>
                                <v-col cols="auto">
                                    <v-btn icon color="error" @click="deleteCreditCardRecord(item.id)">
                                        <v-icon>mdi-note-remove</v-icon>
                                    </v-btn>
                                </v-col>
                            </v-row>
                            <v-row v-else>
                                <v-col cols="auto">
                                    <v-btn icon color="primary" @click="editCreditCardRecurrentRecord(item.id_recurrent)">
                                        <v-icon>mdi-repeat</v-icon>
                                    </v-btn>
                                </v-col>
                                <v-col cols="auto">
                                    <v-btn icon color="error" @click="deleteCreditCardRecurrentRecord(item.id_recurrent)">
                                        <v-icon>mdi-repeat-off</v-icon>
                                    </v-btn>
                                </v-col>
                            </v-row>
                        </template>
                        <template v-slot:expanded-item="options">
                            <td :colspan="options.headers.length" class="pa-5">
                                <span class="text-pre" v-html="options.item.long_description" />
                            </td>
                        </template>
                    </v-data-table>
                </v-container>
            </v-card-text>
            <v-card-actions>
                <v-spacer></v-spacer>
                <v-btn
                    color="primary"
                    text
                    :disabled="loading"
                    @click="close"
                >
                    Fechar
                </v-btn>
            </v-card-actions>
        </v-card>
        <dialog-edit-record v-model="dialog_edit" :record-id="credit_card_record_selected" @close="readRecords"></dialog-edit-record>
        <dialog-delete-record v-model="dialog_delete" :record-id="credit_card_record_selected" @close="readRecords"></dialog-delete-record>
        <dialog-edit-recurrent-record v-model="dialog_recurrent_edit" :recurrent-record-id="credit_card_recurrent_record_selected" @close="readRecords"></dialog-edit-recurrent-record>
        <dialog-delete-recurrent-record v-model="dialog_recurrent_delete" :recurrent-record-id="credit_card_recurrent_record_selected" @close="readRecords"></dialog-delete-recurrent-record>
    </v-dialog>
</template>

<script>
import Vue from "vue";

import DialogEditRecord from "./DialogEditRecord";
import DialogDeleteRecord from "./DialogDeleteRecord";
import DialogEditRecurrentRecord from "../../recurrent-record/components/DialogEditRecurrentRecord";
import DialogDeleteRecurrentRecord from "../../recurrent-record/components/DialogDeleteRecurrentRecord";

import { mapActions, mapState } from "vuex";

export default {
    components: {
        DialogEditRecord,
        DialogDeleteRecord,
        DialogEditRecurrentRecord,
        DialogDeleteRecurrentRecord
    },
    props: {
        value: {
            type: Boolean,
            required: true
        },
        creditCardId: {
            type: Number,
            required: false,
            default: null
        }
    },
    data() {
        return {
            loading: false,
            credit_card: {
                name: null,
                limit: null,
                due_date: null,
                closing_date: null
            },
            credit_card_records: [],
            headers: [
                { text: "Categoria", value: "category", class: "text-no-wrap", cellClass: "text-no-wrap" },
                { text: "Título", value: "description" },
                { text: "Parcela", value: "parcel", class: "text-no-wrap", cellClass: "text-no-wrap" },
                { text: "Valor", value: "value", class: "text-no-wrap", cellClass: "text-no-wrap" },
                { text: "Data", value: "date", class: "text-no-wrap", cellClass: "text-no-wrap" },
                { text: "Ações", value: "actions", sortable: false },
                { text: "", value: "data-table-expand" }
            ],
            dialog_edit: false,
            dialog_delete: false,
            dialog_recurrent_edit: false,
            dialog_recurrent_delete: false,
            credit_card_record_selected: null,
            credit_card_recurrent_record_selected: null
        };
    },
    computed: {
        ...mapState("utils", {
            month: state => state.month,
            year: state => state.year
        })
    },
    watch: {
        value(new_value) {
            if (new_value) {
                this.read();
            }
        }
    },
    methods: {
        ...mapActions("credit_card", ["ActionDoReadCreditCard"]),
        ...mapActions("credit_card_record", ["ActionDoReadCreditCardRecords"]),
        close() {
            this.credit_card_records = [];
            this.$emit("close");
            this.$emit("input", false);
        },
        async read() {
            this.loading = true;

            if (this.creditCardId) {
                await this.ActionDoReadCreditCard(this.creditCardId).then(response => {
                    this.credit_card = response;
                    this.readRecords();
                });
            } else {
                this.close();
            }
        },
        async readRecords() {
            if (this.creditCardId) {
                await this.ActionDoReadCreditCardRecords({
                    credit_card_id: this.creditCardId,
                    month: this.month,
                    year: this.year
                }).then(response => {
                    this.credit_card_records = response;
                    this.loading = false;
                });
            } else {
                this.close();
            }
        },
        editCreditCardRecord(id) {
            this.credit_card_record_selected = id;
            this.dialog_edit = true;
        },
        deleteCreditCardRecord(id) {
            this.credit_card_record_selected = id;
            this.dialog_delete = true;
        },
        editCreditCardRecurrentRecord(id) {
            this.credit_card_recurrent_record_selected = id;
            this.dialog_recurrent_edit = true;
        },
        deleteCreditCardRecurrentRecord(id) {
            this.credit_card_recurrent_record_selected = id;
            this.dialog_recurrent_delete = true;
        },
        sumColumnArrayOfObjects(items, value) {
            if (items !== undefined) {
                return Vue.sumColumnArrayOfObjects(items, value);
            } else {
                return "";
            }
        }
    }
};
</script>
