import services from "../../../http";

const baseUrl = "recurrent-record";

export const ActionDoCreateRecurrentRecord = (context, payload) => {
    return services.api.request({
        url: baseUrl,
        method: "post",
        data: payload
    }).then(response_data => {
        let response = response_data.data;

        return response;
    });
};

export const ActionDoReadRecurrentRecords = () => {
    return services.api.request({
        url: baseUrl,
        method: "get"
    }).then(response_data => {
        let response = response_data.data;

        return response;
    });
};

export const ActionDoEditRecurrentRecord = (context, payload) => {
    return services.api.request({
        url: baseUrl + "/" + payload.id,
        method: "put",
        data: payload
    }).then(response_data => {
        let response = response_data.data;

        return response;
    });
};

export const ActionDoReadRecurrentRecord = (context, recurrent_record_id) => {
    return services.api.request({
        url: baseUrl + "/" + recurrent_record_id,
        method: "get"
    }).then(response_data => {
        let response = response_data.data;

        return response;
    });
};

export const ActionDoDeleteRecurrentRecord = (context, recurrent_record_id) => {
    return services.api.request({
        url: baseUrl + "/" + recurrent_record_id,
        method: "delete"
    }).then(response_data => {
        let response = response_data.data;

        return response;
    });
};