<template>
    <v-container>
        <v-row>
            <v-col>
                <v-card>
                    <v-card-title>
                        <v-row justify="space-between">
                            <v-col cols="auto">
                                Categorias
                            </v-col>
                            <v-col cols="auto">
                                <v-btn color="primary" @click="dialog_add = true">
                                    <v-icon left>
                                        mdi-plus-box
                                    </v-icon>
                                    Nova categoria
                                </v-btn>
                            </v-col>
                        </v-row>
                    </v-card-title>
                    <v-card-text>
                        <v-row>
                            <v-col>
                                <v-data-table
                                    :headers="headers.category"
                                    :items="macro_category"
                                    :items-per-page="10"
                                    :loading="loading"
                                    :single-expand="true"
                                    show-expand
                                    :mobile-breakpoint="0"
                                >
                                    <template v-slot:[`item.sub`]="{ item }">
                                        {{ item.categories.length }}
                                    </template>
                                    <template v-slot:[`item.actions`]="{ item }">
                                        <v-row class="flex-nowrap">
                                            <v-col cols="auto">
                                                <v-btn icon color="success" @click="addCategory(item.id)">
                                                    <v-icon>mdi-plus-circle-multiple</v-icon>
                                                </v-btn>
                                            </v-col>
                                            <v-col cols="auto">
                                                <v-btn icon color="primary" @click="editMacroCategory(item.id)">
                                                    <v-icon>mdi-square-edit-outline</v-icon>
                                                </v-btn>
                                            </v-col>
                                            <v-col cols="auto">
                                                <v-btn icon color="error" @click="deleteMacroCategory(item.id)">
                                                    <v-icon>mdi-close-box</v-icon>
                                                </v-btn>
                                            </v-col>
                                        </v-row>
                                    </template>
                                    <template v-slot:expanded-item="{ item }">
                                        <td :colspan="4">
                                            <v-list color="transparent">
                                                <v-list-item-group>
                                                    <v-list-item
                                                        v-for="category in item.categories"
                                                        :key="category.id"
                                                    >
                                                        <v-list-item-content>
                                                            <v-list-item-title v-text="category.name"></v-list-item-title>
                                                        </v-list-item-content>
                                                        <v-list-item-action>
                                                            <v-row>
                                                                <v-col cols="auto">
                                                                    <v-btn icon color="primary" @click="editCategory(category.id)">
                                                                        <v-icon>mdi-square-edit-outline</v-icon>
                                                                    </v-btn>
                                                                </v-col>
                                                                <v-col cols="auto">
                                                                    <v-btn icon color="error" @click="deleteCategory(category.id)">
                                                                        <v-icon>mdi-close-box</v-icon>
                                                                    </v-btn>
                                                                </v-col>
                                                            </v-row>
                                                        </v-list-item-action>
                                                    </v-list-item>
                                                </v-list-item-group>
                                            </v-list>
                                        </td>
                                    </template>
                                </v-data-table>
                            </v-col>
                        </v-row>
                        <dialog-add v-model="dialog_add" @close="getListMacroCategory"></dialog-add>
                        <dialog-edit v-model="dialog_edit" :macro-category-id="macro_category_selected" @close="getListMacroCategory"></dialog-edit>
                        <dialog-delete v-model="dialog_remove" :macro-category-id="macro_category_selected" @close="getListMacroCategory"></dialog-delete>
                        <dialog-add-category v-model="dialog_add_category" :macro-category-id="macro_category_selected" @close="getListMacroCategory"></dialog-add-category>
                        <dialog-edit-category v-model="dialog_edit_category" :category-id="category_selected" @close="getListMacroCategory"></dialog-edit-category>
                        <dialog-delete-category v-model="dialog_remove_category" :category-id="category_selected" @close="getListMacroCategory"></dialog-delete-category>
                    </v-card-text>
                </v-card>
            </v-col>
        </v-row>
    </v-container>
</template>

<script>
import DialogAdd from "./components/macro_category/DialogAdd";
import DialogEdit from "./components/macro_category/DialogEdit";
import DialogDelete from "./components/macro_category/DialogDelete";
import DialogAddCategory from "./components/category/DialogAddCategory";
import DialogEditCategory from "./components/category/DialogEditCategory";
import DialogDeleteCategory from "./components/category/DialogDeleteCategory";

import { mapActions, mapState } from "vuex";

export default {
    components: {
        DialogAdd,
        DialogEdit,
        DialogDelete,
        DialogAddCategory,
        DialogEditCategory,
        DialogDeleteCategory
    },
    data() {
        return {
            headers: {
                category: [
                    { text: "Nome", value: "name", class: "text-no-wrap", cellClass: "text-no-wrap" },
                    { text: "Sub Categorias", value: "sub", class: "text-no-wrap", cellClass: "text-no-wrap" },
                    { text: "Ações", value: "actions", sortable: false }
                ]
            },
            loading: false,
            dialog_add: false,
            dialog_edit: false,
            dialog_remove: false,
            macro_category_selected: null,
            dialog_add_category: false,
            dialog_edit_category: false,
            dialog_remove_category: false,
            category_selected: null
        };
    },
    computed: {
        ...mapState("utils", {
            month: state => state.month,
            year: state => state.year
        }),
        ...mapState("settings", {
            macro_category: state => state.macro_category
        })
    },
    watch: {
        month() {
            this.getListMacroCategory();
        },
        year() {
            this.getListMacroCategory();
        }
    },
    mounted() {
        if (!this.list_macro_category || this.list_macro_category.length === 0) {
            this.getListMacroCategory();
        }
    },
    methods: {
        ...mapActions("settings", ["ActionDoListMacroCategory"]),
        async getListMacroCategory() {
            this.loading = true;
            await this.ActionDoListMacroCategory().then(() => {
                this.loading = false;
            });
        },
        editMacroCategory(id) {
            this.macro_category_selected = id;
            this.dialog_edit = true;
        },
        deleteMacroCategory(id) {
            this.macro_category_selected = id;
            this.dialog_remove = true;
        },
        addCategory(macro_category_id) {
            this.macro_category_selected = macro_category_id;
            this.dialog_add_category = true;
        },
        editCategory(id) {
            this.category_selected = id;
            this.dialog_edit_category = true;
        },
        deleteCategory(id) {
            this.category_selected = id;
            this.dialog_remove_category = true;
        }
    }
};
</script>