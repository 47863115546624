<template>
    <v-dialog v-model="value" max-width="500px" persistent>
        <v-card>
            <v-card-title>
                <span class="text-h5">
                    Pagar fatura do cartão <b>{{ invoice.credit_card.name }}</b>
                </span>
            </v-card-title>
            <v-card-text>
                <v-container>
                    <v-form ref="formPayInvoice">
                        <v-row>
                            <v-col cols="6">
                                <base-text-money
                                    v-model="invoice.credit_card_records_sum_value"
                                    label="Valor total da fatura"
                                    disabled
                                ></base-text-money>
                            </v-col>
                            <v-col cols="6">
                                <base-text-money
                                    v-model="invoice.credit_card_records_sum_value"
                                    label="Valor a pagar"
                                ></base-text-money>
                            </v-col>
                            <v-col cols="6">
                                <v-select
                                    v-model="account"
                                    label="Debitar da conta"
                                    :rules="account_rules"
                                    :items="accounts"
                                ></v-select>
                            </v-col>
                            <v-col cols="6">
                                <base-date-picker
                                    v-model="date"
                                    label="Data do pagamento"
                                    :rules="date_rules"
                                ></base-date-picker>
                            </v-col>
                        </v-row>
                    </v-form>
                </v-container>
            </v-card-text>
            <v-card-actions>
                <v-spacer></v-spacer>
                <v-btn
                    color="primary"
                    text
                    :disabled="loading"
                    @click="close"
                >
                    Cancelar
                </v-btn>
                <v-btn
                    color="success"
                    text
                    :disabled="loading"
                    @click="pay"
                >
                    Pagar
                </v-btn>
            </v-card-actions>
            <v-progress-linear
                v-if="loading"
                indeterminate
                height="6"
            ></v-progress-linear>
        </v-card>
    </v-dialog>
</template>

<script>
import BaseTextMoney from "../../../components/BaseTextMoney";
import BaseDatePicker from "../../../components/BaseDatePicker";

import { mapActions, mapState } from "vuex";

export default {
    components: {
        BaseTextMoney,
        BaseDatePicker
    },
    props: {
        value: {
            type: Boolean,
            required: true
        },
        invoiceId: {
            type: Number,
            required: false,
            default: null
        }
    },
    data() {
        return {
            invoice: {
                credit_card_records_sum_value: 0,
                credit_card: {
                    name: null
                }
            },
            value_pay: 0,
            account: null,
            date: (new Date(Date.now() - (new Date()).getTimezoneOffset() * 60000)).toISOString().substr(0, 10),
            date_rules: [
                v => !!v || "Informe uma data",
            ],
            account_rules: [
                v => !!v || "Selecione uma conta",
            ],
            accounts: [],
            loading: false
        };
    },
    computed: {
        ...mapState("account", {
            list_accounts: state => state.accounts
        }),
    },
    watch: {
        value(new_val) {
            if (new_val) {
                this.read();
            }
        }
    },
    mounted() {
        if (!this.list_accounts || this.list_accounts.length === 0) {
            this.getListAccount();
        } else {
            this.makeListAccounts();
        }
    },
    methods: {
        ...mapActions("invoice", ["ActionDoReadInvoice", "ActionDoPayInvoice"]),
        ...mapActions("account", ["ActionDoListAccounts"]),
        close() {
            this.cleanForm();
            this.$emit("close");
            this.$emit("input", false);
        },
        cleanForm() {
            this.invoice = {
                credit_card_records_sum_value: 0,
                credit_card: {
                    name: null
                }
            };
            this.value_pay = 0;
            this.account = null;
            this.date = (new Date(Date.now() - (new Date()).getTimezoneOffset() * 60000)).toISOString().substr(0, 10);

            this.$refs.formPayInvoice.resetValidation();
        },
        async read() {
            await this.ActionDoReadInvoice(this.invoiceId).then(response => {
                this.invoice = response;
                this.value_pay = response.credit_card_records_sum_value;
            });
        },
        async getListAccount() {
            await this.ActionDoListAccounts().then(() => {
                this.makeListAccounts();
            });
        },
        makeListAccounts() {
            let bank = [];

            for (let account of this.list_accounts) {
                if (account.account_type === "B") {
                    bank.push({
                        text: account.name,
                        value: account.id,
                        divider: false
                    });
                }
            }

            this.accounts.push({
                text: "Conta bancária",
                value: "B",
                header: "Conta bancária"
            });

            for (let bank_account of bank) {
                this.accounts.push(bank_account);
            }
        },
        pay() {
            this.loading = true;

            if (this.$refs.formPayInvoice.validate()) {
                try {
                    this.ActionDoPayInvoice({
                        id: this.invoice.id,
                        value: parseInt(this.value_pay),
                        account_id: this.account,
                        date: this.date,
                    }).then(response => {

                        if (response.invoice) {
                            this.loading = false;
                            this.close();
                        } else {
                            this.loading = false;
                        }
                    });
                } catch (error) {
                    this.loading = false;
                }
            } else {
                this.loading = false;
            }
        }
    }
};
</script>