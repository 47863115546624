<template>
    <v-dialog v-model="value" max-width="500px" persistent>
        <v-card>
            <v-card-title>
                <span class="text-h5">
                    Deletar conta
                    <v-icon right>
                        mdi-bank-remove
                    </v-icon>
                </span>
            </v-card-title>
            <v-card-text>
                <v-container>
                    <v-alert v-if="error_message" type="error">
                        {{ error_message }}
                    </v-alert>
                    <v-form ref="formDelete">
                        <v-row>
                            <v-col cols="6">
                                <v-text-field
                                    v-model="account.name"
                                    label="Nome da conta"
                                    disabled
                                ></v-text-field>
                            </v-col>
                            <v-col cols="6">
                                <base-text-money
                                    v-model="account.balance"
                                    label="Saldo"
                                    disabled
                                ></base-text-money>
                            </v-col>
                            <v-col cols="12">
                                <v-select
                                    v-model="account.account_type"
                                    label="Tipo de conta"
                                    :items="list_account_type"
                                    disabled
                                ></v-select>
                            </v-col>
                            <v-col cols="12">
                                <v-switch
                                    v-model="account.include_summary"
                                    inset
                                    color="primary"
                                    label="Incluir na soma do resumo"
                                    disabled
                                ></v-switch>
                            </v-col>
                        </v-row>
                    </v-form>
                </v-container>
            </v-card-text>
            <v-card-actions>
                <v-spacer></v-spacer>
                <v-btn
                    color="primary"
                    text
                    :disabled="deleting"
                    @click="close"
                >
                    Cancelar
                </v-btn>
                <v-btn
                    color="error"
                    text
                    :disabled="deleting"
                    @click="remove"
                >
                    Deletar
                </v-btn>
            </v-card-actions>
            <v-progress-linear
                v-if="deleting"
                indeterminate
                height="6"
            ></v-progress-linear>
        </v-card>
        <base-notify
            v-model="show_notify"
            :type="notify_type"
            :message="notify_message"
        >
        </base-notify>
    </v-dialog>
</template>

<script>
import BaseNotify from "../../../components/BaseNotify";
import BaseTextMoney from "../../../components/BaseTextMoney";
import { mapActions } from "vuex";

export default {
    components: {
        BaseNotify,
        BaseTextMoney
    },
    props: {
        value: {
            type: Boolean,
            required: true
        },
        accountId: {
            type: Number,
            required: false,
            default: null
        }
    },
    data() {
        return {
            list_account_type: [
                { text: "Conta bancária", value: this.$accountBank },
                { text: "Voucher", value: this.$accountVoucher }
            ],
            loading: false,
            deleting: false,
            error_message: null,
            show_notify: false,
            notify_type: null,
            notify_message: "",
            account: {
                name: null,
                balance: null,
                account_type: null,
                include_summary: false
            }
        };
    },
    watch: {
        value(new_value) {
            if (new_value) {
                this.read();
            }
        }
    },
    methods: {
        ...mapActions("account", ["ActionDoReadAccount", "ActionDoDeleteAccount"]),
        close() {
            this.$refs.formDelete.reset();
            this.$emit("close");
            this.$emit("input", false);
        },
        async read() {
            this.loading = true;

            if (this.accountId) {
                await this.ActionDoReadAccount(this.accountId).then(response => {
                    this.account = response;
                    this.loading = false;
                });
            } else {
                this.close();
            }
        },
        remove() {
            this.deleting = true;

            if (this.$refs.formDelete.validate()) {

                try {
                    this.ActionDoDeleteAccount(this.account.id).then(response => {
                        this.error_mesasge = null;

                        if (response.account) {
                            this.show_notify = true;
                            this.notify_type = "success";
                            this.notify_message = "Conta deletada com sucesso!";
                            this.deleting = false;
                            this.$refs.formDelete.reset();
                            this.close();
                        } else {
                            this.show_notify = true;
                            this.notify_type = "error";
                            this.notify_message = "Ocorreu um erro!";
                            this.deleting = false;
                            this.error_mesasge = response.error;
                        }
                    });
                } catch (error) {
                    this.show_notify = true;
                    this.notify_type = "error";
                    this.notify_message = "Ocorreu um erro!";
                    this.deleting = false;
                    this.error_mesasge = error.response.data.error;
                }
            } else {
                this.deleting = false;
            }
        }
    }
};
</script>