<template>
    <v-menu
        v-model="menu"
        :close-on-content-click="false"
        transition="scale-transition"
        offset-y
        max-width="290px"
        min-width="auto"
        :disabled="disabled"
    >
        <template v-slot:activator="{ on, attrs }">
            <v-text-field
                v-model="computedDateFormatted"
                :label="label"
                prepend-icon="mdi-calendar"
                readonly
                :disabled="disabled"
                v-bind="attrs"
                v-on="on"
            ></v-text-field>
        </template>
        <v-date-picker
            v-model="date"
            no-title
            :disabled="disabled"
            :min="min"
            @input="menu = false"
        ></v-date-picker>
    </v-menu>
</template>

<script>
export default {
    props: {
        value: {
            type: String,
            required: false,
            default: (new Date(Date.now() - (new Date()).getTimezoneOffset() * 60000)).toISOString().substr(0, 10)
        },
        label: {
            type: String,
            required: true
        },
        rules: {
            type: Array,
            required: false,
            default() {
                return [];
            }
        },
        disabled: {
            type: Boolean,
            required: false,
            default: false
        },
        min: {
            type: String,
            required: false,
            default: null
        }
    },
    data() {
        return {
            menu: false,
            date: this.value
        };
    },
    computed: {
        computedDateFormatted() {
            return this.formatDate(this.date);
        }
    },
    watch: {
        value() {
            this.date = this.value;
        },
        date() {
            this.$emit("input", this.date);
        }
    },
    methods: {
        formatDate(date) {
            if (!date) {
                return null;
            }

            const [year, month, day] = date.split("-");
            return `${day}/${month}/${year}`;
        }
    }
};
</script>