import DashboardPage from "./DashboardPage";
import YearSummaryPage from "./YearSummaryPage";

export default [
    {
        path: "/dashboard",
        name: "dashboard",
        component: DashboardPage
    },
    {
        path: "/",
        name: "home",
        component: DashboardPage
    },
    {
        path: "/summary",
        name: "summary",
        component: YearSummaryPage
    }
];
