<template>
    <v-dialog v-model="value" max-width="500px" persistent>
        <v-card>
            <v-card-title>
                <span class="text-h5">
                    Editar categoria
                    <v-icon right>
                        mdi-pencil-circle-outline
                    </v-icon>
                </span>
            </v-card-title>
            <v-card-text>
                <v-container>
                    <v-alert v-if="error_message" type="error">
                        {{ error_message }}
                    </v-alert>
                    <v-form ref="formEdit">
                        <v-row>
                            <v-col cols="12">
                                <v-text-field
                                    ref="nameField"
                                    v-model="macro_category.name"
                                    label="Nome da categoria"
                                    :rules="name_rules"
                                ></v-text-field>
                            </v-col>
                        </v-row>
                    </v-form>
                </v-container>
            </v-card-text>
            <v-card-actions>
                <v-spacer></v-spacer>
                <v-btn
                    color="primary"
                    text
                    :disabled="saving"
                    @click="close"
                >
                    Cancelar
                </v-btn>
                <v-btn
                    color="success"
                    text
                    :disabled="saving"
                    @click="save"
                >
                    Salvar
                </v-btn>
            </v-card-actions>
            <v-progress-linear
                v-if="saving"
                indeterminate
                height="6"
            ></v-progress-linear>
        </v-card>
        <base-notify
            v-model="show_notify"
            :type="notify_type"
            :message="notify_message"
        >
        </base-notify>
    </v-dialog>
</template>

<script>
import BaseNotify from "../../../../components/BaseNotify";
import { mapActions } from "vuex";

export default {
    components: {
        BaseNotify
    },
    props: {
        value: {
            type: Boolean,
            required: true
        },
        macroCategoryId: {
            type: Number,
            required: false,
            default: null
        }
    },
    data() {
        return {
            name_rules: [
                v => !!v || "Digite um nome",
            ],
            loading: false,
            saving: false,
            error_message: null,
            show_notify: false,
            notify_type: null,
            notify_message: "",
            macro_category: {
                name: null
            }
        };
    },
    watch: {
        value(new_value) {
            if (new_value) {
                this.read();
            }
        }
    },
    methods: {
        ...mapActions("settings", ["ActionDoReadMacroCategory", "ActionDoEditMacroCategory"]),
        close() {
            this.$refs.formEdit.reset();
            this.$emit("close");
            this.$emit("input", false);
        },
        async read() {
            this.loading = true;

            if (this.macroCategoryId) {
                await this.ActionDoReadMacroCategory(this.macroCategoryId).then(response => {
                    this.macro_category = response;
                    this.loading = false;
                    this.$refs.nameField.focus();
                });
            } else {
                this.close();
            }
        },
        save() {
            this.saving = true;

            if (this.$refs.formEdit.validate()) {
                try {
                    this.ActionDoEditMacroCategory({
                        id: this.macro_category.id,
                        name: this.macro_category.name
                    }).then(response => {
                        this.error_mesasge = null;

                        if (response.macro_category) {
                            this.show_notify = true;
                            this.notify_type = "success";
                            this.notify_message = "Categoria salva com sucesso!";
                            this.saving = false;
                            this.$refs.formEdit.reset();
                            this.close();
                        } else {
                            this.show_notify = true;
                            this.notify_type = "error";
                            this.notify_message = "Ocorreu um erro!";
                            this.saving = false;
                            this.error_mesasge = response.error;
                        }
                    });
                } catch (error) {
                    this.show_notify = true;
                    this.notify_type = "error";
                    this.notify_message = "Ocorreu um erro!";
                    this.saving = false;
                    this.error_mesasge = error.response.data.error;
                }
            } else {
                this.saving = false;
            }
        }
    }
};
</script>