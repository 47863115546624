import services from "../../../http";
import * as types from "./mutations-types";

const baseUrlMacroCategory = "macro-category";
const baseUrlCategory = "category";

export const ActionDoListMacroCategory = ({ commit }) => {
    return services.api.request({
        url: baseUrlMacroCategory,
        method: "get"
    }).then(response_data => {
        let response = response_data.data;

        commit(types.SET_LIST_MACRO_CATEGORY, response);

        return response;
    });
};

export const ActionDoCreateMacroCategory = ({ dispatch }, payload) => {
    return services.api.request({
        url: baseUrlMacroCategory,
        method: "post",
        data: payload
    }).then(response_data => {
        let response = response_data.data;

        dispatch("ActionDoListMacroCategoryTotal");

        return response;
    });
};

export const ActionDoListNotInBudget = () => {
    return services.api.request({
        url: baseUrlMacroCategory + "/not-in-budget",
        method: "post"
    }).then(response_data => {
        let response = response_data.data;

        return response;
    });
};

export const ActionDoReadMacroCategory = (context, id_macro_category) => {
    return services.api.request({
        url: baseUrlMacroCategory + "/" + id_macro_category,
        method: "get"
    }).then(response_data => {
        let response = response_data.data;

        return response;
    });
};

export const ActionDoEditMacroCategory = ({ dispatch }, payload) => {
    return services.api.request({
        url: baseUrlMacroCategory + "/" + payload.id,
        method: "put",
        data: payload
    }).then(response_data => {
        let response = response_data.data;

        dispatch("ActionDoListMacroCategoryTotal");

        return response;
    });
};

export const ActionDoDeleteMacroCategory = ({ dispatch }, id_macro_category) => {
    return services.api.request({
        url: baseUrlMacroCategory + "/" + id_macro_category,
        method: "delete"
    }).then(response_data => {
        let response = response_data.data;

        dispatch("ActionDoListMacroCategoryTotal");

        return response;
    });
};

export const ActionDoListMacroCategoryTotal = ({ commit }, payload) => {
    return services.api.request({
        url: baseUrlMacroCategory + "/total-period",
        method: "post",
        data: payload
    }).then(response_data => {
        let response = response_data.data;

        commit(types.SET_LIST_MACRO_CATEGORY_TOTAL, response);

        return response;
    });
};

export const ActionDoMacroCategoryTotal = (context, payload) => {
    return services.api.request({
        url: baseUrlMacroCategory + "/one-total-period",
        method: "post",
        data: payload
    }).then(response_data => {
        let response = response_data.data;

        return response;
    });
};

export const ActionDoCreateCategory = ({ dispatch }, payload) => {
    return services.api.request({
        url: baseUrlCategory,
        method: "post",
        data: payload
    }).then(response_data => {
        let response = response_data.data;

        dispatch("ActionDoListMacroCategoryTotal");

        return response;
    });
};

export const ActionDoReadCategory = (context, id_category) => {
    return services.api.request({
        url: baseUrlCategory + "/" + id_category,
        method: "get"
    }).then(response_data => {
        let response = response_data.data;

        return response;
    });
};

export const ActionDoEditCategory = ({ dispatch }, payload) => {
    return services.api.request({
        url: baseUrlCategory + "/" + payload.id,
        method: "put",
        data: payload
    }).then(response_data => {
        let response = response_data.data;

        dispatch("ActionDoListMacroCategoryTotal");

        return response;
    });
};

export const ActionDoDeleteCategory = ({ dispatch }, id_category) => {
    return services.api.request({
        url: baseUrlCategory + "/" + id_category,
        method: "delete"
    }).then(response_data => {
        let response = response_data.data;

        dispatch("ActionDoListMacroCategoryTotal");

        return response;
    });
};

export const ActionDoReadRecordsByCategory = (context, payload) => {
    return services.api.request({
        url: baseUrlCategory + "/records-by-category",
        method: "post",
        data: payload
    }).then(response_data => {
        let response = response_data.data;

        return response;
    });
};

export const ActionDoGetSummaryYear = (context, payload) => {
    return services.api.request({
        url: baseUrlCategory + "/year-summary",
        method: "post",
        data: payload
    }).then(response_data => {
        let response = response_data.data;

        return response;
    });
};
