<template>
    <v-app>
        <v-app-bar
            v-if="is_logged"
            app
            color="cyan lighten-4"
            flat
            class="elevation-5"
        >
            <v-img src="./assets/logo-transparent.png" width="150"></v-img>
            <v-tabs
                v-model="tab_selected"
                centered
                class="ml-n9"
                color="grey darken-1"
            >
                <v-tab v-for="(link, n) in links" :key="n" :to="link.href">
                    <v-icon left :x-small="$vuetify.breakpoint.smAndDown">
                        {{ link.icon }}
                    </v-icon>
                    <span :class="$vuetify.breakpoint.smAndDown ? 'text-caption' : ''">{{ link.name }}</span>
                </v-tab>
            </v-tabs>
        </v-app-bar>

        <v-main class="blue lighten-5">
            <v-container :class="!is_logged ? 'fill-height' : ''">
                <v-row v-if="is_logged">
                    <v-col cols="12" md="2">
                        <v-sheet rounded="lg">
                            <v-list shaped class="d-none d-md-block">
                                <v-list-item-group v-model="month_selected" color="primary" :mandatory="$vuetify.breakpoint.mdAndUp">
                                    <v-list-item v-for="(month, n) in months" :key="n" :value="n">
                                        <v-list-item-content>
                                            <v-list-item-title>
                                                {{ month }}
                                            </v-list-item-title>
                                        </v-list-item-content>
                                    </v-list-item>
                                </v-list-item-group>
                            </v-list>

                            <v-divider class="my-2 d-none d-md-block"></v-divider>

                            <v-container>
                                <v-row justify="space-between">
                                    <v-col cols="auto" class="d-md-none">
                                        <v-select
                                            v-model="month_select"
                                            :items="months_select"
                                            label="Mês"
                                        ></v-select>
                                    </v-col>
                                    <v-col cols="auto">
                                        <v-select
                                            v-model="year_selected"
                                            :items="years"
                                            label="Ano"
                                        ></v-select>
                                    </v-col>
                                </v-row>
                            </v-container>
                        </v-sheet>
                    </v-col>

                    <v-col cols="12" md="10">
                        <v-sheet min-height="72vh" rounded="lg">
                            <router-view></router-view>
                        </v-sheet>
                    </v-col>
                </v-row>

                <router-view v-else></router-view>
            </v-container>
        </v-main>
    </v-app>
</template>

<script>
import { mapState } from "vuex";
import * as typesUtils from "./utils/store/mutations-types";

export default {
    data() {
        return {
            links: {
                dashboard: {
                    name: "Dashboard",
                    href: "/dashboard",
                    icon: "mdi-view-dashboard-outline"
                },
                credit_card: {
                    name: "Cartões",
                    href: "/credit-card",
                    icon: "mdi-credit-card-multiple-outline"
                },
                account: {
                    name: "Contas",
                    href: "/account",
                    icon: "mdi-bank"
                },
                budget: {
                    name: "Planejamento",
                    href: "/budget",
                    icon: "mdi-calendar-month-outline"
                },
                summary: {
                    name: "Resumo Anual",
                    href: "/summary",
                    icon: "mdi-chart-timeline-variant"
                },
                settings: {
                    name: "Configurações",
                    href: "/settings",
                    icon: "mdi-cog-outline"
                }
            },
            tab_selected: null,
            months: {
                1: "Janeiro",
                2: "Fevereiro",
                3: "Março",
                4: "Abril",
                5: "Maio",
                6: "Junho",
                7: "Julho",
                8: "Agosto",
                9: "Setembro",
                10: "Outubro",
                11: "Novembro",
                12: "Dezembro"
            },
            months_select: [
                {
                    text: "Janeiro",
                    value: 1
                },
                {
                    text: "Fevereiro",
                    value: 2
                },
                {
                    text: "Março",
                    value: 3
                },
                {
                    text: "Abril",
                    value: 4
                },
                {
                    text: "Maio",
                    value: 5
                },
                {
                    text: "Junho",
                    value: 6
                },
                {
                    text: "Julho",
                    value: 7
                },
                {
                    text: "Agosto",
                    value: 8
                },
                {
                    text: "Setembro",
                    value: 9
                },
                {
                    text: "Outubro",
                    value: 10
                },
                {
                    text: "Novembro",
                    value: 11
                },
                {
                    text: "Dezembro",
                    value: 12
                }
            ],
            month_select: parseInt((new Date().getMonth() + 1).toString())
        };
    },
    computed: {
        ...mapState({
            is_logged: state => state.auth.user && state.auth.token
        }),
        year_selected: {
            get () {
                return this.$store.state.utils.year;
            },
            set (value) {
                this.$store.commit("utils/" + typesUtils.SET_YEAR, value);
            }
        },
        month_selected: {
            get () {
                return this.$store.state.utils.month;
            },
            set (value) {
                this.$store.commit("utils/" + typesUtils.SET_MONTH, value);
            }
        },
        years() {
            const year = new Date().getFullYear();
            return Array.from({length: year - 2019}, (value, index) => 2021 + index);
        }
    },
    watch: {
        month_select() {
            this.month_selected = this.month_select;
        },
        month_selected() {
            this.month_select = this.month_selected;
        }
    }
};
</script>
