import { routes as auth } from "../views/auth";
import { routes as dashboard } from "../views/dashboard";
import { routes as credit_card } from "../views/credit-card";
import { routes as account } from "../views/account";
import { routes as settings } from "../views/settings";
import { routes as budget } from "../views/budget";

export default [
    ...auth,
    ...dashboard,
    ...credit_card,
    ...account,
    ...settings,
    ...budget
];