<template>
    <v-dialog v-model="value" max-width="500px" persistent>
        <v-card>
            <v-card-title>
                <span class="text-h5">
                    Novo planejamento
                    <v-icon right>
                        mdi-plus
                    </v-icon>
                </span>
            </v-card-title>
            <v-card-text>
                <v-container>
                    <v-alert v-if="error_message" type="error">
                        {{ error_message }}
                    </v-alert>
                    <v-form ref="formAdd">
                        <v-row>
                            <v-col cols="6">
                                <v-autocomplete
                                    v-model="category"
                                    label="Categoria"
                                    :rules="category_rules"
                                    :items="macro_category"
                                ></v-autocomplete>
                            </v-col>
                            <v-col cols="6">
                                <base-text-money
                                    v-model="price"
                                    label="Valor planejado"
                                    :rules="price_rules"
                                ></base-text-money>
                            </v-col>
                        </v-row>
                    </v-form>
                </v-container>
            </v-card-text>
            <v-card-actions>
                <v-spacer></v-spacer>
                <v-btn
                    color="primary"
                    text
                    :disabled="loading"
                    @click="close"
                >
                    Cancelar
                </v-btn>
                <v-btn
                    color="success"
                    text
                    :disabled="loading"
                    @click="create"
                >
                    Salvar
                </v-btn>
            </v-card-actions>
            <v-progress-linear
                v-if="loading"
                indeterminate
                height="6"
            ></v-progress-linear>
        </v-card>
        <base-notify
            v-model="show_notify"
            :type="notify_type"
            :message="notify_message"
        >
        </base-notify>
    </v-dialog>
</template>

<script>
import BaseNotify from "../../../components/BaseNotify";
import BaseTextMoney from "../../../components/BaseTextMoney";

import { mapActions } from "vuex";

export default {
    components: {
        BaseNotify,
        BaseTextMoney
    },
    props: {
        value: {
            type: Boolean,
            required: true
        }
    },
    data() {
        return {
            category: null,
            category_rules: [
                v => !!v || "Selecione uma categoria",
            ],
            price: null,
            price_rules: [
                v => !!v || "Informe um valor",
            ],
            macro_category: [],
            error_message: null,
            loading: false,
            notify_type: null,
            notify_message: "",
            show_notify: false
        };
    },
    mounted() {
        this.getListMacroCategory();
    },
    methods: {
        ...mapActions("settings", ["ActionDoListNotInBudget"]),
        ...mapActions("budget", ["ActionDoCreateBudget"]),
        close() {
            this.cleanForm();
            this.$emit("close");
            this.$emit("input", false);
        },
        cleanForm() {
            this.category = null;
            this.price = null;

            this.$refs.formAdd.resetValidation();
        },
        create() {
            this.loading = true;

            if (this.$refs.formAdd.validate()) {
                try {
                    this.ActionDoCreateBudget({
                        category_id: this.category,
                        planned_value: (parseInt(this.price) == "000" || !parseInt(this.price) ? 0 : parseInt(this.price))
                    }).then(response => {
                        this.error_message = null;

                        if (response.budget) {
                            this.show_notify = true;
                            this.notify_type = "success";
                            this.notify_message = "Planejamento salvo com sucesso!";
                            this.loading = false;
                            this.close();
                        } else {
                            this.show_notify = true;
                            this.notify_type = "error";
                            this.notify_message = "Ocorreu um erro!";
                            this.loading = false;
                            this.error_message = response.error;
                        }

                        this.getListMacroCategory();
                    });
                } catch (error) {
                    this.show_notify = true;
                    this.notify_type = "error";
                    this.notify_message = "Ocorreu um erro!";
                    this.loading = false;
                    this.error_message = error.response.data.error;
                }
            } else {
                this.loading = false;
            }
        },
        async getListMacroCategory() {
            await this.ActionDoListNotInBudget().then((response) => {
                this.macro_category = [];
                let new_list = {};

                for (let macro of response) {
                    if (new_list[macro.id + "macro"] !== undefined) {
                        new_list[macro.id + "macro"].categories.push(...macro.categories);
                    } else {
                        new_list[macro.id + "macro"] = {
                            name: macro.name,
                            id: macro.id,
                            categories: macro.categories
                        };
                    }
                }

                for (let macro of Object.values(new_list)) {
                    this.macro_category.push({
                        text: macro.name,
                        value: macro.id + "macro",
                        header: macro.name
                    });

                    for (let category of macro.categories) {
                        this.macro_category.push({
                            text: category.name,
                            value: category.id,
                            divider: false
                        });
                    }
                }
            });
        }
    }
};
</script>
