<template>
    <v-dialog v-model="value" max-width="500px" persistent>
        <v-card>
            <v-card-title>
                <span class="text-h5">
                    Deletar sub categoria
                    <v-icon right>
                        mdi-close-circle-outline
                    </v-icon>
                </span>
            </v-card-title>
            <v-card-text>
                <v-container>
                    <v-alert v-if="error_message" type="error">
                        {{ error_message }}
                    </v-alert>
                    <v-form ref="formDelete">
                        <v-row>
                            <v-col cols="12">
                                <v-text-field
                                    v-model="category.name"
                                    label="Nome da sub categoria"
                                    disabled
                                ></v-text-field>
                            </v-col>
                        </v-row>
                    </v-form>
                </v-container>
            </v-card-text>
            <v-card-actions>
                <v-spacer></v-spacer>
                <v-btn
                    color="primary"
                    text
                    :disabled="deleting"
                    @click="close"
                >
                    Cancelar
                </v-btn>
                <v-btn
                    color="error"
                    text
                    :disabled="deleting"
                    @click="remove"
                >
                    Deletar
                </v-btn>
            </v-card-actions>
            <v-progress-linear
                v-if="deleting"
                indeterminate
                height="6"
            ></v-progress-linear>
        </v-card>
        <base-notify
            v-model="show_notify"
            :type="notify_type"
            :message="notify_message"
        >
        </base-notify>
    </v-dialog>
</template>

<script>
import BaseNotify from "../../../../components/BaseNotify";
import { mapActions } from "vuex";

export default {
    components: {
        BaseNotify
    },
    props: {
        value: {
            type: Boolean,
            required: true
        },
        categoryId: {
            type: Number,
            required: false,
            default: null
        }
    },
    data() {
        return {
            loading: false,
            deleting: false,
            error_message: null,
            show_notify: false,
            notify_type: null,
            notify_message: "",
            category: {
                name: null
            }
        };
    },
    watch: {
        value(new_value) {
            if (new_value) {
                this.read();
            }
        }
    },
    methods: {
        ...mapActions("settings", ["ActionDoReadCategory", "ActionDoDeleteCategory"]),
        close() {
            this.$refs.formDelete.reset();
            this.$emit("close");
            this.$emit("input", false);
        },
        async read() {
            this.loading = true;

            if (this.categoryId) {
                await this.ActionDoReadCategory(this.categoryId).then(response => {
                    this.category = response;
                    this.loading = false;
                });
            } else {
                this.close();
            }
        },
        remove() {
            this.deleting = true;

            if (this.$refs.formDelete.validate()) {

                try {
                    this.ActionDoDeleteCategory(this.category.id).then(response => {
                        this.error_mesasge = null;

                        if (response.category) {
                            this.show_notify = true;
                            this.notify_type = "success";
                            this.notify_message = "Sub categoria deletada com sucesso!";
                            this.deleting = false;
                            this.$refs.formDelete.reset();
                            this.close();
                        } else {
                            this.show_notify = true;
                            this.notify_type = "error";
                            this.notify_message = "Ocorreu um erro!";
                            this.deleting = false;
                            this.error_mesasge = response.error;
                        }
                    });
                } catch (error) {
                    this.show_notify = true;
                    this.notify_type = "error";
                    this.notify_message = "Ocorreu um erro!";
                    this.deleting = false;
                    this.error_mesasge = error.response.data.error;
                }
            } else {
                this.deleting = false;
            }
        }
    }
};
</script>