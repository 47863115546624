<template>
    <v-dialog v-model="value" persistent>
        <v-card>
            <v-card-title>
                <v-row no-gutters>
                    <v-col cols="12">
                        <span class="text-h5">
                            Extrato da conta <b>{{ account.name }}</b>
                            <v-icon right>
                                mdi-piggy-bank
                            </v-icon>
                        </span>
                    </v-col>
                    <v-col>
                        <span class="text-subtitle-1">
                            Saldo: <b :class="account.balance < 0 ? 'error--text' : 'success--text'">{{ account.balance | toCurrency }}</b>
                        </span>
                    </v-col>
                </v-row>
            </v-card-title>
            <v-card-text>
                <v-container>
                    <v-data-table
                        :headers="headers"
                        :items="extract"
                        :items-per-page="10"
                        :loading="loading"
                        :sort-by="['date']"
                        :sort-desc="[true]"
                        :mobile-breakpoint="0"
                        item-key="id"
                        show-expand
                        single-expand
                    >
                        <template v-slot:[`item.value`]="{ item }">
                            <div v-if="item.type === 'I'" class="success--text">
                                + {{ item.value | toCurrency }}
                            </div>
                            <div v-else class="error--text">
                                - {{ item.value | toCurrency }}
                            </div>
                        </template>
                        <template v-slot:[`item.date`]="{ item }">
                            <span v-date-usa-br="item.date"></span>
                        </template>
                        <template v-slot:[`item.actions`]="{ item }">
                            <v-row class="flex-nowrap">
                                <v-col cols="auto">
                                    <v-btn icon color="primary" @click="editExtract(item.id)">
                                        <v-icon>mdi-note-edit</v-icon>
                                    </v-btn>
                                </v-col>
                                <v-col cols="auto">
                                    <v-btn icon color="error" @click="deleteExtract(item.id)">
                                        <v-icon>mdi-note-remove</v-icon>
                                    </v-btn>
                                </v-col>
                            </v-row>
                        </template>
                        <template v-slot:expanded-item="options">
                            <td :colspan="options.headers.length" class="pa-5">
                                <span class="text-pre" v-html="options.item.long_description" />
                            </td>
                        </template>
                    </v-data-table>
                </v-container>
            </v-card-text>
            <v-card-actions>
                <v-spacer></v-spacer>
                <v-btn
                    color="primary"
                    text
                    :disabled="loading"
                    @click="close"
                >
                    Fechar
                </v-btn>
            </v-card-actions>
        </v-card>
        <dialog-edit-extract v-model="dialog_edit" :extract-id="extract_selected_id" @close="read"></dialog-edit-extract>
        <dialog-delete-extract v-model="dialog_delete" :extract-id="extract_selected_id" @close="read"></dialog-delete-extract>
    </v-dialog>
</template>

<script>
import DialogEditExtract from "./DialogEditExtract";
import DialogDeleteExtract from "./DialogDeleteExtract";

import { mapActions, mapState } from "vuex";

export default {
    components: {
        DialogEditExtract,
        DialogDeleteExtract
    },
    props: {
        value: {
            type: Boolean,
            required: true
        },
        accountId: {
            type: Number,
            required: false,
            default: null
        }
    },
    data() {
        return {
            loading: false,
            account: {
                name: null
            },
            extract: [],
            headers: [
                { text: "Categoria", value: "category", class: "text-no-wrap", cellClass: "text-no-wrap" },
                { text: "Título", value: "description" },
                { text: "Valor", value: "value", class: "text-no-wrap", cellClass: "text-no-wrap" },
                { text: "Data", value: "date", class: "text-no-wrap", cellClass: "text-no-wrap" },
                { text: "Ações", value: "actions", sortable: false },
                { text: "", value: "data-table-expand" }
            ],
            dialog_edit: false,
            dialog_delete: false,
            extract_selected_id: null
        };
    },
    computed: {
        ...mapState("utils", {
            month: state => state.month,
            year: state => state.year
        })
    },
    watch: {
        value(new_value) {
            if (new_value) {
                this.read();
            }
        }
    },
    methods: {
        ...mapActions("account", ["ActionDoReadAccount"]),
        ...mapActions("extract", ["ActionDoListExtractAccount"]),
        close() {
            this.extract = [];
            this.$emit("close");
            this.$emit("input", false);
        },
        async read() {
            this.loading = true;

            if (this.accountId) {
                await this.ActionDoReadAccount(this.accountId).then(response => {
                    this.account = response;
                    this.readExtracts();
                });
            } else {
                this.close();
            }
        },
        async readExtracts() {
            if (this.accountId) {
                await this.ActionDoListExtractAccount({
                    account_id: this.accountId,
                    month: this.month,
                    year: this.year
                }).then(response => {
                    this.extract = response;
                    this.loading = false;
                });
            } else {
                this.close();
            }
        },
        editExtract(id) {
            this.extract_selected_id = id;
            this.dialog_edit = true;
        },
        deleteExtract(id) {
            this.extract_selected_id = id;
            this.dialog_delete = true;
        }
    }
};
</script>
