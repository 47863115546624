<template>
    <v-container>
        <v-row justify="space-between">
            <v-col cols="auto">
                <v-row no-gutters>
                    <v-col cols="12">
                        <h4>Total Planejado</h4>
                    </v-col>
                    <v-col cols="12">
                        {{ total_planned | toCurrency }}
                    </v-col>
                </v-row>
            </v-col>
            <v-col cols="auto">
                <v-row no-gutters>
                    <v-col cols="12">
                        <h4>Total Gasto</h4>
                    </v-col>
                    <v-col cols="12">
                        {{ total_value | toCurrency }}
                    </v-col>
                </v-row>
            </v-col>
            <v-col cols="auto">
                <v-btn color="primary" @click="dialog_add = true">
                    <v-icon left>
                        mdi-notebook-plus-outline
                    </v-icon>
                    Novo Planejamento
                </v-btn>
            </v-col>
        </v-row>
        <v-row>
            <v-col
                v-for="(column, i) in budgetList"
                :key="i"
                cols="12"
                lg="6"
            >
                <v-row>
                    <v-col
                        v-for="(budget, n) in column"
                        :key="n"
                        cols="12"
                    >
                        <v-card>
                            <v-card-title>
                                <v-row justify="space-between">
                                    <v-col cols="auto">
                                        {{ budget.name }}
                                    </v-col>
                                    <v-col cols="auto">
                                        <span class="text-caption">Planejado: <b>{{ budget.total_planned | toCurrency }}</b></span>
                                    </v-col>
                                    <v-col cols="auto">
                                        <span :class="(budget.total_value > budget.total_planned ? 'error--text' : 'success--text') + ' text-caption'">Total: <b>{{ budget.total_value | toCurrency }}</b></span>
                                    </v-col>
                                    <v-col cols="auto">
                                        <span :class="((budget.total_value - budget.total_planned) > 0 ? 'error--text' : ((budget.total_value - budget.total_planned) < 0 ? 'success--text' : 'primary--text')) + ' text-caption'">Diferença: <b>{{ budget.total_value - budget.total_planned | toCurrency }}</b></span>
                                    </v-col>
                                </v-row>
                            </v-card-title>
                            <v-card-text>
                                <v-data-table
                                    :headers="headers"
                                    :items="budget.categories"
                                    hide-default-footer
                                    dense
                                    :sort-by="['name']"
                                    :sort-desc="[false]"
                                    :mobile-breakpoint="0"
                                >
                                    <template v-slot:[`item.planned_value`]="{ item }">
                                        {{ item.planned_value | toCurrency }}
                                    </template>
                                    <template v-slot:[`item.records_sum_value`]="{ item }">
                                        <span :class="(item.total_value > item.planned_value ? 'error--text' : '')">{{ item.total_value | toCurrency }}</span>
                                    </template>
                                    <template v-slot:[`item.actions`]="{ item }">
                                        <v-row class="flex-nowrap">
                                            <v-col cols="auto">
                                                <v-btn icon color="success" @click="openListRecords(item.id)">
                                                    <v-icon>mdi-view-list</v-icon>
                                                </v-btn>
                                            </v-col>
                                            <v-col cols="auto">
                                                <v-btn icon color="primary" @click="editBudget(item.budget_id)">
                                                    <v-icon>mdi-pencil-circle</v-icon>
                                                </v-btn>
                                            </v-col>
                                            <v-col cols="auto">
                                                <v-btn icon color="error" @click="deleteBudget(item.budget_id)">
                                                    <v-icon>mdi-close-circle</v-icon>
                                                </v-btn>
                                            </v-col>
                                        </v-row>
                                    </template>
                                </v-data-table>
                            </v-card-text>
                        </v-card>
                    </v-col>
                </v-row>
            </v-col>
        </v-row>
        <dialog-add v-model="dialog_add" @close="getListBudget"></dialog-add>
        <dialog-edit v-model="dialog_edit" :budget-id="budget_selected" @close="getListBudget"></dialog-edit>
        <dialog-delete v-model="dialog_remove" :budget-id="budget_selected" @close="getListBudget"></dialog-delete>
        <dialog-list-records-category v-model="dialog_list_records" :category-id="category_selected" @close="getListBudget"></dialog-list-records-category>
    </v-container>
</template>

<script>
import DialogAdd from "./components/DialogAdd";
import DialogEdit from "./components/DialogEdit";
import DialogDelete from "./components/DialogDelete";
import DialogListRecordsCategory from "../credit-card-record/components/DialogListRecordsCategory";

import { mapActions, mapState } from "vuex";

export default {
    components: {
        DialogAdd,
        DialogDelete,
        DialogEdit,
        DialogListRecordsCategory
    },
    data() {
        return {
            headers: [
                { text: "Sub Categoria", value: "name", class: "text-no-wrap", cellClass: "text-no-wrap" },
                { text: "Planejado", value: "planned_value", class: "text-no-wrap", cellClass: "text-no-wrap" },
                { text: "Atual", value: "records_sum_value", class: "text-no-wrap", cellClass: "text-no-wrap" },
                { text: "Ações", value: "actions", sortable: false }
            ],
            loading: false,
            dialog_add: false,
            dialog_edit: false,
            dialog_remove: false,
            dialog_list_records: false,
            budget_selected: null,
            category_selected: null,
            total_planned: 0,
            total_value: 0
        };
    },
    computed: {
        ...mapState("utils", {
            month: state => state.month,
            year: state => state.year
        }),
        ...mapState("budget", {
            budgets: state => state.budgets
        }),
        budgetList() {
            let data = {};

            for (let item of this.budgets) {
                let budget = JSON.parse(JSON.stringify(item));

                if (budget.category) {
                    let category = JSON.parse(JSON.stringify(budget.category));
                    category["planned_value"] = budget.planned_value;
                    category["budget_id"] = budget.id;

                    let total_value = budget.category.records_sum_value ? parseInt(budget.category.records_sum_value) : 0;
                    total_value += budget.category.extracts_sum_value ? parseInt(budget.category.extracts_sum_value) : 0;
                    total_value += budget.category.future_releases_sum_value ? parseInt(budget.category.future_releases_sum_value) : 0;

                    category["total_value"] = total_value;

                    if (data[budget.category.macro_category_id]) {
                        data[budget.category.macro_category_id].categories.push(category);
                        data[budget.category.macro_category_id]["total_value"] += total_value;
                        data[budget.category.macro_category_id]["total_planned"] += budget.planned_value;
                    } else {
                        data[budget.category.macro_category_id] = JSON.parse(JSON.stringify(budget.category.macro_category));
                        data[budget.category.macro_category_id]["categories"] = [category];
                        data[budget.category.macro_category_id]["total_value"] = total_value;
                        data[budget.category.macro_category_id]["total_planned"] = budget.planned_value;
                    }
                }
            }

            let count = 1;
            let half = Math.round(Object.keys(data).length / 2);
            let result = {
                one: {},
                two: {}
            };

            for (let separate in data) {
                if (count < half) {
                    result.one[separate] = data[separate];
                } else {
                    result.two[separate] = data[separate];
                }

                count++;
            }

            return result;
        }
    },
    watch: {
        month() {
            this.getListBudget();
        },
        year() {
            this.getListBudget();
        },
        budgetList() {
            this.total_planned = 0;
            this.total_value = 0;

            for (let item of Object.values(this.budgetList)) {
                for (let budget of Object.values(item)) {
                    this.total_planned += budget.total_planned;
                    this.total_value += budget.total_value;
                }
            }
        }
    },
    mounted() {
        if (!this.budgets || this.budgets.length === 0) {
            this.getListBudget();
        }
    },
    methods: {
        ...mapActions("budget", ["ActionDoListBudgetWithTotal"]),
        async getListBudget() {
            this.loading = true;
            await this.ActionDoListBudgetWithTotal({
                month: this.month,
                year: this.year
            }).then(() => {
                this.loading = false;
            });
        },
        editBudget(id) {
            this.budget_selected = id;
            this.dialog_edit = true;
        },
        deleteBudget(id) {
            this.budget_selected = id;
            this.dialog_remove = true;
        },
        openListRecords(id) {
            this.category_selected = id;
            this.dialog_list_records = true;
        }
    }
};
</script>
