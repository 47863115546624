<template>
    <v-dialog v-model="value" max-width="500px" persistent>
        <v-card>
            <v-card-title>
                <span class="text-h5">
                    Editar cartão
                    <v-icon right>
                        mdi-pencil
                    </v-icon>
                </span>
            </v-card-title>
            <v-card-text>
                <v-container>
                    <v-alert v-if="error_message" type="error">
                        {{ error_message }}
                    </v-alert>
                    <v-form ref="formEdit">
                        <v-row>
                            <v-col cols="6">
                                <v-autocomplete
                                    v-model="budget.category_id"
                                    label="Categoria"
                                    :rules="category_rules"
                                    :items="macro_category"
                                    disabled
                                ></v-autocomplete>
                            </v-col>
                            <v-col cols="6">
                                <base-text-money
                                    v-model="budget.planned_value"
                                    label="Valor planejado"
                                    :rules="price_rules"
                                ></base-text-money>
                            </v-col>
                        </v-row>
                    </v-form>
                </v-container>
            </v-card-text>
            <v-card-actions>
                <v-spacer></v-spacer>
                <v-btn
                    color="primary"
                    text
                    :disabled="saving"
                    @click="close"
                >
                    Cancelar
                </v-btn>
                <v-btn
                    color="success"
                    text
                    :disabled="saving"
                    @click="save"
                >
                    Salvar
                </v-btn>
            </v-card-actions>
            <v-progress-linear
                v-if="saving"
                indeterminate
                height="6"
            ></v-progress-linear>
        </v-card>
        <base-notify
            v-model="show_notify"
            :type="notify_type"
            :message="notify_message"
        >
        </base-notify>
    </v-dialog>
</template>

<script>
import BaseNotify from "../../../components/BaseNotify";
import BaseTextMoney from "../../../components/BaseTextMoney";

import { mapActions } from "vuex";

export default {
    components: {
        BaseNotify,
        BaseTextMoney
    },
    props: {
        value: {
            type: Boolean,
            required: true
        },
        budgetId: {
            type: Number,
            required: false,
            default: null
        }
    },
    data() {
        return {
            category_rules: [
                v => !!v || "Selecione uma categoria",
            ],
            price_rules: [
                v => !!v || "Informe um valor",
            ],
            list_dias: Array.from({length: 31}, (_, i) => i + 1),
            loading: false,
            saving: false,
            error_message: null,
            show_notify: false,
            notify_type: null,
            notify_message: "",
            macro_category: [],
            budget: {
                category_id: null,
                planned_value: null
            }
        };
    },
    watch: {
        value(new_value) {
            if (new_value) {
                this.read();
            }
        }
    },
    mounted() {
        this.getListMacroCategory();
    },
    methods: {
        ...mapActions("settings", ["ActionDoListMacroCategory"]),
        ...mapActions("budget", ["ActionDoReadBudget", "ActionDoEditBudget"]),
        close() {
            this.$refs.formEdit.reset();
            this.$emit("close");
            this.$emit("input", false);
        },
        async read() {
            this.loading = true;

            if (this.budgetId) {
                await this.ActionDoReadBudget(this.budgetId).then(response => {
                    this.budget = response;
                    this.loading = false;
                });
            } else {
                this.close();
            }
        },
        save() {
            this.saving = true;

            if (this.$refs.formEdit.validate()) {
                try {
                    this.ActionDoEditBudget({
                        id: this.budget.id,
                        category_id: this.budget.category_id,
                        planned_value: (this.budget.planned_value == "000" || !this.budget.planned_value ? 0 : this.budget.planned_value)
                    }).then(response => {
                        this.error_mesasge = null;

                        if (response.budget) {
                            this.show_notify = true;
                            this.notify_type = "success";
                            this.notify_message = "Planejamento salvo com sucesso!";
                            this.saving = false;
                            this.$refs.formEdit.reset();
                            this.close();
                        } else {
                            this.show_notify = true;
                            this.notify_type = "error";
                            this.notify_message = "Ocorreu um erro!";
                            this.saving = false;
                            this.error_mesasge = response.error;
                        }
                    });
                } catch (error) {
                    this.show_notify = true;
                    this.notify_type = "error";
                    this.notify_message = "Ocorreu um erro!";
                    this.saving = false;
                    this.error_mesasge = error.response.data.error;
                }
            } else {
                this.saving = false;
            }
        },
        async getListMacroCategory() {
            await this.ActionDoListMacroCategory().then((response) => {
                for (let macro of response) {
                    this.macro_category.push({
                        text: macro.name,
                        value: macro.id + "macro",
                        header: macro.name
                    });

                    for (let category of macro.categories) {
                        this.macro_category.push({
                            text: category.name,
                            value: category.id,
                            divider: false
                        });
                    }
                }
            });
        }
    }
};
</script>
