import services from "../../../http";
import * as types from "./mutations-types";

export const ActionDoLogin = ({ commit }, payload) => {
    return services.api.request({
        url: "auth/login",
        method: "post",
        data: payload
    }).then(response_data => {
        let response = response_data.data;

        if (response.error === "" && response.token) {
            commit(types.SET_USER, response.data);
            commit(types.SET_TOKEN, response.token);
        }

        return response;
    });
};