<template>
    <v-container min->
        <v-row class="fill-height">
            <v-col cols="12">
                <v-row justify="space-between">
                    <v-col cols="auto">
                        <h1>Dashboard</h1>
                    </v-col>
                    <v-col cols="auto">
                        <float-button-action
                            @creditCardClick="dialog_add_record = true"
                            @accountClick="dialog_add_extract = true"
                            @futureReleaseClick="dialog_add_future_release = true"
                            @transferClick="dialog_transfer = true"
                        ></float-button-action>
                    </v-col>
                </v-row>
            </v-col>
            <v-col cols="12">
                <v-row v-if="listRefreshClosingDate.length > 0">
                    <v-col cols="12">
                        <v-card color="green lighten-4">
                            <v-card-title>
                                <v-row no-gutters>
                                    <v-col cols="12">
                                        Atualizar data de fechamento dos cartões
                                    </v-col>
                                </v-row>
                            </v-card-title>
                            <v-card-text>
                                <v-data-table
                                    :headers="[{ text: 'Cartão', value: 'name' }, { text: 'Ações', value: 'actions', sortable: false }]"
                                    :items="listRefreshClosingDate"
                                    hide-default-footer
                                    class="green lighten-4"
                                    :mobile-breakpoint="0"
                                >
                                    <template v-slot:[`item.actions`]="{ item }">
                                        <v-row>
                                            <v-col cols="auto">
                                                <v-btn icon color="success" @click="refreshClosingDate(item.id)">
                                                    <v-icon>mdi-update</v-icon>
                                                </v-btn>
                                            </v-col>
                                        </v-row>
                                    </template>
                                </v-data-table>
                            </v-card-text>
                        </v-card>
                    </v-col>
                </v-row><v-row v-if="pendingReleases.releases.length > 0">
                    <v-col cols="12">
                        <v-card color="teal lighten-4">
                            <v-card-title>
                                <v-row no-gutters>
                                    <v-col cols="12">
                                        Lançamentos pendentes
                                    </v-col>
                                    <v-col>
                                        <span class="text-caption">Receitas: <b>{{ pendingReleases.income | toCurrency }}</b></span>
                                    </v-col>
                                    <v-col>
                                        <span class="text-caption">Despesas: <b>{{ pendingReleases.expense | toCurrency }}</b></span>
                                    </v-col>
                                    <v-col>
                                        <span class="text-caption">Total: <b :class="pendingReleases.total < 0 ? 'error--text' : 'success--text'">{{ pendingReleases.total | toCurrency }}</b></span>
                                    </v-col>
                                </v-row>
                            </v-card-title>
                            <v-card-text>
                                <v-data-table
                                    :headers="[{ text: 'Conta', value: 'account' }, { text: 'Categoria', value: 'category' }, { text: 'Descrição', value: 'description' }, { text: 'Data', value: 'date' }, { text: 'Valor', value: 'value' }, { text: 'Ações', value: 'actions', sortable: false }]"
                                    :items="pendingReleases.releases"
                                    hide-default-footer
                                    class="teal lighten-4"
                                    :mobile-breakpoint="0"
                                >
                                    <template v-slot:[`item.value`]="{ item }">
                                        <div v-if="item.type === 'I'" class="success--text">
                                            + {{ item.value | toCurrency }}
                                        </div>
                                        <div v-else class="error--text">
                                            - {{ item.value | toCurrency }}
                                        </div>
                                    </template>
                                    <template v-slot:[`item.date`]="{ item }">
                                        <span v-date-usa-br="item.date"></span>
                                    </template>
                                    <template v-slot:[`item.actions`]="{ item }">
                                        <v-row>
                                            <v-col cols="auto">
                                                <v-btn icon color="success" @click="approveRelease(item.id)">
                                                    <v-icon>mdi-check-decagram</v-icon>
                                                </v-btn>
                                            </v-col>
                                        </v-row>
                                    </template>
                                </v-data-table>
                            </v-card-text>
                        </v-card>
                    </v-col>
                </v-row>
                <v-row v-if="invoices_pending_payment.length">
                    <v-col cols="12">
                        <v-card color="red lighten-4">
                            <v-card-title>
                                <v-row no-gutters>
                                    <v-col cols="12">
                                        Faturas fechadas pendente de pagamento
                                    </v-col>
                                    <v-col>
                                        <span class="text-caption">Total: <b>{{ total_closed_invoice | toCurrency }}</b></span>
                                    </v-col>
                                </v-row>
                            </v-card-title>
                            <v-card-text>
                                <v-data-table
                                    :headers="[{ text: 'Cartão', value: 'name' }, { text: 'Referência', value: 'referer' }, { text: 'Dia do vencimento', value: 'due_date' }, { text: 'Total', value: 'credit_card_records_sum_value' }, { text: 'Ações', value: 'actions', sortable: false }]"
                                    :items="invoices_pending_payment"
                                    hide-default-footer
                                    :sort-by="['credit_card_records_sum_value']"
                                    :sort-desc="[true]"
                                    dense
                                    class="red lighten-4"
                                    :mobile-breakpoint="0"
                                >
                                    <template v-slot:[`item.name`]="{ item }">
                                        {{ item.credit_card.name }}
                                    </template>
                                    <template v-slot:[`item.referer`]="{ item }">
                                        {{ item.month }}/{{ item.year }}
                                    </template>
                                    <template v-slot:[`item.due_date`]="{ item }">
                                        {{ item.credit_card.due_date }}
                                    </template>
                                    <template v-slot:[`item.credit_card_records_sum_value`]="{ item }">
                                        {{ item.credit_card_records_sum_value | toCurrency }}
                                    </template>
                                    <template v-slot:[`item.actions`]="{ item }">
                                        <v-row class="flex-nowrap">
                                            <v-col cols="auto">
                                                <v-btn icon color="primary" @click="listRecords(item.credit_card)">
                                                    <v-icon>mdi-view-list</v-icon>
                                                </v-btn>
                                            </v-col>
                                            <v-col cols="auto">
                                                <v-btn icon color="success" @click="payInvoice(item.id)">
                                                    <v-icon>mdi-cash</v-icon>
                                                </v-btn>
                                            </v-col>
                                        </v-row>
                                    </template>
                                </v-data-table>
                            </v-card-text>
                        </v-card>
                    </v-col>
                </v-row>
                <v-row>
                    <v-col
                        cols="12"
                        lg="6"
                        xl="4"
                    >
                        <v-card :height="cardHeightOne">
                            <v-card-title>
                                <v-row no-gutters>
                                    <v-col cols="12">
                                        Resumo
                                    </v-col>
                                </v-row>
                            </v-card-title>
                            <v-card-text>
                                <v-row>
                                    <v-col cols="6">
                                        <v-row no-gutters>
                                            <v-col cols="12">
                                                <v-checkbox
                                                    v-model="sum_income"
                                                    hide-details
                                                    dense
                                                >
                                                    <template #label>
                                                        <span class="text-caption">Receitas futuras</span>
                                                    </template>
                                                </v-checkbox>
                                            </v-col>
                                            <v-col cols="12">
                                                <b class="success--text">+ {{ total_income_summary | toCurrency }}</b>
                                            </v-col>
                                        </v-row>
                                    </v-col>
                                    <v-col cols="6">
                                        <v-row no-gutters>
                                            <v-col cols="12">
                                                <v-checkbox
                                                    v-model="sum_account"
                                                    hide-details
                                                    dense
                                                >
                                                    <template #label>
                                                        <span class="text-caption">Saldo em contas</span>
                                                    </template>
                                                </v-checkbox>
                                            </v-col>
                                            <v-col cols="12">
                                                <b class="success--text">+ {{ total_account_summary | toCurrency }}</b>
                                            </v-col>
                                        </v-row>
                                    </v-col>
                                </v-row>
                                <v-row>
                                    <v-col cols="6">
                                        <v-row no-gutters>
                                            <v-col cols="12">
                                                <v-checkbox
                                                    v-model="sum_expenses"
                                                    hide-details
                                                    dense
                                                >
                                                    <template #label>
                                                        <span class="text-caption">Despesas futuras</span>
                                                    </template>
                                                </v-checkbox>
                                            </v-col>
                                            <v-col cols="12">
                                                <b class="error--text">- {{ total_expense_summary | toCurrency }}</b>
                                            </v-col>
                                        </v-row>
                                    </v-col>
                                    <v-col cols="6">
                                        <v-row no-gutters>
                                            <v-col cols="12">
                                                <v-checkbox
                                                    v-model="sum_invoices"
                                                    hide-details
                                                    dense
                                                >
                                                    <template #label>
                                                        <span class="text-caption">Faturas dos cartões</span>
                                                    </template>
                                                </v-checkbox>
                                            </v-col>
                                            <v-col cols="12">
                                                <b class="error--text">- {{ total_invoice | toCurrency }}</b>
                                            </v-col>
                                        </v-row>
                                    </v-col>
                                </v-row>
                                <v-row>
                                    <v-col cols="auto">
                                        Restante
                                    </v-col>
                                </v-row>
                                <v-row justify="center" no-gutters>
                                    <v-col cols="auto">
                                        <span :class="(total_remaining < 0 ? 'error--text' : 'success--text') + ' text-h2 font-weight-medium'">{{ total_remaining | toCurrency }}</span>
                                    </v-col>
                                </v-row>
                            </v-card-text>
                        </v-card>
                    </v-col>
                    <v-col
                        cols="12"
                        lg="6"
                        xl="4"
                    >
                        <v-card :height="cardHeightOne" class="overflow-auto">
                            <v-card-title>
                                <v-row no-gutters>
                                    <v-col cols="12">
                                        Faturas do mês
                                    </v-col>
                                    <v-col>
                                        <span class="text-caption">Total: <b>{{ total_invoice | toCurrency }}</b></span>
                                    </v-col>
                                </v-row>
                            </v-card-title>
                            <v-card-text>
                                <v-data-table
                                    :headers="[{ text: 'Cartão', value: 'name' }, { text: 'Total', value: 'total' }, { text: 'Ações', value: 'actions', sortable: false }]"
                                    :items="credit_cards"
                                    hide-default-footer
                                    hide-default-header
                                    :sort-by="['name']"
                                    :sort-desc="[false]"
                                    :mobile-breakpoint="0"
                                >
                                    <template v-slot:[`item.total`]="{ item }">
                                        {{ item.total | toCurrency }}
                                    </template>
                                    <template v-slot:[`item.actions`]="{ item }">
                                        <v-row>
                                            <v-col cols="auto">
                                                <v-btn icon color="primary" @click="listRecords(item)">
                                                    <v-icon>mdi-view-list</v-icon>
                                                </v-btn>
                                            </v-col>
                                        </v-row>
                                    </template>
                                </v-data-table>
                            </v-card-text>
                        </v-card>
                    </v-col>
                    <v-col
                        cols="12"
                        lg="12"
                        xl="4"
                    >
                        <v-card :height="cardHeightOne" class="overflow-auto">
                            <v-card-title>
                                <v-row no-gutters>
                                    <v-col cols="12">
                                        Gastos de cartão por categoria
                                    </v-col>
                                </v-row>
                            </v-card-title>
                            <v-card-text>
                                <v-row>
                                    <v-col>
                                        <apexchart
                                            type="donut"
                                            :options="categoriesChart.chartOptions"
                                            :series="categoriesChart.series"
                                        ></apexchart>
                                    </v-col>
                                </v-row>
                            </v-card-text>
                        </v-card>
                    </v-col>
                </v-row>
                <v-row>
                    <v-col
                        cols="12"
                        lg="5"
                        xl="4"
                    >
                        <v-card :height="cardHeightTwo" class="overflow-auto">
                            <v-card-title>
                                <v-row no-gutters>
                                    <v-col cols="12">
                                        Contas bancárias / Vouchers
                                    </v-col>
                                    <v-col>
                                        <span class="text-caption">Total: <b>{{ total_account | toCurrency }}</b></span>
                                    </v-col>
                                </v-row>
                            </v-card-title>
                            <v-card-text>
                                <v-data-table
                                    :headers="[{ text: 'Nome', value: 'name' }, { text: 'Total', value: 'balance' }, { text: 'Ações', value: 'actions', sortable: false }]"
                                    :items="accounts"
                                    hide-default-footer
                                    hide-default-header
                                    :mobile-breakpoint="0"
                                >
                                    <template v-slot:[`item.balance`]="{ item }">
                                        {{ item.balance | toCurrency }}
                                    </template>
                                    <template v-slot:[`item.actions`]="{ item }">
                                        <v-row>
                                            <v-col cols="auto">
                                                <v-btn icon color="primary" @click="listExtract(item)">
                                                    <v-icon>mdi-view-list</v-icon>
                                                </v-btn>
                                            </v-col>
                                        </v-row>
                                    </template>
                                </v-data-table>
                            </v-card-text>
                        </v-card>
                    </v-col>
                    <v-col
                        cols="12"
                        lg="7"
                        xl="8"
                    >
                        <v-card :height="cardHeightTwo" class="overflow-auto">
                            <v-card-title>
                                <v-row no-gutters>
                                    <v-col cols="12">
                                        Lançamentos futuros
                                    </v-col>
                                    <v-col>
                                        <span class="text-caption">Receitas: <b>{{ total_income | toCurrency }}</b></span>
                                    </v-col>
                                    <v-col>
                                        <span class="text-caption">Despesas: <b>{{ total_expense | toCurrency }}</b></span>
                                    </v-col>
                                    <v-col>
                                        <span class="text-caption">Total: <b :class="total_future < 0 ? 'error--text' : 'success--text'">{{ total_future | toCurrency }}</b></span>
                                    </v-col>
                                </v-row>
                            </v-card-title>
                            <v-card-text>
                                <v-data-table
                                    :headers="[{ text: 'Conta', value: 'account' }, { text: 'Categoria', value: 'category' }, { text: 'Descrição', value: 'description' }, { text: 'Data', value: 'date' }, { text: 'Valor', value: 'value' }, { text: 'Ações', value: 'actions', sortable: false }]"
                                    :items="future_releases"
                                    hide-default-footer
                                    :items-per-page="-1"
                                    :mobile-breakpoint="0"
                                >
                                    <template v-slot:[`item.value`]="{ item }">
                                        <div v-if="item.type === 'I'" class="success--text">
                                            + {{ item.value | toCurrency }}
                                        </div>
                                        <div v-else class="error--text">
                                            - {{ item.value | toCurrency }}
                                        </div>
                                    </template>
                                    <template v-slot:[`item.date`]="{ item }">
                                        <span v-date-usa-br="item.date"></span>
                                    </template>
                                    <template v-slot:[`item.actions`]="{ item }">
                                        <v-row class="flex-nowrap">
                                            <v-col cols="auto">
                                                <v-btn icon color="success" @click="approveRelease(item.id)">
                                                    <v-icon>mdi-check-decagram</v-icon>
                                                </v-btn>
                                            </v-col>
                                            <v-col cols="auto">
                                                <v-btn icon color="primary" @click="editFutureRelease(item.id)">
                                                    <v-icon>mdi-pencil-box</v-icon>
                                                </v-btn>
                                            </v-col>
                                            <v-col cols="auto">
                                                <v-btn icon color="error" @click="deleteFutureRelease(item.id)">
                                                    <v-icon>mdi-delete</v-icon>
                                                </v-btn>
                                            </v-col>
                                        </v-row>
                                    </template>
                                </v-data-table>
                            </v-card-text>
                        </v-card>
                    </v-col>
                </v-row>
                <v-row>
                    <v-col cols="12">
                        <v-card ref="testeCard" :height="cardHeightTwo">
                            <v-card-title>
                                <v-row no-gutters align="center">
                                    <v-col>
                                        Gastos por mês
                                    </v-col>
                                    <v-col>
                                        <v-row align="center">
                                            <v-col cols="auto" class="text-caption">
                                                Lançamentos de contas
                                            </v-col>
                                            <v-col>
                                                <v-switch
                                                    v-model="extract"
                                                ></v-switch>
                                            </v-col>
                                        </v-row>
                                    </v-col>
                                    <v-col>
                                        <v-row align="center">
                                            <v-col cols="auto" class="text-caption">
                                                Lançamentos futuros
                                            </v-col>
                                            <v-col>
                                                <v-switch
                                                    v-model="future"
                                                ></v-switch>
                                            </v-col>
                                        </v-row>
                                    </v-col>
                                </v-row>
                            </v-card-title>
                            <v-card-text>
                                <v-row>
                                    <v-col>
                                        <apexchart
                                            type="area"
                                            :height="cardHeightTwo - 150"
                                            :width="card_width"
                                            :options="dataChart.chartOptions"
                                            :series="dataChart.series"
                                        ></apexchart>
                                    </v-col>
                                </v-row>
                            </v-card-text>
                        </v-card>
                    </v-col>
                </v-row>
            </v-col>
        </v-row>
        <dialog-add-record v-model="dialog_add_record" @close="closeAddRecord"></dialog-add-record>
        <dialog-list-records v-model="dialog_list_records" :credit-card-id="credit_card_selected" @close="getListCreditCard"></dialog-list-records>
        <dialog-add-extract v-model="dialog_add_extract" @close="closeAddExtract"></dialog-add-extract>
        <dialog-list-extract v-model="dialog_list_extract" :account-id="account_selected" @close="getListAccount"></dialog-list-extract>
        <dialog-add-future-release v-model="dialog_add_future_release" @close="getListFutureRelease"></dialog-add-future-release>
        <dialog-list-total v-model="dialog_list_categories_total" :macro-category-id="macro_category_selected"></dialog-list-total>
        <dialog-pay-invoice v-model="dialog_pay_invoice" :invoice-id="invoice_selected" @close="refreshData"></dialog-pay-invoice>
        <dialog-edit-future-release v-model="dialog_edit_future_release" :future-release-id="future_release_selected" @close="getListFutureRelease"></dialog-edit-future-release>
        <dialog-delete-future-release v-model="dialog_delete_future_release" :future-release-id="future_release_selected" @close="getListFutureRelease"></dialog-delete-future-release>
        <dialog-approve-release v-model="dialog_approve_release" :future-release-id="future_release_selected" @close="refreshData"></dialog-approve-release>
        <dialog-update-closing-date v-model="dialog_update_closing_date" :credit-card-id="credit_card_selected" @close="getListCreditCard"></dialog-update-closing-date>
        <dialog-transfer v-model="dialog_transfer" @close="getListAccount"></dialog-transfer>

        <base-notify
            v-model="show_notify"
            :type="notify_type"
            :message="notify_message"
        >
        </base-notify>
    </v-container>
</template>

<script>

import Vue from "vue";

import BaseNotify from "../../components/BaseNotify.vue";
import FloatButtonAction from "./components/FloatButtonAction";
import DialogAddRecord from "../credit-card-record/components/DialogAddRecord";
import DialogListRecords from "../credit-card-record/components/DialogListRecords";
import DialogAddExtract from "../extract/components/DialogAddExtract";
import DialogListExtract from "../extract/components/DialogListExtract";
import DialogAddFutureRelease from "../future-release/components/DialogAddFutureRelease";
import DialogListTotal from "../settings/components/macro_category/DialogListTotal";
import DialogPayInvoice from "../invoice/components/DialogPayInvoice";
import DialogEditFutureRelease from "../future-release/components/DialogEditFutureRelease";
import DialogDeleteFutureRelease from "../future-release/components/DialogDeleteFutureRelease";
import DialogApproveRelease from "../future-release/components/DialogApproveRelease";
import DialogUpdateClosingDate from "../credit-card/components/DialogUpdateClosingDate";
import DialogTransfer from "../account/components/DialogTransfer.vue";

import { mapActions, mapState } from "vuex";

export default {
    components: {
        BaseNotify,
        FloatButtonAction,
        DialogAddRecord,
        DialogListRecords,
        DialogAddExtract,
        DialogListExtract,
        DialogAddFutureRelease,
        DialogListTotal,
        DialogPayInvoice,
        DialogEditFutureRelease,
        DialogDeleteFutureRelease,
        DialogApproveRelease,
        DialogUpdateClosingDate,
        DialogTransfer
    },
    data() {
        return {
            gradient: ["#f72047", "#ffd200", "#1feaea"],
            loading_credit_cards: false,
            loading_accounts: false,
            loading_future_releases: false,
            loading_macro_category_total: false,
            dialog_add_record: false,
            dialog_list_records: false,
            dialog_list_extract: false,
            dialog_list_categories_total: false,
            dialog_pay_invoice: false,
            invoice_selected: null,
            credit_card_selected: null,
            account_selected: null,
            macro_category_selected: null,
            dialog_add_extract: false,
            dialog_add_future_release: false,
            dialog_transfer: false,
            dialog_edit_future_release: false,
            dialog_delete_future_release: false,
            dialog_approve_release: false,
            dialog_update_closing_date: false,
            future_release_selected: null,
            total_invoice: 0,
            total_account: 0,
            total_future: 0,
            total_income: 0,
            total_expense: 0,
            total_expense_summary: 0,
            total_income_summary: 0,
            total_account_summary: 0,
            total_closed_invoice: 0,
            invoices_pending_payment: [],
            extract: false,
            future: false,
            label_sparkline: [
                "Janeiro",
                "Fevereiro",
                "Março",
                "Abril",
                "Maio",
                "Junho",
                "Julho",
                "Agosto",
                "Setembro",
                "Outubro",
                "Novembro",
                "Dezembro"
            ],
            card_width: "100px",
            sum_income: true,
            sum_account: false,
            sum_expenses: true,
            sum_invoices: true,
            show_notify: false,
            notify_type: null,
            notify_message: "",
        };
    },
    computed: {
        ...mapState("utils", {
            month: state => state.month,
            year: state => state.year
        }),
        ...mapState("account", {
            accounts: state => state.accounts
        }),
        ...mapState("credit_card", {
            credit_cards: state => state.credit_cards_total_invoice
        }),
        ...mapState("future_release", {
            future_releases: state => state.future_releases_period
        }),
        ...mapState("settings", {
            macro_categories: state => state.macro_category_total
        }),
        ...mapState("credit_card_record", {
            list_total_year: state => state.list_total_year
        }),
        cardHeightOne() {
            return this.$vuetify.breakpoint.mdAndUp ? 330 : undefined;
        },
        cardHeightTwo() {
            let height = 150;
            let max = 0;

            max = this.accounts.length;
            max = this.future_releases.length > max ? this.future_releases.length : max;

            let total = height + (max * 50);

            return total > 330 ? ( total > 500 ? 500 : total ) : 330;
        },
        total_remaining() {
            let income = 0;
            income += this.sum_income ? parseInt(this.total_income_summary) : 0;
            income += this.sum_account ? parseInt(this.total_account_summary) : 0;

            let expense = 0;
            expense += this.sum_expenses ? parseInt(this.total_expense_summary) : 0;
            expense += this.sum_invoices ? parseInt(this.total_invoice) : 0;

            return income - expense;
        },
        categoriesChart() {
            let series = [];
            let labels = [];
            let list_macro = this.macro_categories;
            let context = this;

            for (let category of this.macro_categories) {
                series.push(category.credit_card_records_sum_value);
                labels.push(category.name);
            }

            return {
                series: series,
                chartOptions: {
                    labels: labels,
                    chart: {
                        type: "donut",
                        events: {
                            dataPointSelection: function(event, chartContext, config) {
                                context.listCategoriesTotal(list_macro[config.dataPointIndex].id);
                            }
                        }
                    },
                    plotOptions: {
                        pie: {
                            donut: {
                                labels: {
                                    show: true,
                                    name: {
                                        show: true,
                                        formatter: function (val) {
                                            return val;
                                        }
                                    },
                                    value: {
                                        show: true,
                                        formatter: function (val) {
                                            return Vue.toCurrency(val);
                                        }
                                    },
                                    total: {
                                        show: true,
                                        label: "Total",
                                        formatter: function (w) {
                                            return Vue.toCurrency(w.globals.seriesTotals.reduce((a, b) => {
                                                return a + b;
                                            }, 0));
                                        }
                                    }
                                }
                            }
                        }
                    },
                    tooltip: {
                        enabled: true,
                        y: {
                            show: true,
                            formatter: function (val) {
                                return Vue.toCurrency(val);
                            },
                        }
                    }
                }
            };
        },
        dataChart() {
            let series = [];
            let translate = {
                "records": "Gastos com cartão de crédito",
                "extract_expense": "Despesas das contas",
                "extract_income": "Receitas das contas"
            };

            for (let type in this.list_total_year) {
                series.push({
                    name: translate[type],
                    data: this.list_total_year[type]
                });
            }

            return {
                series: series,
                chartOptions: {
                    chart: {
                        type: "area",
                        zoom: {
                            enabled: false
                        },
                        toolbar: {
                            show: false
                        }
                    },
                    forecastDataPoints: {
                        count: 12 - parseInt(this.month)
                    },
                    dataLabels: {
                        enabled: false
                    },
                    colors: ["#9575CD", "#F44336", "#4CAF50"],
                    stroke: {
                        curve: "smooth"
                    },
                    grid: {
                        row: {
                            colors: ["#f3f3f3", "transparent"], // takes an array which will be repeated on columns
                            opacity: 0.5
                        },
                        padding: {
                            right: 30,
                            left: 20
                        }
                    },
                    xaxis: {
                        categories: this.label_sparkline,
                    },
                    yaxis: {
                        labels: {
                            formatter: function (value) {
                                return Vue.toCurrency(value);
                            }
                        }
                    }
                }
            };
        },
        pendingReleases() {
            let releases = [];
            let total_income = 0;
            let total_expense = 0;

            for (let release of this.future_releases) {
                if (new Date(release.date) <= new Date()) {
                    releases.push(release);

                    if (release.type === "E") {
                        total_expense += release.value;
                    } else {
                        total_income += release.value;
                    }
                }
            }

            return {
                releases: releases,
                income: total_income,
                expense: total_expense,
                total: total_income - total_expense
            };
        },
        listRefreshClosingDate() {
            let list = [];

            for (let credit_card of this.credit_cards) {
                if (credit_card.refresh_closing_date) {
                    list.push(credit_card);
                }
            }

            return list;
        }
    },
    watch: {
        month() {
            this.getListCreditCard();
            this.getListAccount();
            this.getListFutureRelease();
            this.getListTotalMacroCategory();
            this.getListTotalYear();
        },
        year() {
            this.getListCreditCard();
            this.getListAccount();
            this.getListFutureRelease();
            this.getListTotalMacroCategory();
            this.getListTotalYear();
        },
        extract() {
            this.getListTotalYear();
        },
        future() {
            this.getListTotalYear();
        }
    },
    mounted() {
        if (!this.credit_cards || this.credit_cards.length === 0) {
            this.getListCreditCard();
        } else {
            this.makeTotalInvoice();
        }

        if (!this.accounts || this.accounts.length === 0) {
            this.getListAccount();
        } else {
            this.makeTotalAccount();
        }

        if (!this.future_releases || this.future_releases.length === 0) {
            this.getListFutureRelease();
        } else {
            this.makeTotalFutureIncomeExpense();
        }

        if (!this.macro_categories || this.macro_categories.length === 0) {
            this.getListTotalMacroCategory();
        }

        if (!this.list_total_year || this.list_total_year.length === 0) {
            this.getListTotalYear();
        }

        this.getListInvoiceClosedPendingPayment();
        this.card_width = this.$refs["testeCard"].$el.clientWidth - 33;
    },
    methods: {
        ...mapActions("credit_card", ["ActionDoListWithTotalInvoice"]),
        ...mapActions("account", ["ActionDoListAccounts"]),
        ...mapActions("future_release", ["ActionDoListFutureReleasesByPeriod"]),
        ...mapActions("settings", ["ActionDoListMacroCategoryTotal"]),
        ...mapActions("invoice", ["ActionDoListInvoicesClosedPendingPayment"]),
        ...mapActions("credit_card_record", ["ActionDoGetTotalYear"]),
        closeAddRecord(data) {
            if (data.category !== undefined) {
                this.show_notify = true;
                this.notify_type = "info";
                if (data.budget_percent) {
                    this.notify_message = "Você já usou <b>" + data.budget_percent + "%</b> do planejamento com a categoria <b>" + data.category + "</b>";
                } else {
                    this.notify_message = "A categoria <b>" + data.category + "</b> não tem um planejamento definido";
                }
            }

            this.getListCreditCard();
        },
        closeAddExtract(data) {
            if (data.category !== undefined) {
                this.show_notify = true;
                this.notify_type = "info";
                this.notify_message = "Você já usou " + data.budget_percent + "% do planejamento com a categoria " + data.category;
            }

            this.getListAccount();
        },
        async getListCreditCard() {
            this.loading_credit_cards = true;
            await this.ActionDoListWithTotalInvoice({
                month: this.month,
                year: this.year
            }).then(() => {
                this.loading_credit_cards = false;
                this.makeTotalInvoice();
            });
        },
        makeTotalInvoice() {
            this.total_invoice = Vue.sumColumnArrayOfObjects(this.credit_cards, "total");
        },
        async getListAccount() {
            this.loading_accounts = true;
            await this.ActionDoListAccounts().then(() => {
                this.loading_accounts = false;
                this.makeTotalAccount();
            });
        },
        makeTotalAccount() {
            this.total_account = Vue.sumColumnArrayOfObjects(this.accounts, "balance");
            this.total_account_summary = Vue.sumColumnArrayOfObjects(this.accounts, "balance", "include_summary", true);
        },
        async getListFutureRelease() {
            this.loading_future_releases = true;
            await this.ActionDoListFutureReleasesByPeriod({
                month: this.month,
                year: this.year
            }).then(() => {
                this.loading_future_releases = false;
                this.makeTotalFutureIncomeExpense();
            });
        },
        makeTotalFutureIncomeExpense() {
            this.total_income_summary = Vue.sumColumnArrayOfObjects(this.future_releases, "value", "type", "I", "include_summary", true);
            this.total_expense_summary = Vue.sumColumnArrayOfObjects(this.future_releases, "value", "type", "E", "include_summary", true);
            this.total_income = Vue.sumColumnArrayOfObjects(this.future_releases, "value", "type", "I");
            this.total_expense = Vue.sumColumnArrayOfObjects(this.future_releases, "value", "type", "E");
            this.total_future = this.total_income - this.total_expense;
        },
        async getListTotalMacroCategory() {
            this.loading_macro_category_total = true;
            await this.ActionDoListMacroCategoryTotal({
                month: this.month,
                year: this.year
            }).then(() => {
                this.loading_macro_category_total = false;
            });
        },
        async getListTotalYear() {
            await this.ActionDoGetTotalYear({
                year: this.year,
                extract: this.extract,
                future: this.future
            });
        },
        async getListInvoiceClosedPendingPayment() {
            await this.ActionDoListInvoicesClosedPendingPayment().then((response) => {
                this.invoices_pending_payment = response;
                this.total_closed_invoice = Vue.sumColumnArrayOfObjects(this.invoices_pending_payment, "credit_card_records_sum_value");
            });
        },
        listRecords(item) {
            this.credit_card_selected = item.id;
            this.dialog_list_records = true;
        },
        listExtract(item) {
            this.account_selected = item.id;
            this.dialog_list_extract = true;
        },
        listCategoriesTotal(id) {
            this.macro_category_selected = id;
            this.dialog_list_categories_total = true;
        },
        payInvoice(id) {
            this.invoice_selected = id;
            this.dialog_pay_invoice = true;
        },
        editFutureRelease(id) {
            this.future_release_selected = id;
            this.dialog_edit_future_release = true;
        },
        deleteFutureRelease(id) {
            this.future_release_selected = id;
            this.dialog_delete_future_release = true;
        },
        approveRelease(id) {
            this.future_release_selected = id;
            this.dialog_approve_release = true;
        },
        refreshData() {
            this.getListFutureRelease();
            this.getListInvoiceClosedPendingPayment();
            this.getListCreditCard();
            this.getListAccount();
        },
        refreshClosingDate(id) {
            this.credit_card_selected = id;
            this.dialog_update_closing_date = true;
        }
    }
};
</script>
