import services from "../../../http";
import * as types from "./mutations-types";

const baseUrl = "budget";

export const ActionDoListBudgets = () => {
    return services.api.request({
        url: baseUrl,
        method: "get"
    }).then(response_data => {
        let response = response_data.data;

        return response;
    });
};

export const ActionDoCreateBudget = (context, payload) => {
    return services.api.request({
        url: baseUrl,
        method: "post",
        data: payload
    }).then(response_data => {
        let response = response_data.data;

        return response;
    });
};

export const ActionDoListBudgetWithTotal = ({ commit }, payload) => {
    return services.api.request({
        url: baseUrl + "/total-period",
        method: "post",
        data: payload
    }).then(response_data => {
        let response = response_data.data;

        commit(types.SET_LIST_BUDGET, response);

        return response;
    });
};

export const ActionDoReadBudget = (context, id_budget) => {
    return services.api.request({
        url: baseUrl + "/" + id_budget,
        method: "get"
    }).then(response_data => {
        let response = response_data.data;

        return response;
    });
};

export const ActionDoEditBudget = (context, payload) => {
    return services.api.request({
        url: baseUrl + "/" + payload.id,
        method: "put",
        data: payload
    }).then(response_data => {
        let response = response_data.data;

        return response;
    });
};

export const ActionDoDeleteBudget = (context, id_budget) => {
    return services.api.request({
        url: baseUrl + "/" + id_budget,
        method: "delete"
    }).then(response_data => {
        let response = response_data.data;

        return response;
    });
};