<template>
    <v-dialog v-model="value" max-width="500px" persistent>
        <v-card>
            <v-card-title>
                <span class="text-h5">
                    Nova categoria
                    <v-icon right>
                        mdi-plus-box-outline
                    </v-icon>
                </span>
            </v-card-title>
            <v-card-text>
                <v-container>
                    <v-alert v-if="error_message" type="error">
                        {{ error_message }}
                    </v-alert>
                    <v-form ref="formAdd">
                        <v-row>
                            <v-col cols="12">
                                <v-text-field
                                    ref="nameField"
                                    v-model="name"
                                    label="Nome da categoria"
                                    :rules="name_rules"
                                ></v-text-field>
                            </v-col>
                        </v-row>
                    </v-form>
                </v-container>
            </v-card-text>
            <v-card-actions>
                <v-spacer></v-spacer>
                <v-btn
                    color="primary"
                    text
                    :disabled="loading"
                    @click="close"
                >
                    Cancelar
                </v-btn>
                <v-btn
                    color="success"
                    text
                    :disabled="loading"
                    @click="create"
                >
                    Salvar
                </v-btn>
            </v-card-actions>
            <v-progress-linear
                v-if="loading"
                indeterminate
                height="6"
            ></v-progress-linear>
        </v-card>
        <base-notify
            v-model="show_notify"
            :type="notify_type"
            :message="notify_message"
        >
        </base-notify>
    </v-dialog>
</template>

<script>
import BaseNotify from "../../../../components/BaseNotify";
import { mapActions } from "vuex";

export default {
    components: {
        BaseNotify
    },
    props: {
        value: {
            type: Boolean,
            required: true
        }
    },
    data() {
        return {
            name: null,
            name_rules: [
                v => !!v || "Digite um nome",
            ],
            loading: false,
            error_message: null,
            show_notify: false,
            notify_type: null,
            notify_message: ""
        };
    },
    watch: {
        value(val) {
            if (val) {
                setTimeout(() => {
                    this.$refs.nameField.focus();
                });
            }
        }
    },
    methods: {
        ...mapActions("settings", ["ActionDoCreateMacroCategory"]),
        close() {
            this.$refs.formAdd.reset();
            this.$emit("close");
            this.$emit("input", false);
        },
        create() {
            this.loading = true;

            if (this.$refs.formAdd.validate()) {
                try {
                    this.ActionDoCreateMacroCategory({
                        name: this.name
                    }).then(response => {
                        this.error_mesasge = null;

                        if (response.macro_category) {
                            this.show_notify = true;
                            this.notify_type = "success";
                            this.notify_message = "Categoria salva com sucesso!";
                            this.loading = false;
                            this.$refs.formAdd.reset();
                            this.close();
                        } else {
                            this.show_notify = true;
                            this.notify_type = "error";
                            this.notify_message = "Ocorreu um erro!";
                            this.loading = false;
                            this.error_mesasge = response.error;
                        }
                    });
                } catch (error) {
                    this.show_notify = true;
                    this.notify_type = "error";
                    this.notify_message = "Ocorreu um erro!";
                    this.loading = false;
                    this.error_mesasge = error.response.data.error;
                }
            } else {
                this.loading = false;
            }
        }
    }
};
</script>