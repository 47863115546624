<template>
    <v-dialog v-model="value" max-width="500px" persistent>
        <v-card>
            <v-card-title>
                <span class="text-h5">
                    Deletar cartão
                    <v-icon right>
                        mdi-credit-card-remove-outline
                    </v-icon>
                </span>
            </v-card-title>
            <v-card-text>
                <v-container>
                    <v-alert v-if="error_message" type="error">
                        {{ error_message }}
                    </v-alert>
                    <v-form ref="formDelete">
                        <v-row>
                            <v-col cols="6">
                                <v-text-field
                                    v-model="credit_card.name"
                                    label="Nome do cartão"
                                    disabled
                                ></v-text-field>
                            </v-col>
                            <v-col cols="6">
                                <base-text-money
                                    v-model="credit_card.limit"
                                    label="Limite do cartão"
                                    disabled
                                ></base-text-money>
                            </v-col>
                            <v-col cols="6">
                                <v-select
                                    v-model="credit_card.due_date"
                                    label="Dia do vencimento"
                                    :items="list_dias"
                                    disabled
                                ></v-select>
                            </v-col>
                            <v-col cols="6">
                                <base-date-picker
                                    v-model="credit_card.closing_date"
                                    label="Dia do fechamento"
                                    disabled
                                ></base-date-picker>
                            </v-col>
                        </v-row>
                    </v-form>
                </v-container>
            </v-card-text>
            <v-card-actions>
                <v-spacer></v-spacer>
                <v-btn
                    color="primary"
                    text
                    :disabled="deleting"
                    @click="close"
                >
                    Cancelar
                </v-btn>
                <v-btn
                    color="error"
                    text
                    :disabled="deleting"
                    @click="remove"
                >
                    Deletar
                </v-btn>
            </v-card-actions>
            <v-progress-linear
                v-if="deleting"
                indeterminate
                height="6"
            ></v-progress-linear>
        </v-card>
        <base-notify
            v-model="show_notify"
            :type="notify_type"
            :message="notify_message"
        >
        </base-notify>
    </v-dialog>
</template>

<script>
import BaseNotify from "../../../components/BaseNotify";
import BaseTextMoney from "../../../components/BaseTextMoney";
import BaseDatePicker from "../../../components/BaseDatePicker";
import { mapActions } from "vuex";

export default {
    components: {
        BaseNotify,
        BaseTextMoney,
        BaseDatePicker
    },
    props: {
        value: {
            type: Boolean,
            required: true
        },
        creditCardId: {
            type: Number,
            required: false,
            default: null
        }
    },
    data() {
        return {
            list_dias: Array.from({length: 31}, (_, i) => i + 1),
            loading: false,
            deleting: false,
            error_message: null,
            show_notify: false,
            notify_type: null,
            notify_message: "",
            credit_card: {
                name: null,
                limit: null,
                due_date: null,
                closing_date: null
            }
        };
    },
    watch: {
        value(new_value) {
            if (new_value) {
                this.read();
            }
        }
    },
    methods: {
        ...mapActions("credit_card", ["ActionDoReadCreditCard", "ActionDoDeleteCreditCard"]),
        close() {
            this.$refs.formDelete.reset();
            this.$emit("close");
            this.$emit("input", false);
        },
        async read() {
            this.loading = true;

            if (this.creditCardId) {
                await this.ActionDoReadCreditCard(this.creditCardId).then(response => {
                    this.credit_card = response;
                    this.credit_card.closing_date = (new Date(this.credit_card.closing_date)).toISOString().substr(0, 10);
                    this.loading = false;
                });
            } else {
                this.close();
            }
        },
        remove() {
            this.deleting = true;

            if (this.$refs.formDelete.validate()) {
                try {
                    this.ActionDoDeleteCreditCard(this.credit_card.id).then(response => {
                        this.error_mesasge = null;

                        if (response.credit_card) {
                            this.show_notify = true;
                            this.notify_type = "success";
                            this.notify_message = "Cartão deletado com sucesso!";
                            this.deleting = false;
                            this.$refs.formDelete.reset();
                            this.close();
                        } else {
                            this.show_notify = true;
                            this.notify_type = "error";
                            this.notify_message = "Ocorreu um erro!";
                            this.deleting = false;
                            this.error_mesasge = response.error;
                        }
                    });
                } catch (error) {
                    this.show_notify = true;
                    this.notify_type = "error";
                    this.notify_message = "Ocorreu um erro!";
                    this.deleting = false;
                    this.error_mesasge = error.response.data.error;
                }
            } else {
                this.deleting = false;
            }
        }
    }
};
</script>