import services from "../../../http";

const baseUrl = "extract";

export const ActionDoCreateExtract = (context, payload) => {
    return services.api.request({
        url: baseUrl,
        method: "post",
        data: payload
    }).then(response_data => {
        let response = response_data.data;

        return response;
    });
};

export const ActionDoReadExtracts = () => {
    return services.api.request({
        url: baseUrl,
        method: "get"
    }).then(response_data => {
        let response = response_data.data;

        return response;
    });
};

export const ActionDoEditExtract = (context, payload) => {
    return services.api.request({
        url: baseUrl + "/" + payload.id,
        method: "put",
        data: payload
    }).then(response_data => {
        let response = response_data.data;

        return response;
    });
};

export const ActionDoReadExtract = (context, extract_id) => {
    return services.api.request({
        url: baseUrl + "/" + extract_id,
        method: "get"
    }).then(response_data => {
        let response = response_data.data;

        return response;
    });
};

export const ActionDoDeleteExtract = (context, extract_id) => {
    return services.api.request({
        url: baseUrl + "/" + extract_id,
        method: "delete"
    }).then(response_data => {
        let response = response_data.data;

        return response;
    });
};

export const ActionDoListExtractAccount = (context, payload) => {
    return services.api.request({
        url: baseUrl + "/extract-account",
        method: "post",
        data: payload
    }).then(response_data => {
        let response = response_data.data;

        return response;
    });
};