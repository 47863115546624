<template>
    <v-dialog v-model="value" persistent>
        <v-card>
            <v-card-title>
                <span class="text-h5">
                    Gastos na categoria <b>{{ macro_category.name }}</b>
                </span>
            </v-card-title>
            <v-card-text>
                <v-container>
                    <v-row>
                        <v-col>
                            <apexchart
                                type="donut"
                                :width="500"
                                :options="categoriesChart.chartOptions"
                                :series="categoriesChart.series"
                            ></apexchart>
                        </v-col>
                    </v-row>
                </v-container>
            </v-card-text>
            <v-card-actions>
                <v-spacer></v-spacer>
                <v-btn
                    color="primary"
                    text
                    :disabled="loading"
                    @click="close"
                >
                    Fechar
                </v-btn>
            </v-card-actions>
        </v-card>
    </v-dialog>
</template>

<script>
import Vue from "vue";
import { mapActions, mapState } from "vuex";

export default {
    props: {
        value: {
            type: Boolean,
            required: true
        },
        macroCategoryId: {
            type: Number,
            required: false,
            default: null
        }
    },
    data() {
        return {
            loading: false,
            macro_category: {
                name: null
            },
            headers: [
                { text: "Sub Categoria", value: "name" },
                { text: "Total", value: "records_sum_value" }
            ]
        };
    },
    computed: {
        ...mapState("utils", {
            month: state => state.month,
            year: state => state.year
        }),
        categoriesChart() {
            let series = [];
            let labels = [];

            if (this.macro_category.categories !== undefined) {
                for (let category of this.macro_category.categories) {
                    series.push(category.records_sum_value);
                    labels.push(category.name);
                }
            }

            return {
                series: series,
                chartOptions: {
                    labels: labels,
                    chart: {
                        type: "donut",
                    },
                    plotOptions: {
                        pie: {
                            donut: {
                                labels: {
                                    show: true,
                                    name: {
                                        show: true,
                                        formatter: function (val) {
                                            return val;
                                        }
                                    },
                                    value: {
                                        show: true,
                                        formatter: function (val) {
                                            return Vue.toCurrency(val);
                                        }
                                    },
                                    total: {
                                        show: true,
                                        label: "Total",
                                        formatter: function (w) {
                                            return Vue.toCurrency(w.globals.seriesTotals.reduce((a, b) => {
                                                return a + b;
                                            }, 0));
                                        }
                                    }
                                }
                            }
                        }
                    },
                    tooltip: {
                        enabled: true,
                        y: {
                            show: true,
                            formatter: function (val) {
                                return Vue.toCurrency(val);
                            },
                        }
                    }
                }
            };
        }
    },
    watch: {
        value(new_value) {
            if (new_value) {
                this.read();
            }
        }
    },
    methods: {
        ...mapActions("settings", ["ActionDoMacroCategoryTotal"]),
        close() {
            this.macro_category = {
                name: null
            };
            this.$emit("close");
            this.$emit("input", false);
        },
        async read() {
            this.loading = true;

            if (this.macroCategoryId) {
                await this.ActionDoMacroCategoryTotal({
                    macro_category_id: this.macroCategoryId,
                    month: this.month,
                    year: this.year
                }).then(response => {
                    this.macro_category = response;
                    this.loading = false;
                });
            } else {
                this.close();
            }
        }
    }
};
</script>