<template>
    <v-snackbar
        v-model="show"
        bottom
        :color="theme.color"
        max-width="320px"
        :timeout="15000"
        elevation="5"
        rounded="lg"
        transition="slide-y-transition"
    >
        <v-row no-gutters>
            <v-col>
                <v-row align="center" no-gutters>
                    <v-col cols="auto" class="mr-4">
                        <v-icon :color="theme.content">
                            {{ theme.icon }}
                        </v-icon>
                    </v-col>
                    <v-col>
                        <v-row no-gutters>
                            <v-col
                                cols="12"
                                :class="theme.content + '--text mt-1'"
                                v-html="message"
                            >
                            </v-col>
                        </v-row>
                    </v-col>
                </v-row>
            </v-col>
        </v-row>
        <template v-slot:action="{ attrs }">
            <v-btn
                icon
                v-bind="attrs"
                :color="theme.content"
                @click="show = false"
            >
                <v-icon>mdi-close-circle</v-icon>
            </v-btn>
        </template>
    </v-snackbar>
</template>

<script>
export default {
    props: {
        value: {
            type: Boolean,
            required: false,
            default: false
        },
        color: {
            type: String,
            required: false,
            default: "success"
        },
        icon: {
            type: String,
            required: false,
            default: "mdi-checkbox-marked-circle"
        },
        contentColor: {
            type: String,
            required: false,
            default: "white"
        },
        message: {
            type: String,
            required: true
        },
        type: {
            type: String,
            required: false,
            default: "success"
        }
    },
    data() {
        return {
            show: this.value
        };
    },
    computed: {
        theme() {
            switch (this.type) {
            case "success":
                return {
                    color: "success",
                    icon: "mdi-checkbox-marked-circle",
                    content: "white"
                };
            case "error":
                return {
                    color: "error",
                    icon: "mdi-alert-circle",
                    content: "white"
                };
            case "info":
                return {
                    color: "blue-grey lighten-5",
                    icon: "mdi-bell-circle",
                    content: "black"
                };
            default: {
                return {
                    color: this.color,
                    icon: this.icon,
                    content: this.contentColor
                };
            }
            }
        }
    },
    watch: {
        show() {
            this.$emit("input", this.show);
        },
        value(new_value) {
            this.show = new_value;
        }
    }
};
</script>