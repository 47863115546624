import Vue from "vue";
import App from "./App.vue";
import vuetify from "./plugins/vuetify";
import store from "./store";
import router from "./router";
import "./plugins/custom-plugin";
import "./plugins/vuetify-mask";
import "./plugins/apexcharts";

if (process.env.NODE_ENV !== "production") {
    Vue.config.productionTip = false;
    Vue.config.devtools = true;
}

new Vue({
    store,
    router,
    vuetify,
    render: h => h(App)
}).$mount("#app");
