<template>
    <v-row justify="center" align="center">
        <v-col cols="12" md="6" lg="5">
            <v-card class="py-10 px-16">
                <v-row no-gutters>
                    <!-- <v-col cols="12" class="text-center">
                        <span class="text-h2">Melvid</span>
                    </v-col>
                    <v-col cols="12" class="text-center">
                        <span class="text-subtitle-">Controle Financeiro</span>
                    </v-col> -->
                    <v-col cols="auto">
                        <v-img src="../../assets/logo-melvid.png" width="500px"></v-img>
                    </v-col>
                </v-row>
                <v-row v-if="error_mesasge">
                    <v-col>
                        <v-alert type="error">
                            {{ error_mesasge }}
                        </v-alert>
                    </v-col>
                </v-row>
                <v-row>
                    <v-form ref="formLogin">
                        <v-row justify="end">
                            <v-col cols="12">
                                <v-text-field
                                    v-model="email"
                                    :rules="email_rules"
                                    label="E-mail"
                                    required
                                ></v-text-field>
                            </v-col>
                            <v-col cols="12">
                                <v-text-field
                                    v-model="password"
                                    :rules="password_rules"
                                    label="Senha"
                                    required
                                    type="password"
                                ></v-text-field>
                            </v-col>
                            <v-col cols="auto">
                                <v-btn color="success" :loading="loading" @click="login">
                                    Entrar
                                </v-btn>
                            </v-col>
                        </v-row>
                    </v-form>
                </v-row>
            </v-card>
        </v-col>
    </v-row>
</template>

<script>
import { mapActions } from "vuex";

export default {
    data() {
        return {
            email: null,
            email_rules: [
                v => !!v || "Digite um email",
                v => !v || /^\w+([.-]?\w+)*@\w+([.-]?\w+)*(\.\w{2,3})+$/.test(v) || "Digite um email válido"
            ],
            password: null,
            password_rules: [
                v => !!v || "Digite uma senha"
            ],
            loading: false,
            error_mesasge: null
        };
    },
    methods: {
        ...mapActions("auth", ["ActionDoLogin"]),
        async login() {
            this.loading = true;

            if (this.$refs.formLogin.validate()) {
                try {
                    await this.ActionDoLogin({email: this.email, password: this.password}).then(response => {
                        this.error_mesasge = null;

                        if (response.error === "") {
                            this.$router.push({name: "dashboard"});
                        } else {
                            this.loading = false;
                            this.error_mesasge = response.error;
                        }
                    });
                } catch (error) {
                    this.loading = false;
                    this.error_mesasge = error.response.data.error;
                }
            } else {
                this.loading = false;
            }
        }
    }
};
</script>